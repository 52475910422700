import React from 'react';

import AWSIconWrapper from './aws-icon-wrapper.component';

const awsNodeTypes = {
    apigateway: (props) => { console.log("props are" ,props); return <AWSIconWrapper iconType="APIGateway" description="API Gateway is a fully managed service that makes it easy for developers to create, publish, maintain, monitor, and secure APIs at any scale. With a few clicks in the AWS Management Console, you can create an API that acts as a “front door” for applications to access data, business logic, or functionality from your back-end services, such as workloads running on Amazon Elastic Compute Cloud (Amazon EC2), code running on AWS Lambda, or any web application."
    {...props} />},
    quicksight: (props) => <AWSIconWrapper iconType="QuickSight" description= "Amazon QuickSight is a fast, cloud-powered business intelligence service that makes it easy to deliver insights to everyone in your organization. With QuickSight, you can create and publish interactive dashboards, reports, and analyses, and access them from your web browser or mobile device."{...props} />,
    athena: (props) => <AWSIconWrapper iconType="Athena" description= "Amazon Athena is an interactive query service that makes it easy to analyze data in Amazon S3 using standard SQL. Athena is serverless, so there is no infrastructure to manage, and you pay only for the queries that you run."{...props} />,
    instance: (props) => <AWSIconWrapper iconType="Instance" {...props} />,
    mobileanalytics: (props) => <AWSIconWrapper iconType="MobileAnalytics" {...props} />,
    iot: (props) => <AWSIconWrapper iconType="IoT" {...props} />,
    greengrass: (props) => <AWSIconWrapper iconType="Greengrass" {...props} />,
    kinesisstreams: (props) => <AWSIconWrapper iconType="KinesisStreams" {...props} />,
    awscloud: (props) => <AWSIconWrapper iconType="AWSCloud" {...props} />,
    cloudfront: (props) => <AWSIconWrapper iconType="CloudFront" description="Amazon CloudFront is a fast content delivery network (CDN) service that securely delivers data, videos, applications, and APIs to customers globally with low latency, high transfer speeds, all within a developer-friendly environment."{...props} />,
    cognito: (props) => <AWSIconWrapper iconType="Cognito" description= "Amazon Cognito provides authentication, authorization, and user management for your web and mobile apps. Your users can sign in directly with a user name and password, or through a third party such as Facebook, Amazon, or Google. With Amazon Cognito, you can secure your resources and grant access only to users that you authorize."{...props} />,
    dynamodb: (props) => <AWSIconWrapper iconType="DynamoDB" description="Amazon DynamoDB is a fully managed NoSQL database service that provides fast and predictable performance with seamless scalability. It lets you offload the administrative burdens of operating and scaling a distributed database, so that you don't have to worry about hardware provisioning, setup and configuration, replication, software patching, or cluster scaling."{...props} />,
    ec2: (props) => <AWSIconWrapper iconType="EC2" description= "Amazon Elastic Compute Cloud (Amazon EC2) is a web service that provides resizable compute capacity in the cloud. It is designed to make web-scale cloud computing easier for developers."{...props} />,
    lambdafunction: (props) => <AWSIconWrapper iconType="LambdaFunction" {...props} />,
    lambda: (props) => <AWSIconWrapper iconType="LambdaFunction" description="AWS Lambda is a serverless compute service that runs your code in response to events and automatically manages the underlying compute resources for you. You can use AWS Lambda to build data processing triggers for AWS services like Amazon S3 and Amazon DynamoDB, process streaming data stored in Kinesis, or create your own backend that operates at AWS scale, performance, and security."{...props} />,
    s3: (props) => <AWSIconWrapper iconType="S3" description="Amazon S3 is object storage built to store and retrieve any amount of data from anywhere – web sites and mobile apps, corporate applications, and data from IoT sensors or devices."
    {...props} />,
    mobileclient: (props) => <AWSIconWrapper iconType="MobileClient" {...props} />,
    rds: (props) => <AWSIconWrapper iconType="RDS"  description= "Amazon Relational Database Service (Amazon RDS) makes it easy to set up, operate, and scale a relational database in the cloud. It provides cost-efficient and resizable capacity while automating time-consuming administration tasks such as hardware provisioning, database setup, patching and backups." {...props} />,
    aurora: (props) => <AWSIconWrapper iconType="Aurora" description= "Amazon Aurora is a MySQL and PostgreSQL-compatible relational database built for the cloud, that combines the performance and availability of traditional enterprise databases with the simplicity and cost-effectiveness of open source databases."
    {...props} />,
    lightsail: (props) => <AWSIconWrapper iconType="Lightsail" description="Amazon Lightsail is an easy-to-use cloud platform that offers everything you need to build an application or website, including compute, storage, and networking resources. It's designed to help you start small, and then scale as your needs grow."
    {...props} />,
    redshift: (props) => <AWSIconWrapper iconType="Redshift"   description="Amazon Redshift is a fast, fully managed, petabyte-scale data warehouse service that makes it simple and cost-effective to analyze all your data using your existing business intelligence tools."
    {...props} />,
    elasticache: (props) => <AWSIconWrapper iconType="ElastiCache"  description= "Amazon ElastiCache is a fully managed in-memory data store and cache service that makes it easy to deploy and operate popular open-source compatible in-memory data stores such as Redis, Memcached, and others. ElastiCache improves the performance of web applications and reduces the load on databases by retrieving data from fast, managed, in-memory data stores, instead of relying entirely on slower disk-based databases."
    {...props} />,
    cloudsearch: (props) => <AWSIconWrapper iconType="CloudSearch"  description="Amazon CloudSearch is a fully managed search service that makes it easy to set up, manage, and scale a search solution for your website or application."
    {...props} />,
    kinesis: (props) => <AWSIconWrapper iconType="Kinesis"   description= "Amazon Kinesis makes it easy to collect, process, and analyze real-time, streaming data so you can get timely insights and react quickly to new information. With Amazon Kinesis, you can ingest data from hundreds of thousands of sources and analyze it in real-time, or store it for later in Amazon S3 or Amazon Redshift."
    {...props} />,
    emr: (props) => <AWSIconWrapper iconType="EMR"   description="Amazon EMR is a managed Hadoop framework that makes it easy, fast, and cost-effective to process vast amounts of data across dynamically scalable Amazon EC2 instances. You can also run other popular distributed frameworks such as Apache Spark, HBase, Presto, and Flink in EMR, and interact with data in other AWS data stores such as Amazon S3 and Amazon DynamoDB."
    {...props} />,
    elasticmapreduce: (props) => <AWSIconWrapper iconType="EMR" {...props} />,
    glue: (props) => <AWSIconWrapper iconType="Glue"  description="AWS Glue is a fully managed extract, transform, and load (ETL) service that makes it easy to move data between data stores, clean and enrich data, and load data into analytics engines. You can use AWS Glue to organize and manage your data, create and run ETL jobs, and track and monitor your job runs."
    {...props} />,
    managedkafka: (props) => <AWSIconWrapper iconType="ManagedKafka" {...props} />,
    ses: (props) => <AWSIconWrapper iconType="SES"  description="Amazon Simple Email Service (Amazon SES) is a reliable, scalable, and cost-effective email service designed to help digital marketers and application developers send marketing, notification, and transactional emails. Amazon SES enables you to send and receive email using a simple SMTP interface or the AWS SDKs."
    {...props} />,
    sqs: (props) => <AWSIconWrapper iconType="SQS"  description= "Amazon Simple Queue Service (Amazon SQS) is a fully managed message queuing service that enables you to decouple and scale microservices, distributed systems, and serverless applications. SQS eliminates the complexity and overhead associated with managing and operating message oriented middleware, and empowers developers to focus on differentiating work."
    {...props} />,
    simplequeueservice: (props) => <AWSIconWrapper iconType="SQS" {...props} />,
    sns: (props) => <AWSIconWrapper iconType="SNS"  description="Amazon Simple Notification Service (Amazon SNS) is a fully managed messaging service for both application-to-application (A2A) and application-to-person (A2P) communication. With Amazon SNS, you can create push notifications that are delivered to iOS, Android, Fire OS, Windows, and Kindle devices, or to other distributed services."
    {...props} />,
    simplenotificationservice: (props) => <AWSIconWrapper iconType="SNS" {...props} />,
    elasticsearch: (props) => <AWSIconWrapper iconType="ElasticSearch" {...props} />,
    mechanicalturk: (props) => <AWSIconWrapper iconType="MechanicalTurk"   description= "Amazon Mechanical Turk (MTurk) is a crowdsourcing marketplace that makes it easier for individuals and businesses to outsource their processes and jobs to a distributed workforce who can perform these tasks virtually. This could include anything from conducting simple data validation and research to more subjective tasks like survey participation, content moderation, and more."
    {...props} />,
    mturk: (props) => <AWSIconWrapper iconType="MechanicalTurk" {...props} />,
    cog: (props) => <AWSIconWrapper iconType="Instance" {...props} />,
    cloudwatch: (props) => <AWSIconWrapper iconType="CloudWatch" description= "Amazon CloudWatch is a monitoring and observability service built for DevOps engineers, developers, site reliability engineers (SREs), and IT managers. CloudWatch provides you with data and actionable insights to monitor your applications, respond to system-wide performance changes, optimize resource utilization, and get a unified view of operational health."
    {...props} />,
    cloudwatchlogs: (props) => <AWSIconWrapper iconType="CloudWatch" {...props} />,
    ebs: (props) => <AWSIconWrapper iconType="EBS" {...props} />,
    ebssnapshot: (props) => <AWSIconWrapper iconType="EBS" {...props} />,
    elasticblockstore: (props) => <AWSIconWrapper iconType="EBS" {...props} />,
    vpc: (props) => <AWSIconWrapper iconType="VPC" {...props} />,
    loadbalancer: (props) => <AWSIconWrapper iconType="LoadBalancer" {...props} />,
    applicationloadbalancer: (props) => <AWSIconWrapper iconType="LoadBalancer" {...props} />,
    elasticloadbalancer: (props) => <AWSIconWrapper iconType="LoadBalancer" {...props} />,
    elb: (props) => <AWSIconWrapper iconType="LoadBalancer" {...props} />,
    alb: (props) => <AWSIconWrapper iconType="LoadBalancer" {...props} />,
    route53: (props) => <AWSIconWrapper iconType="Route53"  description= "Amazon Route 53 is a highly available and scalable cloud Domain Name System (DNS) web service. It is designed to give developers and businesses an extremely reliable and cost effective way to route end users to Internet applications by translating names like www.example.com into the numeric IP addresses"
    {...props} />,
    autoscaling: (props) => <AWSIconWrapper iconType="AutoScaling" {...props} />,
    rekognition: (props) => <AWSIconWrapper iconType="Rekognition" {...props} />,
    stepfunctions: (props) => <AWSIconWrapper iconType="StepFunctions"    description="AWS Step Functions is a web service that enables you to coordinate the components of distributed applications and microservices using visual workflows. You build applications from individual components that each perform a discrete function, or task, allowing you to scale and change applications quickly."
    {...props} />,
    xray: (props) => <AWSIconWrapper iconType="XRay" {...props} />,
    glacier: (props) => <AWSIconWrapper iconType="Glacier" {...props} />,
    appsync: (props) => <AWSIconWrapper iconType="AppSync"    description= "AWS AppSync is a fully managed service that makes it easy to develop GraphQL APIs by handling the heavy lifting of securely connecting to data sources like AWS DynamoDB, Lambda, and more. With AppSync, you can build scalable applications, including those requiring real-time updates, on a range of data sources such as NoSQL data stores, relational databases, HTTP APIs, and your custom data sources with AWS Lambda."
    {...props} />,
    iam: (props) => <AWSIconWrapper iconType="IAM" {...props} />,
    cloudtrail: (props) => <AWSIconWrapper iconType="CloudTrail"    description= "AWS CloudTrail is a service that enables governance, compliance, operational auditing, and risk auditing of your AWS account. With CloudTrail, you can log, continuously monitor, and retain account activity related to actions across your AWS infrastructure. CloudTrail provides event history of your AWS account activity, including actions taken through the AWS Management Console, AWS SDKs, command line tools, and other AWS services."
    {...props} />,
    waf: (props) => <AWSIconWrapper iconType="WAF" {...props} />,
    kms: (props) => <AWSIconWrapper iconType="KMS" {...props} />,
    keymanagement: (props) => <AWSIconWrapper iconType="KMS" {...props} />,
    keymanagementservice: (props) => <AWSIconWrapper iconType="KMS" {...props} />,
    keymanagementstore: (props) => <AWSIconWrapper iconType="KMS" {...props} />,
    s3bucket: (props) => <AWSIconWrapper iconType="S3bucket" {...props} />,
    volume: (props) => <AWSIconWrapper iconType="Volume" {...props} />,
    ['elastic-ip']: (props) => <AWSIconWrapper iconType="ElasticIPAddress" {...props} />,
    ['key-pair']: (props) => <AWSIconWrapper iconType="KeyPair" {...props} />,
    ['route-table']: (props) => <AWSIconWrapper iconType="RouteTable" {...props} />,
    table: (props) => <AWSIconWrapper iconType="DynamoDBTable" {...props} />,
    bucket: (props) => <AWSIconWrapper iconType="S3bucket" {...props} />,
    function: (props) => <AWSIconWrapper iconType="LambdaFunction" {...props} />,
    graphqlapi: (props) => <AWSIconWrapper iconType="GraphqlApi" {...props} />,
    apps: (props) => <AWSIconWrapper iconType="AmplifyApps" {...props} />,
    layer: (props) => <AWSIconWrapper iconType="Layer" {...props} />,
    apis: (props) => <AWSIconWrapper iconType="Apis" {...props} />
};

export default awsNodeTypes;
