import { useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";

const PopOver = ({
  isSidebarOpen,
  target,
  renderSelectedComponentInfo,
  placement = "right",
  maxWidth='auto',
  
  flip=true
}) => {
  const ref = useRef();
  

  return (
    isSidebarOpen?<div  ref={ref}>
      <Overlay
        show={isSidebarOpen}
        target={target}
        placement={placement}
        container={ref}
        containerPadding={20}
        flip={flip}
        boundary="viewport"
      >
        <Popover
        hidearrow={true}
          id="popover-contained"
          style={{
            minHeight:'fit-content',
            minWidth: '200px',
            maxWidth: `${maxWidth}`,
            borderRadius: "4px !important",
            backgroundColor: '#e0dcce',
            border: "1px solid #696969",
            padding:'0.5rem 0.75rem',
            background: '#2F2F2F',
            marginBottom:"-32px"
          }}
        >
          <Popover.Body >
            <div className="sidebar">{renderSelectedComponentInfo()}</div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>:<></>
  );
};
export default PopOver;
