import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import drag from "../../../images/drag.svg";
import arrow_right from "../../../images/arrow-right.svg"
import flag from "../../../images/Flag.svg";
import downArrow from "../../../images/downVector.svg";
import generic_application from "../../../images/aws/Resource-Icons/Res_General-Icons/Res_48_Dark/Res_Generic-Application_48_Dark.svg";
import { infraviewResourceIcon } from "../new-icons/infraviewResource-icons";
import {icons} from "./CategoriesIcon";
import machinelearning from "../../../images/Machine-Learning_Category.svg";
import "./InfraStructure.css";
import { useRef, useState } from "react";

  export const removeDuplicates = (array) => {
    const seenFirstPart = {};
    const result = {};
    array.forEach(item => {
      const [firstPart, secondPart] = item.type.split(':').map(part => part.toLowerCase().trim());
      if (!seenFirstPart[firstPart]) {
        seenFirstPart[firstPart] = [];
        result[firstPart] = {};
      }
      if (!seenFirstPart[firstPart].includes(secondPart)) {
        seenFirstPart[firstPart].push(secondPart);
        result[firstPart][secondPart] = [];
      }
      result[firstPart][secondPart].push(item);
    });
    return result;
  };

  export const resourceIcon = (childType) => {
    const resType = (childType && typeof childType === 'string') ? childType.toLowerCase().trim() : '';

    return (
      infraviewResourceIcon[resType] || (
        <img
          src={generic_application}
          alt=""
          style={{ width: "22px", height: "22px" }}
        />
      )
    );
  };

  const Regions = ({
    selectedDropDownValues,
    searchBar,
    handleResourceIconClick,
    CustomToggle,
    highlightText,
    allOpen
  }) => {

  const [hideRegion, setHideRegion] = useState(true);
  const [openParentCards, setOpenParentCards] = useState({});
  const [openChildCards, setOpenChildCards] = useState({});
  const [openGrandchildCards, setOpenGrandchildCards] = useState({});
  const categories = selectedDropDownValues["aws:region"]?.children?.categories;
  const regionRef = useRef(null);

  const toggleParentCard = (index) => {
    setOpenParentCards((prev) => {
      const newState = { ...prev };
      newState[index] = !prev[index];
      return newState;
    });
  };

  const toggleChildCard = (id) => {
    setOpenChildCards((prev) => {
      const newState2 = { ...prev };
      newState2[id] = !prev[id];
      return newState2;
    });
  };

  const toggleGrandchildCard = (idx) => {
    setOpenGrandchildCards((prev) => {
      const newState3 = { ...prev };
      newState3[idx] = !prev[idx];
      return newState3;
    });
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (regionRef.current) {
      const newWidth = e.clientX - regionRef.current.getBoundingClientRect().left;
      regionRef.current.style.width = `${newWidth}px`;
    }

  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <>
      {selectedDropDownValues["aws:region"]?.name && (
        <div style={{ position: "relative"}}>
          {hideRegion ?
          <>
          <img src={drag} alt="drag" className="drag-img" onClick={() => setHideRegion(!hideRegion)} />
          <div className="universal-res" ref={regionRef}>
            <span className="uni-reg-resource">
              <img src={flag} alt="vector" />
              <span>{highlightText(selectedDropDownValues["aws:region"]?.name, searchBar)}</span>
            </span>
            <Accordion>
              {Object.keys(
                selectedDropDownValues["aws:region"]?.children?.categories
              ).map((category, index) => {
                const uniqueItems = removeDuplicates(categories[category]);

                return (
                  <div key={category}>
                  <Card className="all-category-data">
                    <CustomToggle eventKey={index}>
                      <Card.Header className="compute-header"
                       onClick={() => toggleParentCard(index)}
                      >
                        <span className="compute-btn">
                          <span>{icons[category] || <img src={machinelearning} alt="Application Integration" />}</span>
                          <span>
                            {highlightText(category, searchBar)}
                          </span>
                        </span>
                        <span className="data-count">
                          <span className="category-count">
                            {Object.keys(uniqueItems).length}
                          </span>
                          <span>
                            <img
                              src={downArrow}
                              alt=""
                              className = {allOpen
                                ? (!openParentCards[index] ? 'uparrow' : '')
                                : (openParentCards[index] ? 'uparrow' : '')}
                            />
                          </span>
                        </span>
                      </Card.Header>
                    </CustomToggle>
                    <Accordion.Collapse
                     eventKey={index}
                     in={allOpen ? !openParentCards[index] : openParentCards[index]}
                    >
                      <Card.Body className="card-body">
                        <Accordion>
                          {Object.keys(uniqueItems)?.map((el, idx) => {
                            return (
                              <Card className="compute-details" key={idx}>
                                <CustomToggle eventKey={idx} style={{backgroundColor:"transparent"}}>
                                  <Card.Header className="compute-details-header" onClick={() => toggleChildCard(el)}>
                                    <span key={index} className="compute-btn">
                                        {resourceIcon(el)}
                                      <span className="body-data">
                                        {highlightText(el, searchBar)}
                                      </span>
                                    </span>
                                    <span className="data-count">
                                      <span className="category-count">
                                      {Object.keys(uniqueItems[el]).length}
                                      </span>
                                      <span>
                                        <img
                                        src={downArrow}
                                        alt=""
                                        className = {allOpen
                                          ? (!openChildCards[el] ? "arrow-up" : "vector-arrow")
                                          : (openChildCards[el] ? "arrow-up" : "vector-arrow")}
                                        />
                                      </span>
                                    </span>
                                  </Card.Header>
                                </CustomToggle>
                                <Accordion.Collapse
                                 eventKey={idx}
                                 in={allOpen ? !openChildCards[el] : openChildCards[el]}
                                >
                                  <Card.Body className="compute-details-body">
                                    <Accordion>
                                      {Object.keys(uniqueItems[el]).map((element, innerIdx) => (
                                      <Card
                                        className="compute-details"
                                        key={innerIdx}
                                      >
                                        <CustomToggle eventKey={innerIdx}>
                                          <Card.Header className="compute-details-header" onClick={() => toggleGrandchildCard(element)}>
                                            <span className="compute-btn">
                                                {resourceIcon(element)}
                                              <span className="body-data">
                                              {highlightText(element, searchBar)}
                                              </span>
                                            </span>
                                            <span className="data-count">
                                              <span className="category-count">
                                                {uniqueItems[el][element].length}
                                              </span>
                                              <span>
                                                <img
                                                  src={downArrow}
                                                  alt=""
                                                  className = {allOpen
                                                    ? (!openGrandchildCards[element] ? "arrow-up" : "vector-arrow")
                                                    : (openGrandchildCards[element] ? "arrow-up" : "vector-arrow")}
                                                />
                                              </span>
                                            </span>
                                          </Card.Header>
                                        </CustomToggle>
                                          <Accordion.Collapse
                                           eventKey={innerIdx}
                                           in={allOpen ? !openGrandchildCards[element] : openGrandchildCards[element]}
                                          >
                                            <Card.Body className="compute-details-body">
                                            {uniqueItems[el][element].map((item, itemIdx) => (
                                              <div key={itemIdx} className="instances"
                                                onClick={(e) => handleResourceIconClick(e, item)}
                                              >
                                                <span>{resourceIcon(item.type?.includes(":") &&
                                                  item.type.split(":")[1])}</span>
                                                <span className="body-data">{highlightText(item.name.length>10
                                                  ? item.name.slice(0,10)+"..." : item.name, searchBar)}
                                                </span>
                                              </div>
                                            ))}
                                            </Card.Body>
                                          </Accordion.Collapse>
                                      </Card>))}
                                    </Accordion>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            );
                          })}
                        </Accordion>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  </div>
                );
              })}
            </Accordion>
            <div className="resize-handle" onMouseDown={handleMouseDown}></div>
          </div>
          </> :
          <div className="hide-data">
            <img src={arrow_right} alt="drag" className="right-arrow-img" onClick={() => setHideRegion(!hideRegion)} />
            <div style={{paddingTop:'10px'}}><img src={flag} alt="vector" /></div>
            <span className="hide-data-name">Regional resources</span>
          </div>}
        </div>
      )}
    </>
  );
};

export default Regions;
