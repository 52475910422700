const ActivityLogSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_4705_3625)">
        <g id="Group">
          <path
            id="Vector"
            d="M7.74375 15.3321C7.64613 15.3321 7.54852 15.3077 7.4509 15.2589C7.15805 15.1368 6.98723 14.8196 7.01163 14.5023L7.57292 9.98765H1.39877C1.10592 9.98765 0.861882 9.81682 0.739864 9.54838C0.617845 9.27994 0.666652 8.98709 0.861882 8.76746L7.69494 0.909444C7.91457 0.665406 8.25622 0.592195 8.54907 0.714214C8.84191 0.836233 9.01274 1.15348 8.98834 1.47073L8.42705 5.98543H14.6256C14.9185 5.98543 15.1625 6.15626 15.2845 6.4247C15.4065 6.69314 15.3577 6.98598 15.1625 7.20562L8.30503 15.088C8.15861 15.2589 7.96338 15.3321 7.74375 15.3321ZM3.00941 8.54783H8.42705C8.64668 8.54783 8.84191 8.64544 8.96393 8.79186C9.11036 8.93829 9.15916 9.15792 9.13476 9.37755L8.7687 12.3304L12.9906 7.47406H7.57292C7.35328 7.47406 7.15806 7.37644 7.03604 7.23002C6.88961 7.0836 6.84081 6.86396 6.86521 6.64433L7.23127 3.69147L3.00941 8.54783Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4705_3625">
          <rect
            width="14.6667"
            height="14.6667"
            fill="white"
            transform="translate(0.666504 0.666016)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ActivityLogSvg;
