import React from "react";

const LinkSvg = () => {
  return (
    <svg
      width="23"
      height="13"
      viewBox="0 0 23 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1331_64362)">
        <path
          d="M16.504 4.98547H7.2602C6.62909 4.98547 6.14648 5.46807 6.14648 6.09918C6.14648 6.73028 6.62909 7.21289 7.2602 7.21289H16.504C17.1351 7.21289 17.6177 6.73028 17.6177 6.09918C17.6177 5.46807 17.1351 4.98547 16.504 4.98547Z"
          fill="#F8F8FF"
        />
        <path
          d="M9.48774 0.122325H6.74058C3.47369 0.122325 0.800781 2.83236 0.800781 6.09925C0.800781 9.36614 3.47369 12.0762 6.7777 12.0762H9.52486C10.156 12.0762 10.6386 11.5936 10.6386 10.9625C10.6386 10.3314 10.156 9.84875 9.52486 9.84875H6.7777C4.69877 9.84875 3.02821 8.17818 3.02821 6.09925C3.02821 4.02032 4.69877 2.34975 6.7777 2.34975H9.52486C10.156 2.34975 10.6386 1.86714 10.6386 1.23604C10.6386 0.604933 10.1188 0.122325 9.48774 0.122325Z"
          fill="#F8F8FF"
        />
        <path
          d="M17.023 0.122325H14.2758C13.6447 0.122325 13.1621 0.604933 13.1621 1.23604C13.1621 1.86714 13.6447 2.34975 14.2758 2.34975H17.023C19.1019 2.34975 20.7725 4.02032 20.7725 6.09925C20.7725 8.17818 19.0648 9.84875 17.023 9.84875H14.2758C13.6447 9.84875 13.1621 10.3314 13.1621 10.9625C13.1621 11.5936 13.6447 12.0762 14.2758 12.0762H17.023C20.327 12.0762 22.9999 9.40326 22.9999 6.09925C22.9999 2.79523 20.2899 0.122325 17.023 0.122325Z"
          fill="#F8F8FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1331_64362">
          <rect
            width="22.2"
            height="11.9538"
            fill="white"
            transform="matrix(1 0 0 -1 0.800781 12.0762)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkSvg;
