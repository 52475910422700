import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { JMLSvg } from "../../../images/svg";
import { EyePasswordSvg, EyeClosePasswordSvg } from "../../../images/svg/icons";
import { trackLogin } from "../../../utils/google-analytics";
import Toastify from "../../../common/components/Toastify/Toastify";
import context from "../../../store/create-context";

function LoginPage({ onLogin, redirectTo }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showToastr, setShowToastr} = useContext(context)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  async function handleSubmit(event) {
    event.preventDefault();
    console.log("Logging in user", username);
    const user_id = await onLogin(username, password);
    if (user_id !== null) {
      console.log("Authentication succeeded. Redirecting to " + redirectTo);
      user_id && trackLogin(user_id);
      navigate("/dashboard");
    } else {
      // handle login failure
      setErrorMessage(true);
      console.log("Login failure");
    }
  }

  const headingStyle = {
    display: "inline-flex",
    padding: "16px 40px",
    justifyContent: "center",
    alignItems: "center",
    gap: "1px",
    borderRadius: "100px",
    background: "linear-gradient(90deg, #1B05F9 0%, #F726BF 100%)",
    color: "#FFF",
    textAlign: "center",
    fontFamily: "DM Mono",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100vh",
  };

  const formStyle = {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "normal",
    gap: "16px",
  };

  const formHeadingStyle = {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  };

  const inputStyle = {
    display: "flex",
    width: "360px",
    height: "48px",
    padding: "12px 16px",
    alignItems: "center",
    borderRadius: "4px",
    border: "1px solid #E8E8E8",
  };

  if (errorMessage) {
    inputStyle["borderColor"] = "red";
  }

  const buttonStyle = {
    display: "flex",
    width: "360px",
    padding: "16px 40px",
    justifyContent: "center",
    alignItems: "center",
    gap: "1px",
    borderRadius: "8px",
    background: "#5A46FF",
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Work Sans",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    border: "none",
  };

  const loginImageStyle = {
    width: "55.438px",
    height: "64px",
    flexShrink: "0",
    fill: "linear-gradient(225deg, #261DF9 23.53%, #D410F9 111.92%)",
  };

  const passwordEyeIconStyle = { position: "relative" };

  const eyeIconStyle = {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
  };

  const errorMessageStyle = {
    color: "var(--colors-red-500, #F2323F)",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "14px",
    textAlign: "left",
    marginTop: "-0.6rem",
    marginBottom: "0.5rem",
  };

  return (
    <div style={containerStyle}>
      <div>
        <p style={headingStyle}>Experience Cloud like you have never before</p>
      </div>

      <form onSubmit={handleSubmit} style={formStyle}>
        <p style={formHeadingStyle}>Login to your account</p>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Enter your email address"
          style={inputStyle}
        />
        <div style={passwordEyeIconStyle}>
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            style={inputStyle}
          />
          <span style={eyeIconStyle} onClick={togglePasswordVisibility}>
            {!showPassword ? <EyeClosePasswordSvg /> : <EyePasswordSvg />}
          </span>
        </div>
        {errorMessage ? (
          <p style={errorMessageStyle}>Please enter correct details.</p>
        ) : null}

        <button name="login" type="submit" style={buttonStyle}>
          Login
        </button>
      </form>
      <div style={loginImageStyle}>
        <JMLSvg />
      </div>
      <Toastify
        show={showToastr["show"]}
        type={showToastr["type"]}
        message={showToastr["text"]}
        handleClose={() => {
          setShowToastr((props) => {
            return { ...props, show: false };
          });
        }}
      />
    </div>
  );
}

export default LoginPage;
