import React from "react";

const UpSvg = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1680_1737)">
        <path
          d="M10.5371 7.33073C10.3247 7.33073 10.139 7.24876 9.9797 7.08483L5.67994 2.63128L1.35363 7.08483C1.03513 7.4127 0.530835 7.4127 0.238876 7.08483C-0.0796253 6.75696 -0.0796253 6.23783 0.238876 5.93729L5.09602 0.909964C5.41452 0.582095 5.91881 0.582095 6.21077 0.909964L11.0945 5.93729C11.413 6.26516 11.413 6.78428 11.0945 7.08483C10.9352 7.24876 10.7494 7.33073 10.5371 7.33073Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1680_1737">
          <rect
            width="11.3333"
            height="6.66667"
            fill="white"
            transform="translate(0 0.664062)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UpSvg;
