import React from "react";

const PlusSvg = ({color= 'white'}) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49997 6.24561V11.7404"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 8.99303H5.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0143 1.5H4.98571C2.53571 1.5 1 3.23406 1 5.68887V12.3111C1 14.7659 2.52857 16.5 4.98571 16.5H12.0143C14.4714 16.5 16 14.7659 16 12.3111V5.68887C16 3.23406 14.4714 1.5 12.0143 1.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusSvg;
