import React from "react";

const LogoSvg = (props) => {
  return (
      <svg
        width="56"
        height="64"
        viewBox="0 0 56 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6278 0L32.884 11.6116L20.7128 16.4801L19.3631 13.1057L25.3632 10.7057L16.1796 4.14603L7.36759 7.9226L18.2208 15.6749V39.4701L1.86667 48.8153V16.61H5.50092V42.5528L14.5866 37.361V17.5452L0 7.12619L16.6278 0ZM41.1072 18.5828L42.5832 21.9038L27.3082 28.6927V59.2411L40.028 53.5879V27.5118H43.6623V55.9497L25.5494 63.9998L7.32187 57.1645L18.9045 47.8984L21.1748 50.7363L14.5861 56.0072L23.6739 59.4151V26.3309L41.1072 18.5828ZM43.6594 7.525H45.6854L47.8252 12.7455H47.9162L50.056 7.525H52.082V7.54932H52.1071V19.7465H55.4382V21.1009H50.4643V10.2377H50.4241L48.4132 15.2572H47.3282L45.3173 10.2187H45.2528V15.2951H43.6594V7.525Z"
          fill="url(#paint0_linear_438_27703)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_438_27703"
            x1="42.3939"
            y1="15.0588"
            x2="-13.5947"
            y2="63.5575"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#261DF9" />
            <stop offset="1" stopColor="#D410F9" />
          </linearGradient>
        </defs>
      </svg>
  );
};

export default LogoSvg;
