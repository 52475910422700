const BrownTwoSvg = () => {
  return (
    <>
      <svg
        width="160"
        height="112"
        viewBox="0 0 160 112"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.3152 2.07093C83.5321 -0.690311 75.7773 -0.69031 70.9943 2.07093L3.64114 40.9539C1.02955 42.4616 -0.156078 44.4807 0.0842445 46.4528C0.0289212 46.7506 0 47.0577 0 47.3716V64.3716C0 64.7208 0.0357869 65.0616 0.103895 65.3905C-0.211903 67.4071 0.967176 69.4869 3.64113 71.0306L70.9943 109.914C75.7773 112.675 83.5321 112.675 88.3151 109.914L155.668 71.0306C157.503 69.9713 158.634 68.6594 159.061 67.2887C159.652 66.4677 160 65.4603 160 64.3716V47.3716C160 46.1791 159.583 45.0841 158.886 44.2247C158.364 43.0262 157.292 41.8912 155.668 40.9539L88.3152 2.07093Z"
          fill="white"
        />
        <g filter="url(#filter0_i_68_681)">
          <rect
            width="85.1695"
            height="85.1695"
            rx="4"
            transform="matrix(0.866044 0.499967 -0.866044 0.499967 79.6547 3.37109)"
            fill="#FF8946"
          />
          <rect
            width="85.1695"
            height="85.1695"
            rx="4"
            transform="matrix(0.866044 0.499967 -0.866044 0.499967 79.6547 3.37109)"
            fill="url(#paint0_radial_68_681)"
          />
        </g>
        <defs>
          <filter
            id="filter0_i_68_681"
            x="7.9234"
            y="3.54297"
            width="145.463"
            height="83.8203"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="-1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_68_681"
            />
          </filter>
          <radialGradient
            id="paint0_radial_68_681"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(80.8553 5.6547) rotate(136.311) scale(95.5074 78.8491)"
          >
            <stop stopColor="#91A8BA" stopOpacity="0" />
            <stop offset="0.300379" stopColor="#3B356B" stopOpacity="0.4" />
            <stop offset="1" stopColor="#49260D" />
          </radialGradient>
        </defs>
      </svg>
    </>
  );
};

export default BrownTwoSvg;
