import React from "react";

const SetOneSvg = (props) => {
  return (
    <svg
      width="371"
      height="313"
      viewBox="0 0 371 313"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3" filter="url(#filter0_f_55_144)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M200.39 122.071C195.607 119.31 187.852 119.31 183.069 122.071L115.716 160.954C113.104 162.462 111.919 164.481 112.159 166.453C112.104 166.751 112.075 167.058 112.075 167.372V184.372C112.075 184.721 112.11 185.062 112.179 185.391C111.863 187.407 113.042 189.487 115.716 191.031L183.069 229.914C187.852 232.675 195.607 232.675 200.39 229.914L267.743 191.031C269.578 189.971 270.709 188.659 271.136 187.289C271.727 186.468 272.075 185.46 272.075 184.372V167.372C272.075 166.179 271.657 165.084 270.96 164.225C270.439 163.026 269.367 161.891 267.743 160.954L200.39 122.071Z"
          fill="white"
        />
      </g>
      <g opacity="0.6" filter="url(#filter1_df_55_144)">
        <rect
          width="96.6802"
          height="96.6802"
          rx="10"
          transform="matrix(0.866044 0.499967 -0.866044 0.499967 191.729 117.617)"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        <rect
          width="161.216"
          height="161.216"
          rx="20"
          transform="matrix(0.866044 0.499967 -0.866044 0.499967 188 87)"
          fill="white"
          fillOpacity="0.4"
        />
        <rect
          y="0.499967"
          width="160.216"
          height="160.216"
          rx="19.5"
          transform="matrix(0.866044 0.499967 -0.866044 0.499967 188.433 87.25)"
          stroke="url(#paint0_linear_55_144)"
          strokeOpacity="0.8"
        />
      </g>
      <g opacity="0.5">
        <rect
          width="161.216"
          height="161.216"
          rx="20"
          transform="matrix(0.866044 0.499967 -0.866044 0.499967 188 83)"
          fill="url(#paint1_linear_55_144)"
          fillOpacity="0.6"
        />
        <rect
          y="0.499967"
          width="160.216"
          height="160.216"
          rx="19.5"
          transform="matrix(0.866044 0.499967 -0.866044 0.499967 188.433 83.25)"
          stroke="url(#paint2_linear_55_144)"
          strokeOpacity="0.8"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_55_144"
          x="102.075"
          y="110"
          width="180"
          height="131.984"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="5"
            result="effect1_foregroundBlur_55_144"
          />
        </filter>
        <filter
          id="filter1_df_55_144"
          x="93.0732"
          y="100.547"
          width="197.312"
          height="130.816"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_55_144"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_55_144"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="10"
            result="effect2_foregroundBlur_55_144"
          />
        </filter>
        <linearGradient
          id="paint0_linear_55_144"
          x1="215.32"
          y1="42.6964"
          x2="76.3031"
          y2="220.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_55_144"
          x1="-66.3938"
          y1="66.3938"
          x2="86.5178"
          y2="-4.62032"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.78" />
          <stop offset="0.958333" stopOpacity="0.25" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_55_144"
          x1="-128.979"
          y1="75.9757"
          x2="36.1813"
          y2="-92.3481"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopOpacity="0.4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SetOneSvg;
