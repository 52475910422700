const CodeFrameSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2089_30776)">
        <path
          d="M5.87317 12.6665C5.67761 12.6665 5.5065 12.5962 5.35984 12.4557L0.886504 8.17002C0.593171 7.88899 0.593171 7.44402 0.886504 7.18641L5.35984 2.87728C5.65317 2.59625 6.11761 2.59625 6.3865 2.87728C6.67984 3.15831 6.67984 3.60327 6.3865 3.86088L2.4265 7.65479L6.3865 11.4487C6.67984 11.7297 6.67984 12.1747 6.3865 12.4323C6.26428 12.5962 6.06873 12.6665 5.87317 12.6665Z"
          fill="#F8F8FF"
        />
        <path
          d="M10.1264 12.6665C9.93087 12.6665 9.75976 12.5962 9.6131 12.4557C9.31976 12.1747 9.31976 11.7297 9.6131 11.4721L13.5731 7.67821L9.58865 3.86088C9.29532 3.57985 9.29532 3.13489 9.58865 2.87728C9.88199 2.59625 10.3464 2.59625 10.6153 2.87728L15.0887 7.18641C15.382 7.46744 15.382 7.91241 15.0887 8.17002L10.6398 12.4557C10.4931 12.5962 10.2975 12.6665 10.1264 12.6665Z"
          fill="#F8F8FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_2089_30776">
          <rect
            width="14.6667"
            height="10"
            fill="white"
            transform="translate(0.666504 2.6665)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CodeFrameSvg;
