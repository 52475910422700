import React from "react";

const StartSvg = () => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 0L7.69088 3.2183C7.79217 3.49202 8.00798 3.70783 8.2817 3.80912L11.5 5L8.2817 6.19088C8.00798 6.29217 7.79217 6.50798 7.69088 6.7817L6.5 10L5.30912 6.7817C5.20783 6.50798 4.99202 6.29217 4.7183 6.19088L1.5 5L4.7183 3.80912C4.99202 3.70783 5.20783 3.49202 5.30912 3.2183L6.5 0ZM12.5 7L13.1507 8.75849C13.252 9.03221 13.4678 9.24802 13.7415 9.3493L15.5 10L13.7415 10.6507C13.4678 10.752 13.252 10.9678 13.1507 11.2415L12.5 13L11.8493 11.2415C11.748 10.9678 11.5322 10.752 11.2585 10.6507L9.5 10L11.2585 9.3493C11.5322 9.24802 11.748 9.03221 11.8493 8.75849L12.5 7ZM2.88061 10.0286L2.5 9L2.11939 10.0286C2.01811 10.3023 1.8023 10.5181 1.52858 10.6194L0.5 11L1.52858 11.3806C1.8023 11.4819 2.01811 11.6977 2.11939 11.9714L2.5 13L2.88061 11.9714C2.98189 11.6977 3.1977 11.4819 3.47142 11.3806L4.5 11L3.47142 10.6194C3.1977 10.5181 2.98189 10.3023 2.88061 10.0286Z"
        fill="white"
      />
    </svg>
  );
};

export default StartSvg;
