import React from "react";

const HomeSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.86791 15.5786V13.2785C6.8679 12.6935 7.34484 12.2181 7.93576 12.2142H10.1003C10.6941 12.2142 11.1754 12.6907 11.1754 13.2785V13.2785V15.5857C11.1752 16.0824 11.5757 16.4884 12.0773 16.5H13.5203C14.9588 16.5 16.125 15.3455 16.125 13.9214V13.9214V7.37838C16.1173 6.81812 15.8516 6.29201 15.4035 5.94977L10.4683 2.01398C9.6037 1.32867 8.37465 1.32867 7.51006 2.01398L2.59652 5.95692C2.14669 6.29777 1.88054 6.82475 1.875 7.38552V13.9214C1.875 15.3455 3.04116 16.5 4.47968 16.5H5.92272C6.43677 16.5 6.85348 16.0875 6.85348 15.5786V15.5786"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HomeSvg;
