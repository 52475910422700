import awsNodeTypes from "./aws-icons";
import azureNodeTypes from "./azure-icons";
import { useCallback } from "react";
import { Handle, Position } from "reactflow";

const allNodeTypes = {
  ...awsNodeTypes,
  ...azureNodeTypes,
  group: GroupNode,
};

export default allNodeTypes;

function GroupNode({ data }) {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  return (
    <>
      <div>
        <label htmlFor="text" className="position-absolute " style={{left:'50%',top:'0%'}}>{data.label ?? ""}</label>
      </div>
    </>
  );
}
