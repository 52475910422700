import React from 'react';
import CloudIcon from './cloud-icon.component';

import {APIManagement, AzMachineLearning, AzFunctions, CosmosDBcolor, AzStorage, AzAppService, AzCacheRedisProducticonColor, AzSQLDatabase, AzVirtualMachine, AzEventHubs, AzIoTHub, AzLogicApps, ActiveDirectory} from 'azure-react-icons';

function AzureIconWrapper({ iconType, data }) {
    const iconMap = {
        APIManagement: {
            IconComponent: APIManagement,
            label: 'API Management',
        },
        ActiveDirectory: {
            IconComponent: ActiveDirectory,
            label: 'Azure Active Directory',
        },
        MachineLearning: {
            IconComponent: AzMachineLearning,
            label: 'Azure Machine Learning',
        },
        Functions: {
            IconComponent: AzFunctions,
            label: 'Azure Functions',
        },
        CosmosDB: {
            IconComponent: CosmosDBcolor,
            label: 'Azure Cosmos DB',
        },
        Storage: {
            IconComponent: AzStorage,
            label: 'Azure Storage',
        },
        BlobStorage: {
            IconComponent: AzStorage,
            label: "Blob Storage"
        },
        AppService: {
            IconComponent: AzAppService,
            label: 'Azure App Service',
        },
        KubernetesService: {
            IconComponent: AzAppService,
            label: 'Azure Kubernetes Service',
        },
        RedisCache: {
            IconComponent: AzCacheRedisProducticonColor,
            label: 'Azure Redis Cache',
        },
        SQLDatabase: {
            IconComponent: AzSQLDatabase,
            label: 'Azure SQL Database',
        },
        VirtualMachine: {
            IconComponent: AzVirtualMachine,
            label: 'Azure Virtual Machines',
        },
        EventHub: {
            IconComponent: AzEventHubs,
            label: 'Azure Event Hub',
        },
        IoTHub: {
            IconComponent: AzIoTHub,
            label: 'Azure IoT Hub',
        },
        LogicApps: {
            IconComponent: AzLogicApps,
            label: 'Azure Logic Apps',
        },
        Other: {
            IconComponent: AzVirtualMachine,
            label: 'Azure Service',
        }
    };

    var iconData = iconMap[iconType];

    if (!iconData) {
        // fill a default icon here (for now it is an Azure Virtual Machines icon)
        iconData = iconMap['Other'];
    }

    return <CloudIcon data={{ ...data, ...iconData }} />;
}

export default AzureIconWrapper;
