import React, { useState, useRef } from "react";
import styles from "./landing2.module.css";
import banner from "../../images/banner.svg";
import masklayer from "../../images/mask_layer.svg";
import masklayer2 from "../../images/vision2.gif";
import Hexagon from "../../images/Hexagon.svg";
import ModalUI from "../components/UI/ModalUI/ModalUI";
import { Row, Col, Form } from "react-bootstrap";
import hexagonAnimate from "../../images/hexagonAnimate.png";
import circular from "../../images/circular.png";
import sphereAnimation from "../../images/sphereAnimation.gif";
import s3Bucket from "../../images/s3Bucket.svg";
import dollar from "../../images/dollar.svg";
import lambda from "../../images/lambda.svg";
import puzzleImage from "../../images/web development _ plugin, puzzle, puzzles, pieces, man, people-cropped.svg"
import blockTickImage from "../../images/blockTick.svg";


import {
  HexaSvg,
  HexaBigSvg,
  HexaBigTwoSvg,
  RadialSvg,
  NewLogoSvg,
} from "../../images/svg";
import {
  BlueTwoSvg,
  BlueSvg,
  BlueThreeSvg,
  SetOneSvg,
  BrownTwoSvg,
  BrownThreeSvg,
  PurpleTwoSvg,
  PurpleThreeSvg,
  GreenTwoSvg,
  GreenThreeSvg,
} from "../../images/svg";
import Testimonials from "./Testimonials";

const UpdatedLanding = () => {
  const [email, setEmail] = useState("");
  const [Name, setName] = useState("");
  const [Affiliation, setAffiliation] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(null);
  const [emailError, setEmailError] = useState("");

  const [showAccessModal, setShowAccessModal] = useState(false);
  const [showThanksModal, setShowThanksModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);
  const scriptURL = "https://script.google.com/macros/s/AKfycbzhYga59eCTatgsw7vTMdi0eHimwNqwsQfPQNHIrb82wd_ROt49RDDi_-8u6vgdH7Be/exec";
  const setEmailHandler = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (!checkIsValidEmail(value)) {
      setIsValidEmail(false);
      setEmailError("*invalid email address");
    } else {
      setIsValidEmail(true);
      setEmailError("");
    }
  };

  const setNameHandler = (e) => {
    const value = e.target.value;
    setName(value);
  };

  const setAffiliationHandler = (e) => {
    const value = e.target.value;
    setAffiliation(value);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let requestBody = new FormData(formRef.current);

    fetch(scriptURL, { method: "POST", body: requestBody })
      .then((response) => {
        setIsSubmitting(false);
        setEmail("");
        setShowAccessModal(false);
        setShowThanksModal(true);
      })
      .catch((error) => {
        setIsSubmitting(false);
        setIsValidEmail(false);
        setEmailError("*invalid email address");
      });
  };

  function checkIsValidEmail(email) {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  return (
    <>
      <div
        className={`styles["bg-light-grayish-blue"] ${styles["navbar-container"]}`}
      >
        {/* Navbar Start  */}
        <div className="container">
          <nav className="navbar navbar-expand-sm py-4">
            <div className="container-fluid">
              <a className={`navbar-brand ${styles['navbar-brand-wrapper']}`} href="#">
                <NewLogoSvg />
              </a>
              <span className="">Coming soon!</span>
            </div>
          </nav>
        </div>
        {/* Navbar End  */}

        {/* Cloud Section Start */}
        <div className={`container-fluid h-auto ${styles["cloud-outer"]}`}>
          <div
            className={`${styles["cloud_flex_direction"]} row align-items-center`}
          >
            <div className="col-12 col-lg-6 pt-4">
              <h1 className="display-3">
                An AI Assistant for your{" "}
                <strong className="fw-bold ">Cloud journey.</strong>
              </h1>
              <p className="fs-5 my-5">
                JigsawML sits atop your Cloud, considers the world you are in,
                and allows you to manage your cloud systems more effectively.
              </p>
              <button
                className={`${styles["btn"]} ${styles["join-btn"]}`}
                type="button"
                onClick={() => {
                  setEmail("");
                  setIsValidEmail(null);
                  setEmailError(false);
                  setShowAccessModal(true);
                }}
              >
                Join the waitlist
              </button>
            </div>
            <div
              className={`${styles[`updown_animation`]} col-12 col-lg-6 d-flex flex-wrap ${
                styles["col-six-right"]
              }`}
            >
              {/* <img src={banner} alt="" className="w-100" /> */}

              {/* ///////////////ONE START////////////////// */}
              <div className={`position-relative w-50`}>
                <div  className={styles["first-tile"]}>
                <div className={styles["set-wrapper"]}>
                  {" "}
                  <SetOneSvg />
                </div>
                <div className="position-relative ">
                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["blue-btn-svg-one"]}`}
                    id={styles["svg-zero"]}
                    style={{}}
                  >
                    <BlueSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["blue-btn-svg-two"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <BlueTwoSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["blue-btn-svg-three"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <BlueThreeSvg />
                  </div>
                </div>
                </div>
              </div>
              {/* ///////////////ONE END////////////////// */}

              {/* ///////////////TWO START////////////////// */}
              <div
                className={`position-relative w-50 z-1`}
              >
              <div className={styles["second-tile"]}>
                <div className={styles["set-wrapper"]}>
                  <SetOneSvg />
                </div>
                <div className="position-relative ">
                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["purple-btn-svg-one"]}`}
                    id={styles["svg-zero"]}
                    style={{}}
                  >
                    <BlueSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["purple-btn-svg-two"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <PurpleTwoSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["purple-btn-svg-three"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <PurpleThreeSvg />
                  </div>
                </div>
                </div>
              </div>
              {/* ///////////////TWO END////////////////// */}

              {/* ///////////////THREE START////////////////// */}
              <div
                className={`position-relative w-50 z-1`}
              >
              <div  className={styles["third-tile"]}>
                <div className={styles["set-wrapper"]}>
                  <SetOneSvg />
                </div>
                <div className="position-relative ">
                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["green-btn-svg-one"]}`}
                    id={styles["svg-zero"]}
                    style={{}}
                  >
                    <BlueSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["green-btn-svg-two"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <GreenTwoSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["green-btn-svg-three"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <GreenThreeSvg />
                  </div>
                </div>
                </div>
              </div>
              {/* ///////////////THREE END////////////////// */}

              {/* ///////////////FOUR START////////////////// */}
              <div
                className={`position-relative w-50 z-1`}
              >
              <div  className={styles["fourth-tile"]}>
                <div className={styles["set-wrapper"]}>
                  <SetOneSvg />
                </div>
                <div className="position-relative ">
                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["brown-btn-svg-one"]}`}
                    id={styles["svg-zero"]}
                    style={{}}
                  >
                    <BlueSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["brown-btn-svg-two"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <BrownTwoSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["brown-btn-svg-three"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <BrownThreeSvg />
                  </div>
                </div>
                </div>
              </div>
              {/* ///////////////FOUR END////////////////// */}

              {/* /////////////Mobile And Tablet Start///////////// */}
              {/* <div className="row">
                  <div className="col-sm-12 ">
                   1
                  </div>
                  <div className="col-sm-12 ">
                   2
                  </div>
                  <div className="col-sm-12 ">
                   3
                  </div>
                  <div className="col-sm-12 ">
                   4
                  </div>
               </div> */}
              {/* /////////////Mobile And Tablet End/////////////// */}
            </div>
          </div>
        </div>
        {/* Cloud Section End */}

        {/* Intelligence Section Start */}
        <div
          className={`container-fluid h-auto ${styles["intelligence-section"]}`}
        >
          <div className="row">
            <div
              className="col-md-5 col-sm-12 d-flex justify-content-center align-items-center overflow-hidden "
              style={{ minHeight: "300px" }}
            >
              <div className="position-relative  w-100 h-100 ">
                <div className={styles["polygon-box"]}>
                  <div
                    className={`${styles["polygon"]} ${styles["polygon1"]}`}
                  ></div>
                  <div
                    className={`${styles["polygon"]} ${styles["polygon2"]}`}
                  ></div>
                  <div
                    className={`${styles["polygon"]} ${styles["polygon3"]}`}
                  ></div>
                  <div
                    className={`${styles["polygon"]} ${styles["polygon4"]}`}
                  ></div>
                </div>
                <div className={styles["centered-text"]}>
                  <p>Our Vision</p>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-sm-12">
              <div className={styles["intelligence-right-section"]}>
                <p
                  className="text-white text-start"
                  style={{ maxWidth: "40ch" }}
                >
                  A Cloud that is much simpler to operate.{" "}
                </p>
                <p
                  className="text-white text-start"
                  style={{ maxWidth: "40ch" }}
                >
                  JigsawML is taking a completely new top-down approach that
                  redefines how intuitive your Cloud experience should be by
                  using the latest in AI and Design.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Intelligence Section End */}

        {/* Our Vision Section Start */}
        {/* <div className={`container h-auto mt-4 ${styles["vision-container"]}`}>
          <div className={`row position-relative  ${styles["bg-dark-blue"]}`}>
            <div className="col-12">
              <div
                className={`position-absolute ${styles["vision-animate-wrapper"]}`}
              >
                <img
                  src={masklayer2}
                  alt="layer-2"
                  className={`rounded-circle ${styles["vision-animate"]}`}
                  style={{
                    background: "#E9EDF5",
                  }}
                />
              </div>
              <div className="w-75 mx-auto text-start">
                <h2
                  className={`opacity-25 fs-1 mb-0 ${styles["vision-heading"]}`}
                  style={{
                    marginTop: "8.25rem",
                  }}
                >
                  Our vision
                </h2>
                <p
                  className={`mt-4 mb-0 fs-2 fw-normal ${styles["vision-text"]}`}
                  style={{
                    fontFamily: "Work Sans",
                  }}
                >
                  To build an intelligence layer that hides away all the
                  complexity that has come to be associated with the cloud.
                </p>

                <p
                  className={`mt-4 fs-2 fw-normal ${styles["vision-text"]}`}
                  style={{
                    fontFamily: "Work Sans",
                    marginBottom: "8.25rem",
                  }}
                >
                  We are taking a top-down approach in rethinking how a more
                  intuitive Cloud experience should look like.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* Our Vision Section End */}
        {/* You think Section Start */}
        <div className={`container position-relative h-auto ${styles["puzzleDivContainer"]}`}>
          <div className={styles["puzzleTextContainer"]}>
             <div className={styles["puzzleTextFlex"]}>
                 <p className={styles["f-38"]}><strong>Puzzles</strong> that bother you</p>
                 <p className={styles["f-72"]}>We <b>piece</b> them together</p>
             </div>
          </div>
          <div style={{display: "flex", width: "100%"}} className={styles["leftSideTextPart"]}>
            <div className={styles["leftSidePuzzelSec"]}>
              <div id={styles["reaction"]} className={styles["item1"]}>
                <div id={styles["top-left"]} className={styles["corner"]}></div>
                <div id={styles["bottom-left"]} className={styles["corner"]}></div>
                <div className="content">
                     <span className={styles["title"]}>Is my S3 bucket secure</span>
                </div>
                <img src={s3Bucket} className={styles["puzzlesImg"]} />
              </div>
              <div id={styles["reaction"]} className={styles["item2"]}>
                <div id={styles["top-left"]} className={styles["corner"]}></div>
                <div id={styles["bottom-left"]} className={styles["corner"]}></div>
                <div className="content">
                     <span className={styles["title"]}>The bill for one of the accounts increased 3x. How to bring it down  without impacting biz?</span>
                </div>
                <img src={dollar} className={styles["puzzlesImg"]} />
              </div>

              <div id={styles["reaction"]} className={styles["item3"]}>
                <div id={styles["top-left"]} className={styles["corner"]}></div>
                <div id={styles["bottom-left"]} className={styles["corner"]}></div>
                <div className="content">
                     <span className={styles["title"]}>How is this Lambda function contributing to my end-to-end latency?</span>
                </div>
                <img src={lambda} className={styles["puzzlesImg"]} />
              </div>
              </div>
              <div className = {styles["fold3ImgContainer"]} style={{}}><img className = {styles["puzzelsImage"]}  src = {puzzleImage}></img><img className = {styles["blockTick"]} src = {blockTickImage} /></div>
          </div>
          <div>
          </div>
          {/* <div className="row m-0 p-0">
            <div className="col-md-12" style={{ height: "60rem" }}>
              <div
                id="12345"
                className={`position-absolute ${styles["radial-svg"]}`}
              >
                <img
                  src={sphereAnimation}
                  style={{ transform: "rotate(269deg)" }}
                />
              </div>
              <div
                className={`mt-5 d-flex text-start align-items-center justify-content-evenly  ${styles["think-title"]}`}
              >
                <div className={styles["think-left-title"]}>
                  <p className={`display-3 fw-normal mx-auto `}>
                    <strong>Puzzles </strong> that <br /> bother you
                  </p>
                </div>
                <div className={styles["think-right-title"]}>
                  <p className={`display-3 fw-normal mx-auto`}>
                    We <strong>piece</strong> <br />
                    them together
                  </p>
                </div>
              </div>
              <div
                className={`d-flex justify-content-center align-items-start ${styles["think-img-wrapper"]}`}
              >
                <div className={`position-relative  ${styles["big-hexa-svg"]}`}>
                  <div
                    className={`position-absolute ${styles["question-animate-one"]}`}
                    style={{ left: "-158px" }}
                  >
                    <div className={`position-relative`}>
                      <HexaBigSvg />

                      <div
                        className={`p-4 position-absolute rounded-4 text-start d-inline ${styles["question"]}`}
                        style={{
                          backgroundColor: "#FFFDF0",
                          top: "-6%",
                          right: "72%",
                        }}
                      >
                        How can I transition to multi-cloud without incurring
                        huge costs and risks?
                      </div>

                      <div
                        className={`p-4 position-absolute rounded-4 text-start d-inline ${styles["question"]}`}
                        style={{
                          backgroundColor: "#F0FCFF",
                          top: "31%",
                          right: "94%",
                        }}
                      >
                        Is there an easy way to generate a Python example to try
                        this application?
                      </div>

                      <div
                        className={`p-4 position-absolute rounded-4 text-start d-inline ${styles["question"]}`}
                        style={{
                          backgroundColor: "#FCF0FF",
                          top: "90%",
                          right: "88%",
                        }}
                      >
                        Is our application load balancer configured properly for
                        high availability?
                      </div>
                    </div>
                  </div>

                  <div
                    className={`position-absolute ${styles["question-animate-two"]}`}
                    style={{ top: "-17px", left: "-158px" }}
                  >
                    <div className={`position-relative `}>
                      <HexaBigTwoSvg />
                      <div
                        className={`p-4 position-absolute rounded-4 text-start d-inline ${styles["question"]}`}
                        style={{
                          backgroundColor: "#FFFDF0",
                          top: "-8%",
                          right: "62%",
                        }}
                      >
                        Are we sure that this application has end-to-end
                        encryption?
                      </div>
                      <div
                        className={`p-4 position-absolute rounded-4 text-start d-inline ${styles["question"]}`}
                        style={{
                          backgroundColor: "#F0FCFF",
                          top: "36%",
                          right: "100%",
                        }}
                      >
                        How much are we spending on Azure Blob Storage?
                      </div>
                      <div
                        className={`p-4 position-absolute rounded-4 text-start d-inline ${styles["question"]}`}
                        style={{
                          backgroundColor: "#FCF0FF",
                          top: "90%",
                          right: "87%",
                        }}
                      >
                        What’s the current status of security patches for our
                        application stack?
                      </div>
                    </div>
                  </div>

                  <div
                    className={`position-absolute ${styles["question-animate-three"]}`}
                    style={{ left: "-158px" }}
                  >
                    <div className={`position-relative`}>
                      <HexaBigSvg />

                      <div
                        className={`p-4 position-absolute rounded-4 text-start d-inline ${styles["question"]}`}
                        style={{
                          backgroundColor: "#FFFDF0",
                          top: "-6%",
                          right: "72%",
                        }}
                      >
                        The bill for one of our enterprise accounts tripled last
                        month. Why the spike and how can we avoid future
                        anomalies like this without impacting business?
                      </div>

                      <div
                        className={`p-4 position-absolute rounded-4 text-start d-inline ${styles["question"]}`}
                        style={{
                          backgroundColor: "#F0FCFF",
                          top: "31%",
                          right: "94%",
                        }}
                      >
                        There is a latency spike in customer sign-in request
                        flows. How can we determine what’s causing it and how to
                        fix it?
                      </div>

                      <div
                        className={`p-4 position-absolute rounded-4 text-start d-inline ${styles["question"]}`}
                        style={{
                          backgroundColor: "#FCF0FF",
                          top: "90%",
                          right: "88%",
                        }}
                      >
                        What is the cost of S3 usage?
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* You think Section End */}

        {/*Question Section Start*/}
        <div
          className={`container-fluid text-center ${styles["question-section"]}`}
        >
          <div className="row">
            <div
              className={`col-lg-6 col-sm-12 d-flex flex-column justify-content-center align-items-center ${styles["question-section-left"]}`}
            >
              <p
                className={`display-3 fw-normal text-start ${styles["question-heading"]} ${styles["question-heading-desk"]}`}
              >
                <strong>Puzzles</strong> that <br /> bother you
              </p>
              <p
                className={`display-3 fw-normal lh-base ${styles["question-heading"]} ${styles["question-heading-mob"]}`}
              >
                <strong>Solving the questions</strong> that bother you
              </p>

              <div
                className={`d-flex flex-column justify-content-center align-items-center   w-100 ${styles["mobile-hexagon"]}`}
              >
                <div className="position-relative w-100">
                  <div
                    className={`p-4 rounded-4 text-start ${styles["position-top"]} ${styles["mob-top-ques-one"]}`}
                    style={{
                      backgroundColor: "#FCF0FF",
                      maxWidth: "50ch",
                    }}
                  >
                    How can I transition to multi-cloud without incurring huge
                    costs and risks?
                  </div>

                  <div
                    className={`p-4 rounded-4 text-start ${styles["position-top"]} ${styles["mob-top-ques-two"]}`}
                    style={{
                      backgroundColor: "#FCF0FF",
                      maxWidth: "50ch",
                    }}
                  >
                    Can you generate a python example to try the application?
                  </div>

                  <div
                    className={`p-4 rounded-4 text-start ${styles["position-top"]} ${styles["mob-top-ques-three"]}`}
                    style={{
                      backgroundColor: "#FCF0FF",
                      maxWidth: "50ch",
                    }}
                  >
                    Is there an easy way to generate a Python example to try
                    this application?
                  </div>

                  <div
                    className={`p-4 rounded-4 text-start ${styles["position-top"]} ${styles["mob-top-ques-four"]}`}
                    style={{
                      backgroundColor: "#FCF0FF",
                      maxWidth: "50ch",
                    }}
                  >
                    Are we sure that this application has end-to-end encryption?
                  </div>

                  <HexaSvg />
                  <div
                    className={`p-4 rounded-4 text-start ${styles["position-bottom"]} ${styles["mob-top-ques-one"]}`}
                    style={{
                      backgroundColor: "#FCF0FF",
                      maxWidth: "50ch",
                    }}
                  >
                    What’s the current status of security patches for our
                    application stack?
                  </div>

                  <div
                    className={`p-4 rounded-4 text-start ${styles["position-bottom"]} ${styles["mob-top-ques-two"]}`}
                    style={{
                      backgroundColor: "#FCF0FF",
                      maxWidth: "50ch",
                    }}
                  >
                    The bill for one of our enterprise accounts tripled last
                    month. Why the spike and how can we avoid future anomalies
                    like this without impacting business?
                  </div>

                  <div
                    className={`p-4 rounded-4 text-start ${styles["position-bottom"]} ${styles["mob-top-ques-three"]}`}
                    style={{
                      backgroundColor: "#FCF0FF",
                      maxWidth: "50ch",
                    }}
                  >
                    There is a latency spike in customer sign-in request flows.
                    How can we determine what’s causing it and how to fix it?
                  </div>

                  <div
                    className={`p-4 rounded-4 text-start ${styles["position-bottom"]} ${styles["mob-top-ques-four"]}`}
                    style={{
                      backgroundColor: "#FCF0FF",
                      maxWidth: "50ch",
                    }}
                  >
                    Is our application load balancer configured properly for
                    high availability?
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-lg-6 col-sm-12 d-flex justify-content-center ${styles["question-section-right"]}`}
            >
              <p
                className={`display-3 fw-normal text-start ${styles["question-heading"]} ${styles["question-heading-desk"]}`}
              >
                We <strong>piece</strong> <br />
                them together
              </p>
            </div>
          </div>
        </div>
        {/*Question Section End*/}

        {/* <p className="fs-5 my-5">
          <strong className="fw-bold ">
            JigsawML can provide you with instant – and actionable – answers to
            these and many other complex operational questions.
          </strong>
        </p> */}

        {/* Testimonials Slider Section Start */}
        <div
          className={`container-fluid h-auto p-0 bg-white ${styles["testimonials-section"]}`}
        >
          <p
            className={`display-3 fw-medium pt-5 ${styles["testimonials-heading-mob"]}`}
          >
            <strong className="fw-bold">Trust</strong> defines us
          </p>
          <p
            className={`display-3 fw-bold pt-5 ${styles["testimonials-heading"]}`}
          >
            Feedback we have heard:
          </p>
          <div className={`py-5`}>
            <Testimonials />
          </div>
        </div>
        {/* Testimonials Slider Section End */}

        {/* Footer Section Start */}
        <div
          className={`container-fluid h-auto bg-white ${styles["footer-section"]}`}
        >
          <p>
            Email us: <strong>info@jigsawml.com</strong>
          </p>
          <p>&#169; JigsawML 2024</p>
        </div>
        {/* Footer Section End */}
      </div>
      {/* style={{visibility: "hidden", position: "absolute"}} */}
      <svg
        width="10"
        height="10"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <defs>
          <filter id="round">
            <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="goo"
            />
            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
          </filter>
        </defs>
      </svg>
      <ModalUI
        show={showAccessModal}
        handleClose={() => {
          setShowAccessModal(false);
        }}
        modalTitle={""}
        theme="light"
        classes="border-no"
        size="lg"
      >
        <Row>
          <Col>
            <div className={`px-4 ${styles["request-access-container"]}`}>
              <p className="fs-5 text-black opacity-50 mb-0 text-center ">
                Sign up for the beta access
              </p>
              <h1
                className="display-3 text-black fw-bold m-auto text-center"
                style={{ maxWidth: "20ch" }}
              >
                Ready to make your cloud experience seamless?
              </h1>
              <form ref={formRef} onSubmit={formSubmitHandler}>
                <div className={` ${styles["request-access-group"]}`}>
                  <Form.Group className={`${styles["request-access-input"]}`}>
                    <Form.Control
                      value={Name}
                      name="Name"
                      onChange={setNameHandler}
                      placeholder="Name"
                      className={`${styles["custom-input"]}`}
                      required
                    />
                    <Form.Control
                      value={Affiliation}
                      name="Affiliation"
                      onChange={setAffiliationHandler}
                      placeholder="Affiliation"
                      className={`${styles["custom-input"]}`}
                      required
                    />
                    <Form.Control
                      type="email"
                      value={email}
                      name="Email"
                      onChange={setEmailHandler}
                      placeholder="Email"
                      className={`${styles["custom-input"]} ${
                        isValidEmail == false && styles["error"]
                      }`}
                      required
                    />
                    <p className={styles["error-text"]}>
                      {emailError != "" && emailError}
                    </p>
                  </Form.Group>
                  <button
                    className={`${styles["btn"]} ${styles["request-access-btn"]}`}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Request access
                  </button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </ModalUI>

      <ModalUI
        show={showThanksModal}
        handleClose={() => {
          setShowAccessModal(false);
        }}
        modalTitle={""}
        theme="light"
        classes="border-no"
        size="md"
      >
        <Row>
          <Col className="text-center text-black">
            <h1 className={`fw-bold m-auto `} style={{ maxWidth: "16ch" }}>
              Thanks for showing interest :)
            </h1>
            <p className={`opacity-50 mt-2 `}>
              Be a part of the mission of simplifying the cloud. You will hear
              from us shortly.
            </p>
            <button
              className={`m-auto ${styles["btn"]} ${styles["btn-close"]}`}
              type="submit"
              onClick={() => {
                setShowThanksModal(false);
              }}
            >
              Close
            </button>
          </Col>
        </Row>
      </ModalUI>
    </>
  );
};

export default UpdatedLanding;
