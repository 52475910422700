const FileSvg = () => {
  return (
    <svg
      width="45"
      height="38"
      viewBox="0 0 45 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.0533 38H3.94667C2.04 38 0.5 36.4444 0.5 34.5185V3.48148C0.5 1.55556 2.04 0 3.94667 0H16.8533C17.9533 0 18.98 0.518519 19.64 1.33333L23.6 6.44444H41.0533C42.96 6.44444 44.5 8 44.5 9.92593V34.4444C44.5 36.4444 42.96 38 41.0533 38Z"
        fill="white"
      />
    </svg>
  );
};

export default FileSvg;
