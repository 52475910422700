export const stringUrl = [
  { label: "EC2", url: "https://aws.amazon.com/ec2/" },
  { label: "S3", url: "https://aws.amazon.com/s3/" },
  { label: "Lambda function", url: "https://aws.amazon.com/lambda/" },
  { label: "Lambda", url: "https://aws.amazon.com/lambda/" },
  { label: "RDS", url: "https://aws.amazon.com/rds/" },
  { label: "DynamoDB", url: "https://aws.amazon.com/dynamodb/" },
  {
    label: "Elastic Beanstalk",
    url: "https://aws.amazon.com/elasticbeanstalk/",
  },
  { label: "ECS", url: "https://aws.amazon.com/ecs/" },
  { label: "EKS", url: "https://aws.amazon.com/eks/" },
  { label: "API Gateway", url: "https://aws.amazon.com/api-gateway/" },
  { label: "CloudFront", url: "https://aws.amazon.com/cloudfront/" },
  { label: "VPC", url: "https://aws.amazon.com/vpc/" },
  { label: "Route 53", url: "https://aws.amazon.com/route53/" },
  { label: "SNS", url: "https://aws.amazon.com/sns/" },
  { label: "SQS", url: "https://aws.amazon.com/sqs/" },
  { label: "SES", url: "https://aws.amazon.com/ses/" },
  { label: "CloudWatch", url: "https://aws.amazon.com/cloudwatch/" },
  { label: "IAM", url: "https://aws.amazon.com/iam/" },
  { label: "Cognito", url: "https://aws.amazon.com/cognito/" },
  { label: "Redshift", url: "https://aws.amazon.com/redshift/" },
  { label: "Athena", url: "https://aws.amazon.com/athena/" },
  { label: "Glue", url: "https://aws.amazon.com/glue/" },
  { label: "EMR", url: "https://aws.amazon.com/emr/" },
  { label: "Kinesis", url: "https://aws.amazon.com/kinesis/" },
  { label: "Sagemaker", url: "https://aws.amazon.com/sagemaker/" },
  { label: "Quicksight", url: "https://aws.amazon.com/quicksight/" },
  {
    label: "Elasticsearch Service",
    url: "https://aws.amazon.com/elasticsearch-service/",
  },
  { label: "EFS", url: "https://aws.amazon.com/efs/" },
  { label: "FSx", url: "https://aws.amazon.com/fsx/" },
  { label: "Step Functions", url: "https://aws.amazon.com/step-functions/" },
  { label: "AppSync", url: "https://aws.amazon.com/appsync/" },
  { label: "CloudFormation", url: "https://aws.amazon.com/cloudformation/" },
  { label: "CodeCommit", url: "https://aws.amazon.com/codecommit/" },
  { label: "CodeBuild", url: "https://aws.amazon.com/codebuild/" },
  { label: "CodeDeploy", url: "https://aws.amazon.com/codedeploy/" },
  { label: "CodePipeline", url: "https://aws.amazon.com/codepipeline/" },
  { label: "Lightsail", url: "https://aws.amazon.com/lightsail/" },
  { label: "ElastiCache", url: "https://aws.amazon.com/elasticache/" },
  { label: "MQ", url: "https://aws.amazon.com/amazon-mq/" },
  { label: "Neptune", url: "https://aws.amazon.com/neptune/" },
  { label: "DocumentDB", url: "https://aws.amazon.com/documentdb/" },
  { label: "Snowball", url: "https://aws.amazon.com/snowball/" },
  { label: "Snowmobile", url: "https://aws.amazon.com/snowmobile/" },
  { label: "Lex", url: "https://aws.amazon.com/lex/" },
  { label: "Polly", url: "https://aws.amazon.com/polly/" },
  { label: "Rekognition", url: "https://aws.amazon.com/rekognition/" },
  { label: "Translate", url: "https://aws.amazon.com/translate/" },
  { label: "Transcribe", url: "https://aws.amazon.com/transcribe/" },
  { label: "KMS", url: "https://aws.amazon.com/kms/" },
  {
    label: "Secrets Manager",
    url: "https://aws.amazon.com/secrets-manager/",
  },
  { label: "WAF & Shield", url: "https://aws.amazon.com/waf/" },
  { label: "GuardDuty", url: "https://aws.amazon.com/guardduty/" },
  { label: "Macie", url: "https://aws.amazon.com/macie/" },
  { label: "AppSync", url: "https://aws.amazon.com/appsync/" },
  { label: "APIGateway", url: "https://aws.amazon.com/apigateway/" },
  { label: "CloudFront", url: "https://aws.amazon.com/cloudfront/" },
  { label: "CloudTrail", url: "https://aws.amazon.com/cloudtrail/" },
  { label: "MTurk", url: "https://aws.amazon.com/mturk/" },
  { label: "Amplify", url: "https://aws.amazon.com/amplify/" },
  { label: "Pinecone", url: "https://www.pinecone.io/" },
  { label: "OpenAI", url: "https://openai.com/" },
  { label: "Flask", url:"https://flask.palletsprojects.com/en/3.0.x/"},
  { label: "SQLAlchemy", url:"https://www.sqlalchemy.org/"},
  { label: "NLTK", "url":"https://www.nltk.org/"},
  { label:"Celery", "url":"https://docs.celeryq.dev/en/stable/"},
];
