import AzureIconWrapper from './azure-icon-wrapper.component';

const azureNodeTypes = {
    appservice: (props) => <AzureIconWrapper iconType="AppService" {...props} />,
    apimanagement: (props) => <AzureIconWrapper iconType="APIManagement" {...props} />,
    functions: (props) => <AzureIconWrapper iconType="Functions" {...props} />,
    azurefunctions: (props) => <AzureIconWrapper iconType="Functions" {...props} />,
    logicapps: (props) => <AzureIconWrapper iconType="LogicApps" {...props} />,
    virtualmachines: (props) => <AzureIconWrapper iconType="VirtualMachine" {...props} />,
    virtualmachine: (props) => <AzureIconWrapper iconType="VirtualMachine" {...props} />,
    cosmosdb: (props) => <AzureIconWrapper iconType="CosmosDB" {...props} />,
    iot: (props) => <AzureIconWrapper iconType="IoT" {...props} />,
    streamanalytics: (props) => <AzureIconWrapper iconType="StreamAnalytics" {...props} />,
    azurecloud: (props) => <AzureIconWrapper iconType="AzureCloud" {...props} />,
    cdn: (props) => <AzureIconWrapper iconType="CDN" {...props} />,
    ad: (props) => <AzureIconWrapper iconType="ActiveDirectory" {...props} />,
    sql: (props) => <AzureIconWrapper iconType="SQL" {...props} />,
    storageservice: (props) => <AzureIconWrapper iconType="StorageService" {...props} />,
    vmss: (props) => <AzureIconWrapper iconType="VirtualMachineScaleSet" {...props} />,
    appplan: (props) => <AzureIconWrapper iconType="AppPlan" {...props} />,
    eventhubs: (props) => <AzureIconWrapper iconType="EventHub" {...props} />,
    eventhub: (props) => <AzureIconWrapper iconType="EventHub" {...props} />,
    aks: (props) => <AzureIconWrapper iconType="AKS" {...props} />,
    redis: (props) => <AzureIconWrapper iconType="Redis" {...props} />,
    search: (props) => <AzureIconWrapper iconType="Search" {...props} />,
    aml: (props) => <AzureIconWrapper iconType="MachineLearning" {...props} />,
    hdinsight: (props) => <AzureIconWrapper iconType="HDInsight" {...props} />,
    databricks: (props) => <AzureIconWrapper iconType="Databricks" {...props} />,
    servicebus: (props) => <AzureIconWrapper iconType="ServiceBus" {...props} />,
    blobstorage: (props) => <AzureIconWrapper iconType="BlobStorage" {...props} />,
    powerbi: (props) => <AzureIconWrapper iconType="PowerBI" {...props} />,
    cognitivesearch: (props) => <AzureIconWrapper iconType="Search" {...props} />,
    aad: (props) => <AzureIconWrapper iconType="ActiveDirectory" {...props} />,
};

export default azureNodeTypes;
