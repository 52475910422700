import React, { useEffect, useState, useRef, useContext } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import styles from "./view-edit-architecture.module.css";
import Frame from "./Frame";
import NavbarUI from "./NavbarUI";
import LeftSidebar from "./LeftSidebar";
import DataTour from "./components/DataTour/DataTour";
import GuideTour from "../../../common/components/GuideTour/GuideTour";
import steps from "../../../common/components/GuideTour/steps";
import context from "../../../store/create-context";

const ViewEditArchitecture = (props) => {
  //context
  const { showBars, setShowBars, isTourOpen, setIsTourOpen, activeSection, isAnyApplicationSelected} = useContext(context);
  useEffect(()=>{
    if(props.newProject) {
      setIsTourOpen(true);
    }
  }, [props.newProject])

  //states
  const [pendingActivity, setPendingActivity] = useState(false);
  const [checkActivity, setCheckActivity] = useState(false);
  const [toggle, setToggle] = useState(0);
  const [buildModal, setBuildModal] = useState(false);
  const [aiButton, setAiButton] = useState(false);
  const [sidebarData, setSidebarData] = useState({});
  const [items, setItems] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);

  //Handlers
  const toogleHandler = () => {
    setToggle((val) => (val === 0 ? 1 : 0));
  };

  const aiButtonHandler = () => {
    setAiButton(!aiButton);
  };

  const childRef = useRef(null);

  const downloadPDF = () => {
    childRef.current.frameFn();
  };

  // let _i;
  // let section = null;
  // useEffect(() => {
  //   _i = setTimeout(() => {
  //     const presentNameKey = props.sResponse.hasOwnProperty("name");
  //     if (
  //       presentNameKey ||
  //       (!presentNameKey && props.sResponse.nodes?.length > 0) ||
  //       section
  //     ) {
  //       setShowBars((prev) => {
  //         return {
  //           ...prev,
  //           showNavbar: true,
  //           showLeftSidebar: true,
  //           showFooter: true,
  //         };
  //       });
  //     }
  //   });
  //   return () => {
  //     clearTimeout(_i);
  //   };
  // }, [props.sResponse]);

  return (
    <>
      <Container fluid className={activeSection === "infra_view" ? styles["container-fluid"]: styles["container-fluid2"]}>
        {showBars.showNavbar && (
          <NavbarUI
            sResponse={props.sResponse}
            projectName={props.projectName}
            projectDescription={props.projectDescription}
            setSResponse={props.setSResponse}
            downloadPDF={downloadPDF}
            pendingActivity={pendingActivity}
            setPendingActivity={setPendingActivity}
            checkActivity={checkActivity}
            setCheckActivity={setCheckActivity}
            logoutHandler={props.logoutHandler}
            sandboxMode={props.sandboxMode}
            sidebarData={sidebarData}
          />
        )}

        <div className="d-flex flex-row h-100">

          {Object.keys(props.sResponse).length > 0 && showBars.showLeftSidebar && (activeSection !== "infra_view" || isAnyApplicationSelected)  && (
            <DataTour
              tourDataId="reactour__analysis_pane"
              style={{ transform: "rotate(100deg)", top: "2%", left: "50%" }}
            >
              <div style={{ width: "78px" }}>
                <LeftSidebar sidebarData={sidebarData} />
              </div>
            </DataTour>
          )}

          {props.newProject && Object.keys(props.sResponse).length === 0 && (<div className="welcome-message" style={{
              padding: "20px",
              textAlign: "center",
              color: "white",
              position: "fixed",
              top: "70px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              borderRadius: "10px",
              width: "75%", // Cap max width to avoid overflow on large screens
              fontSize: "1vw", // Scale the font size with the viewport
            }}>
              <h3>Welcome to your new Project!</h3>
              <p>Click any of the buttons below to get started.</p>
              <br/>
              <article>
                <p>🚀 <strong>Quick Start:</strong> We've set up a couple of repositories and cloud accounts for you. Access them through the buttons below.
                  <br/>⏳ <em>Setup time for these samples is approximately 5 minutes.</em> Feel free to grab a ☕ during the time.</p>
                <p>Want to use your own repository or cloud account? No problem!
                  <br/>📝 <strong>Note:</strong> We currently support Python and JavaScript applications built on AWS only.
                  <br/>(Support for more coming soon)</p>
                <p>For feedback, reach out to us at <a href="mailto:info@jigsawml.com">info@jigsawml.com</a></p>
            </article>
          </div>
          )}
          <div className="flex-grow-1">
              <Frame
                {...props}
                sResponse={props.sResponse}
                ref={childRef}
                pendingActivity={pendingActivity}
                setPendingActivity={setPendingActivity}
                setSidebarData={setSidebarData}
                items={items}
                setItems={setItems}
                searchLoader={searchLoader}
                setSearchLoader={setSearchLoader}
                setCheckActivity={setCheckActivity}
              />
          </div>
        </div>
        {/* {props.newProject && <GuideTour isOpen={true} steps={steps} />} */}
      </Container>
    </>
  );
};

export default ViewEditArchitecture;
