import React from "react";

const DownSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1545_20227)">
        <path
          d="M7.67994 11.3307C7.4676 11.3307 7.25527 11.2488 7.12256 11.0848L2.23888 6.05751C1.92037 5.72964 1.92037 5.21051 2.23888 4.90996C2.55738 4.5821 3.06167 4.5821 3.35363 4.90996L7.6534 9.33619L11.9797 4.90996C12.2982 4.5821 12.8025 4.5821 13.0945 4.90996C13.413 5.23783 13.413 5.75696 13.0945 6.05751L8.23731 11.0848C8.07806 11.2214 7.86573 11.3307 7.67994 11.3307Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1545_20227">
          <rect
            width="11.3333"
            height="6.66667"
            fill="white"
            transform="translate(2 4.66406)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DownSvg;
