import { useState } from "react";
import { Tab, Tabs, Stack } from "react-bootstrap";
import styles from "./LeftSideActiveLog.module.css";

const ConnectedSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_4705_11902)">
        <path
          d="M12.3852 12.087C12.0929 12.087 11.8005 11.8918 11.703 11.5989C11.5812 11.2084 11.7761 10.7936 12.1659 10.6716C13.1892 10.3299 13.8957 9.35376 13.8957 8.25559C13.8957 6.86457 12.7507 5.7176 11.362 5.7176C11.3376 5.7176 11.3132 5.7176 11.2889 5.7176C10.9722 5.7176 10.6798 5.49796 10.5823 5.20512C10.022 3.35043 8.36529 2.13024 6.44059 2.13024C4.07736 2.13024 2.1283 4.08254 2.1283 6.44971C2.1283 8.03595 2.98101 9.50018 4.39408 10.2567C4.75953 10.4519 4.88134 10.8912 4.68644 11.2573C4.49153 11.6233 4.05299 11.7453 3.68754 11.5501C1.81158 10.5251 0.666504 8.57284 0.666504 6.44971C0.666504 3.27722 3.27337 0.666016 6.44059 0.666016C8.82819 0.666016 10.8991 2.08143 11.8005 4.25337C13.7983 4.49741 15.3332 6.18127 15.3332 8.23118C15.3332 9.96385 14.2368 11.4769 12.6045 12.0382C12.5314 12.0626 12.4583 12.087 12.3852 12.087Z"
          fill="#5A46FF"
        />
        <path
          d="M5.83148 14.2584C5.41731 14.2584 5.10059 13.9411 5.10059 13.5262V7.03485C5.10059 6.61998 5.41731 6.30273 5.83148 6.30273C6.24566 6.30273 6.56238 6.61998 6.56238 7.03485V13.5262C6.56238 13.9167 6.24566 14.2584 5.83148 14.2584Z"
          fill="#5A46FF"
        />
        <path
          d="M7.97553 15.3319C7.56135 15.3319 7.24463 15.0146 7.24463 14.5998V8.71844C7.24463 8.30358 7.56135 7.98633 7.97553 7.98633C8.3897 7.98633 8.70642 8.30358 8.70642 8.71844V14.5998C8.70642 14.9902 8.3897 15.3319 7.97553 15.3319Z"
          fill="#5A46FF"
        />
        <path
          d="M10.144 14.2584C9.72981 14.2584 9.41309 13.9411 9.41309 13.5262V7.03485C9.41309 6.61998 9.72981 6.30273 10.144 6.30273C10.5582 6.30273 10.8749 6.61998 10.8749 7.03485V13.5262C10.8749 13.9167 10.5338 14.2584 10.144 14.2584Z"
          fill="#5A46FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_4705_11902">
          <rect
            width="14.6667"
            height="14.6667"
            fill="white"
            transform="translate(0.666504 0.666016)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
const UploadedSvg = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clipPath="url(#clip0_4705_11942)">
          <path
            d="M12.4 6.53268C12.2045 6.53268 12.0334 6.45935 11.8867 6.31268L8.00003 2.42602L4.11337 6.31268C3.82003 6.60602 3.35559 6.60602 3.0867 6.31268C2.79337 6.01935 2.79337 5.5549 3.0867 5.28602L7.4867 0.886016C7.78003 0.592682 8.24448 0.592682 8.51337 0.886016L12.9134 5.28602C13.2067 5.57935 13.2067 6.04379 12.9134 6.31268C12.7667 6.45935 12.5956 6.53268 12.4 6.53268Z"
            fill="#5A46FF"
          />
          <path
            d="M13.0843 15.3335H2.91539C1.66873 15.3335 0.666504 14.3313 0.666504 13.0846V10.1513C0.666504 9.73575 0.984282 9.41797 1.39984 9.41797C1.81539 9.41797 2.13317 9.73575 2.13317 10.1513V13.0846C2.13317 13.5246 2.47539 13.8669 2.91539 13.8669H13.0843C13.5243 13.8669 13.8665 13.5246 13.8665 13.0846V10.1513C13.8665 9.73575 14.1843 9.41797 14.5998 9.41797C15.0154 9.41797 15.3332 9.73575 15.3332 10.1513V13.0846C15.3332 14.3313 14.3309 15.3335 13.0843 15.3335Z"
            fill="#5A46FF"
          />
          <path
            d="M7.99993 11.5917C7.58438 11.5917 7.2666 11.2739 7.2666 10.8584V2.1806C7.2666 1.76504 7.58438 1.44727 7.99993 1.44727C8.41549 1.44727 8.73327 1.76504 8.73327 2.1806V10.8584C8.73327 11.2739 8.41549 11.5917 7.99993 11.5917Z"
            fill="#5A46FF"
          />
        </g>
        <defs>
          <clipPath id="clip0_4705_11942">
            <rect
              width="14.6667"
              height="14.6667"
              fill="white"
              transform="translate(0.666504 0.666016)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

const FilterSvg = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
      >
        <g clipPath="url(#clip0_4705_10079)">
          <path
            d="M7.56565 13.0007C7.44243 13.0007 7.31922 12.9759 7.196 12.9017L4.70702 11.4915C4.48523 11.3678 4.33737 11.1204 4.33737 10.8483V6.88997L0.148011 1.54622C-0.0244926 1.32357 -0.0491359 1.02669 0.0740807 0.754557C0.197297 0.507161 0.468374 0.333984 0.73945 0.333984H11.9275C12.1986 0.333984 12.4697 0.507161 12.5929 0.754557C12.7161 1.00195 12.6915 1.32357 12.519 1.54622L8.32959 6.91471V12.2585C8.32959 12.5306 8.18173 12.778 7.95994 12.9017C7.83673 12.9759 7.71351 13.0007 7.56565 13.0007ZM5.84062 10.4277L6.85099 10.9967V6.64258C6.85099 6.4694 6.90028 6.32096 6.99885 6.17253L10.3996 1.81836H2.26734L5.66811 6.19727C5.76669 6.32096 5.81597 6.49414 5.81597 6.66732V10.4277H5.84062Z"
            fill="#F8F8FF"
          />
        </g>
        <defs>
          <clipPath id="clip0_4705_10079">
            <rect
              width="12.6667"
              height="12.6667"
              fill="white"
              transform="translate(0 0.333984)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

const distanceToNowFormat = (currentTime, previousTime) => {
  const timeDifferenceMS = currentTime - previousTime;
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;
  if (timeDifferenceMS < msPerMinute) {
    return Math.round(timeDifferenceMS / 1000) + " seconds ago";
  } else if (timeDifferenceMS < msPerHour) {
    return Math.round(timeDifferenceMS / msPerMinute) + " minutes ago";
  } else if (timeDifferenceMS < msPerDay) {
    return Math.round(timeDifferenceMS / msPerHour) + " hours ago";
  } else if (timeDifferenceMS < msPerMonth) {
    return Math.round(timeDifferenceMS / msPerDay) + " days ago";
  } else if (timeDifferenceMS < msPerYear) {
    return Math.round(timeDifferenceMS / msPerMonth) + " months ago";
  } else {
    return Math.round(timeDifferenceMS / msPerYear) + " years ago";
  }
};

let currentTimeStamp = Date.now();

//STATES

const LeftSideActiveLog = ({ activitylog }) => {
  const [activeTab, setActiveTab] = useState("actions");
  return (
    <>
      <div className={styles["activity_log_wrapper"]}>
        <Tabs
          defaultActiveKey={activeTab}
          id="activity_log"
          className="mt-3 mb-4 mx-2"
          variant="underline"
          activeKey={activeTab}
          onSelect={(val) => setActiveTab(val)}
        >
          <Tab
            eventKey="actions"
            title="Actions"
            className={`${styles["activity_log_content"]}`}
            tabClassName={`${
              activeTab == "actions"
                ? styles["active-tab"]
                : styles["inactive-tab"]
            }`}
          >
            <Stack gap={4}>
              {activitylog?.actions?.map((action) => (
                <div className="d-flex flex-row align-items-start gap-3">
                  <span className={styles["activity-log-icon"]}>
                    <ConnectedSvg />
                  </span>
                  <p className={`flex-fill ${styles["activity-log-content"]}`}>
                    {action.activity ?? ""}
                  </p>
                  <small className={styles["activity-log-time"]}>
                    {distanceToNowFormat(
                      currentTimeStamp,
                      action.timestamp * 1000
                    )}
                  </small>
                </div>
              ))}
            </Stack>
          </Tab>
          <Tab
            eventKey="uploads"
            title="Uploads"
            tabClassName={`${
              activeTab == "uploads"
                ? styles["active-tab"]
                : styles["inactive-tab"]
            }`}
          >
           {activitylog?.uploads.length > 0 && <div className="text-end"><FilterSvg /></div>}
            <div className={`${styles["uploads_log_content"]}`}>
              {activitylog?.uploads?.map((upload) => (
                <div class={styles["uploads_log_content_item"]}>
                  <div className={styles["upload-file"]}>
                    <a href={upload.doc_url ?? ""} target="_blank">
                      docx
                    </a>
                  </div>
                  <small className={styles["upload-file-name"]}>
                    {upload.activity ?? ""}
                  </small>
                </div>
              ))}
            </div>
          </Tab>
          <Tab
            eventKey="connections"
            title="Connections"
            className={`${styles["activity_log_content"]}`}
            tabClassName={`${
              activeTab == "connections"
                ? styles["active-tab"]
                : styles["inactive-tab"]
            }`}
          >
            <Stack gap={4}>
              {activitylog?.connections?.map((connection, index) => (
                <div key={index} className="d-flex flex-row align-items-start gap-3">
                  <span className={styles["activity-log-icon"]}>
                    <ConnectedSvg />
                  </span>
                  <p className={`flex-fill ${styles["activity-log-content"]}`}>
                    {connection.repo_url ? (
                      <a href={connection.repo_url} target="_blank">
                        {connection.activity}
                      </a>
                    ) : (
                      connection.activity
                    )}
                  </p>
                  <small className={styles["activity-log-time"]}>
                    {distanceToNowFormat(
                      currentTimeStamp,
                      connection.timestamp * 1000
                    )}
                  </small>
                </div>
              ))}
            </Stack>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default LeftSideActiveLog;
