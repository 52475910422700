const PropConsSvg = () => {
  return (
    <svg
      width="34"
      height="16"
      viewBox="0 0 34 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1542_13270)">
        <path
          d="M12.5426 9.12046H2.7907C2.34264 9.12046 2 8.77782 2 8.32976C2 7.8817 2.34264 7.53906 2.7907 7.53906H12.5426C12.9907 7.53906 13.3333 7.8817 13.3333 8.32976C13.3333 8.77782 12.9907 9.12046 12.5426 9.12046Z"
          fill="white"
        />
        <path
          d="M7.66667 13.9974C7.21861 13.9974 6.87598 13.6548 6.87598 13.2067V3.45476C6.87598 3.0067 7.21861 2.66406 7.66667 2.66406C8.11474 2.66406 8.45737 3.0067 8.45737 3.45476V13.2067C8.45737 13.6548 8.11474 13.9974 7.66667 13.9974Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip1_1542_13270)">
        <path
          d="M30.5426 9.12046H20.7907C20.3426 9.12046 20 8.77782 20 8.32976C20 7.8817 20.3426 7.53906 20.7907 7.53906H30.5426C30.9907 7.53906 31.3333 7.8817 31.3333 8.32976C31.3333 8.77782 30.9907 9.12046 30.5426 9.12046Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1542_13270">
          <rect
            width="11.3333"
            height="11.3333"
            fill="white"
            transform="translate(2 2.66406)"
          />
        </clipPath>
        <clipPath id="clip1_1542_13270">
          <rect
            width="11.3333"
            height="11.3333"
            fill="white"
            transform="translate(20 2.66406)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PropConsSvg;
