import ELK from "elkjs/lib/elk.bundled.js";

const screenWidth = window.innerWidth;
const screenHeight = window.innerHeight;

export const elkOptions = {
  "elk.algorithm": "layered",
  "elk.layered.spacing.nodeNodeBetweenLayers": `${screenWidth*0.18}`,
  "elk.spacing.nodeNode": `${screenHeight*0.07}`,
  'elk.layered.nodePlacement.strategy': 'SIMPLE'
  // 'elk.layered.nodePlacement.strategy': 'NETWORK_SIMPLEX'

};

/**
 * ==================================================
 * ELK ALGORITHM TO CALCULATE NODE AND EDGE POSITION
 * ==================================================
 */
const getLayoutedElements = async (nodes, edges, options = {}) => {
  const elk = new ELK();
  const isHorizontal = options?.["elk.direction"] === "RIGHT";
  const graph = {
    id: "root",
    layoutOptions: options,
    children: nodes.map((node) => ({
      ...node,
      // Adjust the target and source handle positions based on the layout direction.
      targetPosition: isHorizontal ? "left" : "top",
      sourcePosition: isHorizontal ? "right" : "bottom",

      // Hardcode a width and height for elk to use when layouting.
      width: 140,
      height: 110,
        properties: {
          'org.eclipse.elk.portConstraints': 'FIXED_ORDER',
        },
    })),
    edges: edges,
  };

  try {
    const layoutedGraph = await elk.layout(graph);
    return {
      nodes: layoutedGraph.children.map((node) => ({
        ...node,
        position: { x: node.x, y: node.y },
      })),
      edges: layoutedGraph.edges,
    };
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * CALCULATE NODES X Y POSITION BY ELKJS ALGORITHM
 */
export async function CalulateNodesXYPosition(ns, es) {
  const opts = { "elk.direction": "RIGHT", ...elkOptions };
  const { nodes, edges } = await getLayoutedElements(ns, es, opts);

  return {
    nodes,
    edges,
  };
}
