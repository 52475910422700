import storage from "../../../images/Storage.svg";
import compute from "../../../images/Compute.svg";
import analytics from "../../../images/Analytics_Category.svg";
import database from "../../../images/Database_Category.svg";
import machinelearning from "../../../images/Machine-Learning_Category.svg";

export const icons = {
    Compute: <img src={compute} alt="Compute" />,
    Storage: <img src={storage} alt="Storage" />,
    Analytics: <img src={analytics} alt="Analytics" />,
    Database: <img src={database} alt="Database" />,
    "Application Integration": <img src={machinelearning} alt="Application Integration" />,
};