const MinusSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.92 7V8.992H4V7H11.92Z" fill="white" />
    </svg>
  );
};

export default MinusSvg;
