import { useState } from "react";
import styles from './DialogModal.module.css';

const DialogModal = (props) => {

  const { position, options,dialogClickHandler } = props;
  const style = {
    top: position.y + "px",
    left: position.x + "px",
  };

  return (
    <div className={styles['dialogWrapper']} style={style}>
      {options.map((option,key) => (
        <div
          key={key}
          className={`${styles['dialogWrapper_options']} ${option.action}`}
          onClick={() => {dialogClickHandler(option.action)}}
        >
          {option.text}
        </div>
      ))}
    </div>
  );
};

export default DialogModal;
