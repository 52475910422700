import { PointSvg } from "../../../../../images/svg";

const DataTour = (props) => {
  let style = {
    display: "none",
    zIndex: "999999",
    transform: "rotate(120deg)",
    top: "100%",
  };

  if (props.style) {
    style = {
      ...style,
      ...props.style,
    };
  }

  return (
    <div
      data-tut={props.tourDataId}
      className="reactour__upload position-relative d-flex"
      // style={{ gap: "0.1rem" }}
    >
      <div className="reactour__icon position-absolute" style={style}>
        <PointSvg />
      </div>
      {props.children}
    </div>
  );
};

export default DataTour;
