import Canvas from "../new-canvas/canvas.component";
import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";
import DefaultCanvasModal from "../new-canvas/component/DefaultCanvasModal";
import { Spinner } from "react-bootstrap";
import { useContext } from "react";
import context from "../../../store/create-context";

const Frame = forwardRef(
  (
    {
      sResponse,
      qResponse,
      setSResponse,
      setQResponse,
      descriptionId,
      socket,
      loadingArch,
      setLoadingArch,
      projectId,
      projectName,
      pendingActivity,
      setPendingActivity,
      setSidebarData,
      items,
      setItems,
      searchLoader,
      setSearchLoader,
      footerSearchText,
      setFooterSearchText,
      setCheckActivity,
      useCache,
    },
    ref
  ) => {
    const frameRef = useRef(null);
    const [modal, setModal] = useState(false);
    // const [searchLoader, setSearchLoader] = useState(false);
    const navigate = useNavigate();
    const [templateName, setTemplateName] = useState("");
    const reactFlowRef = useRef(null);
    const handleDefault = () => {
      // Code to handle default action
      console.log("Default action");
    };
    const [selectedFiles, setSelectedFiles] = useState([]);
    const { setShowToastr } = useContext(context);
    const generatePdf = () => {
      // Code to generate pdf
      console.log("Generating pdf");
      var filename = sResponse["name"]
        ? sResponse["name"]
        : sResponse["user_input"]
        ? sResponse["user_input"]
        : "architecture";
      filename = filename + ".pdf";
      // Get the divs
      var app_name = document.getElementById("app_name").outerHTML;
      var btf_canvas = document.getElementById("btf_canvas").outerHTML;

      // Create a temporary div to merge the divs
      var mergedContent = document.createElement("div");
      mergedContent.innerHTML = app_name + btf_canvas;

      // var element = document.getElementById("arch_atf_btf");
      html2pdf()
        .from(mergedContent)
        .set({
          margin: 10,
          filename: filename,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        })
        .save();

      if (reactFlowRef.current === null) {
        console.log("flowRef is null");
        return;
      }
    };

    const handleDeploy = () => {
      // Code to handle deployment action
      console.log("Deploying template: " + templateName);
      socket.send("deploy", JSON.stringify({ templateName: templateName }));
    };

    // const ActionButton = [
    //   { label: "View Code", action: handleDefault, available: true },
    //   { label: "Download", action: generatePdf, available: true },
    //   // { label: "Deploy", action: handleDeploy, available: true },
    //   { label: "LiveSync", action: handleDefault, available: false },
    //   { label: "Share", action: handleDefault, available: false },
    // ];

    useEffect(() => {
      const handleUnauthorized = () => {
        navigate("/login");
      };

      window.addEventListener("unauthorized", handleUnauthorized);

      return () => {
        window.removeEventListener("unauthorized", handleUnauthorized);
      };
    }, [navigate]);

    useEffect(() => {
      console.log("Items: ", items);
      if (items.length > 0) {
        items.forEach((item) => {
          setSearchLoader(false);
        });
      } else {
        setSearchLoader(false);
      }
    }, [items]);

    const { conversation } = qResponse;

    const QuestionsAndResponse =
      conversation && conversation[conversation["latest_turn"]];

    const QuestionAnswer =
      QuestionsAndResponse &&
      Object.keys(QuestionsAndResponse)?.map((item, index) => {
        return {
          question: QuestionsAndResponse[item].question,
          candidateResponse: QuestionsAndResponse[item]["candidate_response"],
        };
      });

    const backBtn = "< Generate architecture";

    const handleFilesSelected = (files) => {
      console.log("Selected files:", files);
      // You can now process the files or send them to a server
      setSelectedFiles(files);
    };

    const ActionButtonList = ({ action, ActionButton }) => {
      return (
        <div className=" position-absolute action-button-group">
          {!action &&
            ActionButton.map((actionBtn, index) => (
              <div key={index}>
                <div className="action-dropdown">
                  <div className="navButton">
                    <Button
                      className="appButton btn-secondary px-3 "
                      onClick={actionBtn.action}
                      style={{ opacity: 1, width: 126 }}
                      disabled={!actionBtn.available || !sResponse}
                    >
                      <span className=" px-2">{actionBtn.label}</span>
                    </Button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      );
    };

    /*
    Send request via socket and add 
    event listener to retrieved workspace state
  */
    useEffect(() => {
      // catch all for all architecture related updates
      const handleArchUpdate = (event) => {
        if (event?.success === false) {
          setShowToastr({
            show: true,
            type: "error",
            text: event?.error,
          });
        } else if ("architecture" in event) {
          let data = event["architecture"];

          // setSResponse(event['architecture']);

          //added on 12-12-2023
          if (data !== "") {
            let views = [];
            if (data.hasOwnProperty("doc_view")) views.push("doc_view");
            if (data.hasOwnProperty("code_view")) views.push("code_view");
            if (data.hasOwnProperty("infra_view")) views.push("infra_view");

            for (let key in data) {
              if (!views.includes(key)) {
                views.forEach((view) => {
                  if (!data[view].hasOwnProperty(key)) {
                    data[view][key] = data[key];
                  }
                });
              }
            }
          }
          if (Object.keys(data).length == 2) {
            if ("nodes" in data && "edges" in data) {
              if (data["nodes"].length === 0 && data["edges"].length === 0) {
                data = {};
              }
            }
          }
          setSResponse(data);
        }
      };
      socket.addEventListener("archupdate", handleArchUpdate);
      socket.addEventListener("retrieve_project_state", handleArchUpdate);

      if (projectId) {
        /*setting projectId to JML workspace id*/
        let jml_workspace_id = sessionStorage.getItem("jml_workspace_id");
        if (jml_workspace_id !== projectId) {
          console.log(
            "JML workspace ",
            jml_workspace_id,
            " is not same as project id ",
            projectId
          );
          console.log(
            "Retrieving project state for project id: ",
            jml_workspace_id
          );
          socket.send(
            "retrieve_project_state",
            JSON.stringify({ jml_workspace_id: jml_workspace_id })
          );
        }                                                                                                                                                                                                                                                         
      }
      return () => {
        socket.removeEventListener("retrieve_project_state", handleArchUpdate);
        socket.removeEventListener("archupdate", handleArchUpdate);
      };
    }, []);

    useImperativeHandle(ref, () => ({
      frameFn() {
        frameRef.current.downloadPdfWithReactFlowGraph();
      },
    }));

    return Array.isArray(sResponse) || sResponse == "" ? (
      <Button
        variant="dark"
        style={{
          marginTop: "25%",
          pointerEvents: "none",
          background: "#0C0C0C",
          border: "none",
        }}
      >
        <Spinner animation="grow" size="sm" />
        <span style={{ marginLeft: "5px" }}>Loading...</span>
      </Button>
    ) : Object.keys(sResponse).length > 0 ? (
      <Canvas
        sResponse={sResponse}
        setQResponse={setQResponse}
        setSResponse={setSResponse}
        setModal={setModal}
        socket={socket}
        loadingArch={loadingArch}
        setLoadingArch={setLoadingArch}
        flowRef={reactFlowRef}
        items={items}
        setItems={setItems}
        searchLoader={searchLoader}
        setSearchLoader={setSearchLoader}
        descriptionId={descriptionId}
        projectName={projectName}
        ref={frameRef}
        pendingActivity={pendingActivity}
        setPendingActivity={setPendingActivity}
        setSidebarData={setSidebarData}
        footerSearchText={footerSearchText}
        setFooterSearchText={setFooterSearchText}
      />
    ) : (
      <DefaultCanvasModal
        sResponse={sResponse}
        setSResponse={setSResponse}
        descriptionId={descriptionId}
        socket={socket}
        setPendingActivity={setPendingActivity}
        setCheckActivity={setCheckActivity}
      />
    );
  }
);

export default Frame;
