import React, { useEffect, useState, useContext } from "react";
import styles from "./view-edit-architecture.module.css";
import WebSocketInstance from "../../../common/WebSocketInstance";
import {
  DownloadSvg,
  DocSvg,
  NotionIconSvg,
  TerraformIconSvg,
  PulumiIconSvg,
} from "../../../images/svg/icons";
import OverLayTooltip from "./components/OverLayTooltip/OverLayTooltip";
import { Dropdown, Nav, OverlayTrigger } from "react-bootstrap";
import context from "../../../store/create-context";

const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const toggle = () => setState(!state);
  return [state, toggle];
};

const DownloadButtonDropdown = ({
  setCheckActivity,
  projectName,
}) => {
  const { setShowToastr, openSpinnerHandler, closeSpinnerHandler } = useContext(context);
  const [isPlusDropdownOpen, togglePlusDropdown] = useToggle();

  const getTerraform = () => {
    openSpinnerHandler();
    const socket = WebSocketInstance.getInstance();
    socket.send("retrieve_terraform", {
      project_id: sessionStorage.getItem("jml_workspace_id"),
    });
    setCheckActivity(true);
  };

  const downloadPDF = () => {
    openSpinnerHandler();
    const socket = WebSocketInstance.getInstance();
    socket.send("retrieve_pdf", {
      project_id: sessionStorage.getItem("jml_workspace_id"),
    });
    setCheckActivity(true);
  };

  useEffect(() => {
    const socket = WebSocketInstance.getInstance();
    socket.addEventListener("retrieve_pdf", pdfReceipt);
    socket.addEventListener("retrieve_terraform", terraformReceipt);
    setCheckActivity(true); // Set checkActivity to true when the component initially loads

    return () => {
      socket.removeEventListener("retrieve_pdf", pdfReceipt);
      socket.removeEventListener("retrieve_terraform", terraformReceipt);
    };
  }, []);

  const pdfReceipt = (event) => {
    if (event?.success === false) {
      setShowToastr({
        show: true,
        type: "error",
        text: event?.error,
      });
    } else {
      const blob = new Blob([event.pdf_data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = projectName + ".pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    closeSpinnerHandler();
  };

  const terraformReceipt = (event) => {
    if (event?.success === false) {
      setShowToastr({
        show: true,
        type: "error",
        text: event?.error,
      });
    } else {
      // console.log("terraformReceipt", event);
      // Convert the event object to a string format. Adjust the serialization as needed.
      const content = JSON.stringify(event["terraform_code"], null, 2);

      // Create a blob of the content
      const blob = new Blob([content], { type: "text/plain" });

      // Create a link element
      const link = document.createElement("a");

      // Assign the blob URL to the link's href
      link.href = URL.createObjectURL(blob);

      // Set the download attribute with a filename
      link.download = projectName + ".tf";

      // Append the link to the document, trigger a click to start the download, and then remove the link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    closeSpinnerHandler();
  };

  return (
    <>
      <Dropdown
        className={`${styles["navigation-item"]} ${styles["navigation-item-download"]
        } ${styles["patch-dropdown"]} ${
          isPlusDropdownOpen && styles["navigation-item-active"]}`}
        drop="start"
        show={isPlusDropdownOpen}
        onToggle={() => togglePlusDropdown()}
        data-testid="plus-dropdown"
      >
        <OverLayTooltip placement="left" info="Download">
          <Dropdown.Toggle>
            <DownloadSvg />
          </Dropdown.Toggle>
        </OverLayTooltip>

        <Dropdown.Menu className={`${styles["plus-dropdowm-menu"]}`}>
          <Dropdown.Item
            className={styles["plus-dropdowm-item"]}
            href="#"
            onClick={downloadPDF}
            data-testid="file-upload"
          >
            <DocSvg />
            <span>Design Doc (pdf)</span>
          </Dropdown.Item>

          <Dropdown.Item
            className={styles["plus-dropdowm-item"]}
            href="#"
            onClick={getTerraform}
            data-testid="file-upload"
            style={{ opacity: 0.3 }}
            disabled
          >
            <TerraformIconSvg />
            <span>Code - Terraform</span>
          </Dropdown.Item>

          <Dropdown.Item
            disabled
            style={{ opacity: 0.3 }}
            className={styles["plus-dropdowm-item"]}
            href="#"
            onClick={getTerraform}
            data-testid="file-upload"
          >
            <PulumiIconSvg />
            <span>Code - Pulumi</span>
          </Dropdown.Item>

          <Dropdown.Item
            disabled
            style={{ opacity: 0.3 }}
            className={styles["plus-dropdowm-item"]}
            href="#"
            onClick={getTerraform}
            data-testid="file-upload"
          >
            <NotionIconSvg />
            <span>Notion (LiveSync)</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default DownloadButtonDropdown;
