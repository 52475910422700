import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./ModalUI.css";

const ModalUI = (props) => {

  let theme = props.theme ?? '';
  let header = props.header ?? true;
  return (
    <Modal 
      size={props.size} 
      show={props.show} onHide={props.handleClose} 
      dialogClassName={`custom-modal ${theme} ${props.className}`}
      backdrop="static"
      centered
    >
      {header && <Modal.Header closeButton className={props.classes}>
        <Modal.Title>{props.modalTitle}</Modal.Title>
      </Modal.Header>}
      <Modal.Body>{props.children}</Modal.Body>
      {/* <Modal.Footer> */}
        {/* <Button className="modal-btn" onClick={props.handleClose}>
          <span></span>Close
        </Button> */}
      {/* </Modal.Footer> */}
    </Modal>
  );
};

export default ModalUI;
