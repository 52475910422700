import React, { useState, useContext } from "react";
import styles from "./view-edit-architecture.module.css";
import bookFrame from "../../../images/bookframe.svg";
import cloudframe from "../../../images/cloudFrame.svg";
import gitFrame from "../../../images/gitframe.svg";
import WebSocketInstance from "../../../common/WebSocketInstance";
import UploadFileModal from "./components/UploadFileModal/UploadFileModal";
import { LinkSvg, PlusTwoSvg } from "../../../images/svg/icons";
import { Dropdown, InputGroup, Form } from "react-bootstrap";
import context from "../../../store/create-context";
import AddMultipleGitRepoModal from "./components/AddMultipleGitRepoModal/AddMultipleGitRepoModal";
import DataTour from "./components/DataTour/DataTour";
import AddConnectCloudModal from "./components/AddConnectCloudModal/AddConnectCloudModal";

const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const toggle = () => setState(!state);
  return [state, toggle];
};

const PlusButtonDropdown = ({
  setCheckActivity,
  setPendingActivity,
  setShowToastr,
}) => {
  const [isPlusDropdownOpen, togglePlusDropdown] = useToggle();
  const [isUploadModalOpen, toggleUploadModal] = useToggle();
  const [isGitRepoAddModalOpen, toggleGitRepoAddModal] = useToggle();
  const [isGitCloudAddModalOpen, toggleCloudRepoAddModal] = useToggle();
  const [inputGitUrl, setInputGitUrl] = useState("");
  const [inputAccessKey, setInputAccessKey] = useState("");
  const [inputSecretKey, setInputSecretKey] = useState("");
  const ctx = useContext(context);
  let upload_file_disable = true;
  let mode = process.env.REACT_APP_ENVIRONMENT;
  let uploadFileSupport = process.env.REACT_APP_UPLOAD_FILE_SUPPORT === 'true';  // Convert string to boolean
  console.log("Upload file support: ", uploadFileSupport);

  if (uploadFileSupport) {
    console.log("Upload file option is enabled.");
    upload_file_disable = false;
  } else {
    console.log("Upload file option is disabled.");
    upload_file_disable = true;
  }

  const handleInputChange = (e, setInput) => {
    setInput(e.target.value);
  };

  const linkSvgClickHandler = () => {
    if (inputGitUrl != "") {
      gitClickHandler(
        ctx,
        inputGitUrl,
        setCheckActivity,
        setPendingActivity,
        setShowToastr,
        setInputGitUrl,
        togglePlusDropdown
      );
    }
  };

  const linkCloudClickHandler = () => {
    cloudClickHandler(
      ctx,
      inputAccessKey,
      inputSecretKey,
      setCheckActivity,
      setPendingActivity,
    );
  };
const plusBtnClick = () => {
  togglePlusDropdown();
}

  return (
    <>
      <UploadFileModal
        show={isUploadModalOpen}
        handleClose={() => toggleUploadModal()}
        setCheckActivity={setCheckActivity}
      />
     <AddMultipleGitRepoModal
         show = {isGitRepoAddModalOpen}
         handleClose = {() => toggleGitRepoAddModal()}
         setCheckActivity = {setCheckActivity}
         setPendingActivity = {setPendingActivity}
         setShowToastr = {setShowToastr}
         togglePlusDropdown = {togglePlusDropdown}
     />
       <AddConnectCloudModal
         show = {isGitCloudAddModalOpen}
         handleClose = {() => toggleCloudRepoAddModal()}
         setCheckActivity = {setCheckActivity}
         setPendingActivity = {setPendingActivity}
         setShowToastr = {setShowToastr}
         togglePlusDropdown = {togglePlusDropdown}
     />
      <Dropdown
        className={`mx-auto ${styles["navigation-item"]} ${
          styles["patch-dropdown"]
        } ${isPlusDropdownOpen && styles["navigation-item-active"]}`}
        drop="start"
        show={isPlusDropdownOpen}
        onToggle={() => togglePlusDropdown()}
        data-testid="plus-dropdown"
        id = "nav-plus-btn"
        onClick={() => plusBtnClick()}
        autoClose = {false}
      >
        <Dropdown.Toggle>
             <PlusTwoSvg />
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${styles["plus-dropdowm-menu"]}`}>
          {/* Upload Files */}
          <Dropdown.Item
            className={styles["plus-dropdowm-item"]}
            href="#"
            onClick={() => toggleUploadModal(!isUploadModalOpen)}
            disabled={upload_file_disable}
            data-testid="file-upload"
          >
            <img src={bookFrame} alt="File Symbol" />
            <span>Upload files</span>
          </Dropdown.Item>
          <DataTour tourDataId="reactour__sync_code_repo"
                    style={{ transform: "rotate(202deg)", top: "-19px", right: "97%" }}
                    id="sync_code_repo">
          </DataTour>
          <Dropdown
            className={`${styles["sub_menu_dropdown"]} ${styles["plus-dropdowm-item"]}`}
            drop="end"
            onClick={() => toggleGitRepoAddModal(!isGitRepoAddModalOpen)}
          >
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className={styles["sub_menu_dropdown_toggle"]}
            >
              <img src={gitFrame} alt="Git Symbol" />
              <span>Connect Github Repo</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className={`${styles["dropdowm-menu"]}`}>
              <InputGroup>
                <Form.Control
                  className={`${styles["dropdown-input"]}`}
                  placeholder="Enter your repository url to connect..."
                  value={inputGitUrl}
                  onChange={(e) => handleInputChange(e, setInputGitUrl)}
                  onKeyUp={(e) =>
                    e.key === "Enter" &&
                    inputGitUrl != "" &&
                    linkSvgClickHandler()
                  }
                />
                <InputGroup.Text>
                  <div onClick={linkSvgClickHandler}>
                    <LinkSvg />
                  </div>
                </InputGroup.Text>
              </InputGroup>
            </Dropdown.Menu>
          </Dropdown>

          {/* Sync from Cloud */}
          <DataTour tourDataId="reactour__link_cloud"
                    style={{ transform: "rotate(202deg)", top: "-21px", right: "97%" }}
          ></DataTour>
          <Dropdown
            className={`${styles["sub_menu_dropdown"]} ${styles["plus-dropdowm-item"]}`}
            drop="end"
            onClick={() => toggleCloudRepoAddModal(!isGitCloudAddModalOpen)}
          >
              <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className={styles["sub_menu_dropdown_toggle"]}
                >
                  <img src={cloudframe} alt="Cloud Symbol" />
                  <span>Connect Cloud Account</span>
                </Dropdown.Toggle>
            <Dropdown.Menu
              className={`${styles["dropdowm-menu"]} ${styles["dropdowm-menu-pos"]}`}
            >
              <InputGroup>
                <Form.Control
                style={{borderRight: "1px solid #656565"}}
                  className={`${styles["dropdown-input"]}`}
                  placeholder="Enter access key"
                  value={inputAccessKey}
                  onChange={(e) => handleInputChange(e, setInputAccessKey)}
                />
                <Form.Control
                  className={`${styles["dropdown-input"]}`}
                  placeholder="Enter secret key"
                  value={inputSecretKey}
                  onChange={(e) => handleInputChange(e, setInputSecretKey)}
                />
                <InputGroup.Text>
                  <div onClick={linkCloudClickHandler}>
                    <LinkSvg />
                  </div>
                </InputGroup.Text>
              </InputGroup>
            </Dropdown.Menu>
          </Dropdown>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default PlusButtonDropdown;

function gitClickHandler(
  ctx,
  inputGitUrl,
  setCheckActivity,
  setPendingActivity,
  setShowToastr,
  setInputGitUrl,
  togglePlusDropdown
) {
  console.log("Github URL provided.", inputGitUrl);
  console.log("Cache option selected.", ctx.useCache);
  const socket = WebSocketInstance.getInstance();
  socket.send("git_connect", {
    git_url: inputGitUrl,
    project_id: sessionStorage.getItem("jml_workspace_id"),
    use_cache: ctx.useCache,
  });
  setCheckActivity(true);
  setPendingActivity(true);
  setShowToastr({
    show: true,
    type: "info",
    text: "We are working on your repository",
  });
  setInputGitUrl("");
  togglePlusDropdown();
}

function cloudClickHandler(
  ctx,
  inputAccessKey,
  inputSecretKey,
  setCheckActivity,
  setPendingActivity,
  setShowToastr
) {
  console.log("Cloud account linked.", inputAccessKey);
  console.log("Cache option selected.", ctx.useCache);
  const socket = WebSocketInstance.getInstance();
  socket.send("cloud_aws_connect", {
    project_id: sessionStorage.getItem("jml_workspace_id"),
    access_key: inputAccessKey,
    secret_key: inputSecretKey,
    use_cache: ctx.useCache,
  });
  setCheckActivity(true);
  setPendingActivity(true);
  setShowToastr({
    show: true,
    type: "info",
    text: "We are syncing your account",
  });
}
