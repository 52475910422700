const AzureIconSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.51586 0.716045H10.0908L5.34168 14.7872C5.29291 14.9318 5.19999 15.0575 5.076 15.1465C4.95201 15.2355 4.80322 15.2834 4.65058 15.2834H1.09026C0.974635 15.2834 0.860672 15.2558 0.757787 15.2031C0.654902 15.1503 0.566049 15.0738 0.498566 14.9799C0.431102 14.886 0.386979 14.7774 0.369849 14.663C0.352719 14.5487 0.363076 14.4319 0.400062 14.3223L4.82476 1.21216C4.87355 1.06753 4.96648 0.941849 5.09046 0.852809C5.21444 0.763769 5.36322 0.715857 5.51586 0.71582V0.716045Z"
        fill="url(#paint0_linear_2511_28273)"
      />
      <path
        d="M12.1635 10.1543H4.90916C4.8417 10.1541 4.77575 10.1743 4.71992 10.2122C4.6641 10.2501 4.62098 10.3039 4.59621 10.3667C4.57143 10.4294 4.56614 10.4982 4.58103 10.564C4.59591 10.6298 4.63029 10.6896 4.67966 10.7355L9.34129 15.0867C9.47709 15.2133 9.65587 15.2836 9.84154 15.2835H13.9493L12.1635 10.1543Z"
        fill="#0078D4"
      />
      <path
        d="M5.51579 0.715825C5.36149 0.715246 5.21104 0.764015 5.08642 0.855011C4.96181 0.946006 4.86955 1.07446 4.82312 1.22161L0.405504 14.3102C0.366066 14.4201 0.353689 14.538 0.369418 14.6537C0.385147 14.7695 0.428521 14.8797 0.49587 14.9752C0.563219 15.0706 0.652564 15.1484 0.756346 15.202C0.860128 15.2556 0.975296 15.2835 1.09211 15.2832H4.7444C4.88043 15.2589 5.00757 15.1989 5.11283 15.1094C5.21809 15.0199 5.2977 14.904 5.34352 14.7737L6.22444 12.1774L9.37128 15.1124C9.50311 15.2215 9.66852 15.2818 9.83962 15.2832H13.9323L12.1373 10.1538L6.90474 10.155L10.1072 0.715825H5.51579Z"
        fill="url(#paint1_linear_2511_28273)"
      />
      <path
        d="M11.3591 1.21126C11.3104 1.06684 11.2176 0.941337 11.0938 0.852453C10.9699 0.763568 10.8213 0.715779 10.6689 0.71582H5.57031C5.72271 0.715837 5.87126 0.76365 5.99506 0.852528C6.11886 0.941406 6.21166 1.06687 6.2604 1.21126L10.6852 14.322C10.7223 14.4315 10.7327 14.5484 10.7156 14.6628C10.6985 14.7772 10.6544 14.8858 10.5869 14.9798C10.5195 15.0738 10.4306 15.1503 10.3277 15.2031C10.2248 15.2559 10.1108 15.2834 9.99512 15.2834H15.0939C15.2096 15.2833 15.3235 15.2557 15.4264 15.2029C15.5292 15.1501 15.618 15.0735 15.6854 14.9796C15.7528 14.8856 15.7969 14.777 15.8141 14.6627C15.8312 14.5483 15.8209 14.4315 15.7839 14.322L11.3591 1.21126Z"
        fill="url(#paint2_linear_2511_28273)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2511_28273"
          x1="7.18334"
          y1="1.79554"
          x2="2.43221"
          y2="15.8314"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#114A8B" />
          <stop offset="1" stopColor="#0669BC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2511_28273"
          x1="8.66736"
          y1="8.33639"
          x2="7.56841"
          y2="8.70803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.3" />
          <stop offset="0.071" stopOpacity="0.2" />
          <stop offset="0.321" stopOpacity="0.1" />
          <stop offset="0.623" stopOpacity="0.05" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2511_28273"
          x1="8.06294"
          y1="1.38589"
          x2="13.2783"
          y2="15.2804"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3CCBF4" />
          <stop offset="1" stopColor="#2892DF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AzureIconSvg;
