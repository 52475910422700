import "./App.css";
import { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import WebSocketInstance from "./common/WebSocketInstance";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import axios from "axios";
import LoginPage from "./app/components/login/LoginPage";
import ChangePassword from "./app/components/changepassword/ChangePassword";
import NewDashboard from "./app/NewDashboard/NewDashboard";
import AdminRegisterPage from "./app/components/adminregister/AdminRegisterPage";
import RedriveIndexingPage from "./app/components/admintools/RedriveIndexing";

import NewSafeViewEditArchitecture from "./app/components/new-view-edit-architecture/new-safe-view-edit-architecture";
import UpdatedLanding from "./app/NewLanding2/Newlanding2";
import PageNotFound from "./PageNotFound";
import context from "./store/create-context";
import api from "./common/api";

function App() {
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress"
  ];
  const [sResponse, setSResponse] = useState(""); // S=Server this is for graph
  const [qResponse, setQResponse] = useState(""); // Q=Questionnaire this is for questionnaire
  const [promptFixed, setPromptFixed] = useState(false);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [descriptionId, setDescriptionId] = useState(""); // uuid for the description
  const [socket, setSocket] = useState(null);
  const [subcomponent, setSubcomponent] = useState("Canvas");
  const [trackProgress, setTrackProgress] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loadingArch, setLoadingArch] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [passwordChangeResponse, setPasswordChangeResponse] = useState()
  const { setProjects, userId, setUserId, setShowToastr } = useContext(context);

  async function handleLogin(username, password) {
    let url = process.env.REACT_APP_SIMPLIFAI_BACKEND_API_URL+"/login";

    console.log("Sending login request for user", username, "to", url);

    try {
      const res = await axios.post(url, {username, password:password});
      if (res.data.success) {
        console.log("The user is authenticated. First name and last name are", res.data.firstname, res.data.lastname);
        localStorage.setItem("JMLAuthToken", res.data.token);
        localStorage.setItem("JMLFirstname", res.data.firstname);
        localStorage.setItem("JMLLastname", res.data.lastname);
        localStorage.setItem("userId", res.data.user_id);
        setAuthToken(res.data.token);
        setUserId(res.data.user_id);
        const socket = WebSocketInstance.getInstance();
        setSocket(socket);
        return res.data.user_id;
      } else {
        console.log("Authentication failed");
        return;
      }
    } catch (err) {
      if (err.response) {
        setShowToastr({
          show: true,
          type: "error",
          text: err?.response?.data?.message
        });
        console.error("Server responded with an error", err.response);
      } else {
        console.error("Something went wrong", err);
      }
      return null; // Returning null to indicate an error occurred
    }
  }

  function handleLogout() {
    setIsAuthenticated(false);
  }

  async function handleAdminRegister(
    adminUsername,
    adminPassword,
    command,
    uEmail,
    uPassword,
    uFirstName,
    uLastName,
  ) {
    try {
      console.log("Sending request to backend to register user with email " + uEmail + " and command " + command);
      const res = await axios.post(
        `${process.env.REACT_APP_SIMPLIFAI_BACKEND_API_URL}/adminregister`,
        {
          admin_user: adminUsername,
          admin_password: adminPassword,
          command: command,
          u_email: uEmail,
          u_password: uPassword,
          u_firstname: uFirstName,
          u_lastname: uLastName,
        }
      );
      console.log(res.data.message);
      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      setShowToastr({
        show: true,
        type: "error",
        text: err?.response?.data?.error ? err?.response?.data?.error : err?.response?.data?.message
      });
      return false; // returning false in case of an error
    }
  }

  async function handleRedriveIndexing(
    adminUsername,
    adminPassword,
    uEmail,
    projectId,
    activityId
  ) {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SIMPLIFAI_BACKEND_API_URL}/redriveindexing`,
        {
          admin_user: adminUsername,
          admin_password: adminPassword,
          u_email: uEmail,
          project_id: projectId,
          activity_id: activityId,
        }
      );
      if (res.data.success) {
        console.log("Redrove indexing successfully");
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false; // returning false in case of an error
    }
  }

  useEffect(() => {
    if (socket !== null) {
      socket.addEventListener("force_logout", (event)=>{
        setTimeout(logoutHandler,2000)
      })
      socket.addEventListener("architecture", (event) => {
        console.log("Received architecture");
        if ("aws" in event["architecture"]) {
          console.log(event["architecture"]["aws"]);
          setSResponse(event["architecture"]["aws"]);
        } else if ("azure" in event["architecture"]) {
          console.log(event["architecture"]["azure"]);
          setSResponse(event["architecture"]["azure"]);
        }
        setPromptFixed(true);
        setWaitingForResponse(false);
      });
    }
  }, [socket]);

  useEffect(() => {
    if (authToken !== null) {
      console.log("Creating websocket instance");
      const socket = WebSocketInstance.getInstance();
      setSocket(socket);
    } else {
      setSocket(null);
    }
  }, [authToken]);

  useEffect(() => {
    let JMLAuthToken = localStorage.getItem("JMLAuthToken");
    let userId = localStorage.getItem("userId");
    if (JMLAuthToken && JMLAuthToken !== "") {
      setAuthToken(JMLAuthToken);
    }
    if(userId && userId !== ""){
      setUserId(userId);
    }
  }, []);

  useEffect(() => {
    console.log("Mounting App component");
    return () => {
      console.log("Closing App component");
    };
  }, []);

  const changeSubcomponent = (subcomponent) => {
    setSubcomponent(subcomponent);
  };

const logoutHandler = () => {
    localStorage.clear();
    sessionStorage.clear();
    setSResponse("");
    setIsAuthenticated(false);
    setAuthToken(null);
    setUserId(null);
    setProjects([]);
    socket.close();
    setSocket(null);
  };

  let timer;
  const resetTimer = () => {
    if(timer) clearTimeout(timer);
  }
  const handleLogoutTimer = () => {
     timer = setTimeout(() => {
       resetTimer();
       events.forEach((item) => {
        window.removeEventListener(item, resetTimer);
       });
    socket!==null && logoutHandler();
  }, 1800000);
  };
  useEffect(() => {
    events.forEach((item) => {
      window.addEventListener(item, () => {
      resetTimer();
      handleLogoutTimer();
      })
    })
  }, [socket]);

  const onChangePassword = async(passwordObject) => {
    const newObject = {
      old_password: passwordObject.OldPassword.value,
      new_password: passwordObject.ConfirmNewPassword.value
    }
    try {
      const res = await api.post(
        `/change-password`,
         newObject
        );
      setPasswordChangeResponse(res.data)
    } catch (err) {
      if (err.response) {
        setShowToastr({
          show: true,
          type: "error",
          text: err?.response?.data?.message
        });
        console.error("Server responded with an error:", err.response);
      } else {
        console.error("Something went wrong:", err);
      }
      setPasswordChangeResponse({...passwordChangeResponse,message: err.response.data.message});
    }
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<UpdatedLanding />} />
          <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
          <Route
            path="/adminregister"
            element={<AdminRegisterPage onLogin={handleAdminRegister} />}
          />
          <Route path="/change-password" element={
            <RequireAuth>
              <ChangePassword onChangePassword={onChangePassword} passwordChangeResponse={passwordChangeResponse}/>
            </RequireAuth>
            }/>
          <Route
            path="/redriveindexing"
            element={<RedriveIndexingPage onLogin={handleRedriveIndexing} />}
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <NewDashboard
                  socket={socket}
                  logoutHandler={logoutHandler}
                  sandboxMode={false}
                />
              </RequireAuth>
            }
          />
          <Route
            path="/canvas/:pathProjectId?"
            element={
              <RequireAuth>
                <NewSafeViewEditArchitecture
                  sResponse={sResponse}
                  qResponse={qResponse}
                  setSResponse={setSResponse}
                  setQResponse={setQResponse}
                  descriptionId={descriptionId}
                  socket={socket}
                  loadingArch={loadingArch}
                  setLoadingArch={setLoadingArch}
                  logoutHandler={logoutHandler}
                />
              </RequireAuth>
            }
          />
          <Route
            path="/sandbox"
            element={
              <RequireAuth>
                <NewDashboard
                  socket={socket}
                  logoutHandler={logoutHandler}
                  sandboxMode={true}
                />
              </RequireAuth>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
