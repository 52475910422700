import React from "react";

const SummarySvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.64512 15.3284C5.23025 15.3284 4.913 15.0106 4.913 14.595V11.0995H1.39886C0.983997 11.0995 0.666748 10.7817 0.666748 10.3661C0.666748 9.95059 0.983997 9.63281 1.39886 9.63281H5.64512C6.05998 9.63281 6.37723 9.95059 6.37723 10.3661V14.595C6.37723 15.0106 6.03558 15.3284 5.64512 15.3284Z"
        fill="#D2D2D2"
      />
      <path
        d="M5.64512 6.38406H1.39886C0.983997 6.38406 0.666748 6.06628 0.666748 5.65073C0.666748 5.23517 0.983997 4.9174 1.39886 4.9174H4.913V1.3974C4.913 0.98184 5.23025 0.664062 5.64512 0.664062C6.05998 0.664062 6.37723 0.98184 6.37723 1.3974V5.65073C6.37723 6.04184 6.03558 6.38406 5.64512 6.38406Z"
        fill="#D2D2D2"
      />
      <path
        d="M14.6012 6.38406H10.3549C9.94005 6.38406 9.6228 6.06628 9.6228 5.65073V1.3974C9.6228 0.98184 9.94005 0.664062 10.3549 0.664062C10.7698 0.664062 11.087 0.98184 11.087 1.3974V4.9174H14.6012C15.016 4.9174 15.3333 5.23517 15.3333 5.65073C15.3333 6.06628 14.9916 6.38406 14.6012 6.38406Z"
        fill="#D2D2D2"
      />
      <path
        d="M10.3549 15.3284C9.94005 15.3284 9.6228 15.0106 9.6228 14.595V10.3661C9.6228 9.95059 9.94005 9.63281 10.3549 9.63281H14.6012C15.016 9.63281 15.3333 9.95059 15.3333 10.3661C15.3333 10.7817 15.016 11.0995 14.6012 11.0995H11.087V14.595C11.087 15.0106 10.7454 15.3284 10.3549 15.3284Z"
        fill="#D2D2D2"
      />
    </svg>
  );
};

export default SummarySvg;
