import React, { useState, useEffect, useRef, useContext } from "react";
import { PlusSvg } from "../../../images/svg/icons";
import { Tabs, Tab, OverlayTrigger } from "react-bootstrap";
import styles from "./Projects.module.css";
import { Row, Col } from "react-bootstrap";
import Rectangle2151 from "../../../images/Rectangle2151.svg";
import { useNavigate } from "react-router-dom";
import DeleteProjectModal from "./DeleteProjectModal";
import TimeAgo from "../../../common/components/TimeAgo/TimeAgo";
import DialogModal from "../../../common/components/Modal/DialogModal";
import { Popover as ReactBootstrapPopover } from "react-bootstrap";
import CreateContext from "../../../store/create-context";
import { ProjectDescription } from "./ProjectDescription";
import WebSocketInstance from "../../../common/WebSocketInstance";
import Toastify from "../../../common/components/Toastify/Toastify";
const Projects = ({ socket, setShowHandler, sandboxMode }) => {
  const navigate = useNavigate();

  //context
  const { projects, setProjects, setShowToastr, showToastr } = useContext(CreateContext);
  const [isHovered, setIsHovered] = useState(false);
  const [showDescription, setShowDescription] = useState({})

  const showDescriptionHandler = (projectId) => {
    setShowDescription({
      [projectId]: true
    })
  }
  //states
  const [activeTab, setActiveTab] = useState("projects");
  const [activeProject, setActiveProject] = useState(null);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [projectId, setProjectId] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [dialogModalData, setDialogModalData] = useState({
    show: false,
    position: { x: 0, y: 0 },
    options: [
      {
        id: 1,
        text: "Delete",
        action: "delete",
      },
    ],
    dialogClickHandler: dialogClickHandlerFn,
  });

  //ref
  const dialogRef = useRef();

  //Handlers
  const goToProjectDetails = (projectId, projectName, projectDescription) => {
    console.log("goToProjectDetails ", projectId, projectName);
    console.log("Setting jml_workspace_id to ", projectId);
    sessionStorage.setItem("jml_workspace_id", projectId);
    var newProject = false;
    navigate(`/canvas/${projectId}`, {
      state: { projectId, projectName, newProject, sandboxMode, projectDescription },
    });
  };

  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const contextMenuHandler = (project) => {
    let event = window.event;
    event.preventDefault();
    const positionX = window.event.clientX;
    const positionY = window.event.clientY;
    setDialogModalData((props) => {
      return {
        ...props,
        show: true,
        position: { x: positionX, y: positionY },
      };
    });

    setActiveProject(project);
  };

  function dialogClickHandlerFn(action) {
    setDialogModalData((props) => {
      return { ...props, show: false };
    });
    setDeleteModal(true);
  }

  //functions
  const deleteProjectFn = (project) => {
    if (socket) {
      const handleDeleteProject = (message) => {
        console.log("delete_project", message);
        socket.removeEventListener("delete_project", handleDeleteProject);
        if(message?.success === false){
          setShowToastr({
            show: true,
            type: "error",
            text: message?.error ?  message?.error : message?.message
          });
        }
        else{
          let updatedProjects = projects.filter(
            (proj) => proj.id !== project.id
          );
          setProjects(updatedProjects);
        }
      };
      socket.addEventListener("delete_project", handleDeleteProject);
      socket.send("delete_project", {
        project_id: project.id,
      });
      console.log("delete_project message sent for project id", project.id);
    }
    setActiveProject(null);
    setDeleteModal(false);
  };

  const getDayName = (dateStr = "", locale = "") => {
    let date = dateStr !== "" ? new Date(dateStr) : new Date();
    locale = locale !== "" ? locale : window.navigator.language;
    return date.toLocaleDateString(locale, { weekday: "long" });
  };

  //Effects
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        setDialogModalData((props) => {
          return { ...props, show: false };
        });
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //Popover
  const popover = (text) => (
    <ReactBootstrapPopover id="popover-basic">
      <ReactBootstrapPopover.Body>
        <p className="px-3 py-1 text-dark ">{text}</p>
      </ReactBootstrapPopover.Body>
    </ReactBootstrapPopover>
  );

  const handleOnBlur = (e, project) => {
    if (e.target.value.trim().length > 0) {
      handleEditProjectName(project.id, e.target.value.trim());
    } else {
      setShowToastr({
        show: true,
        type: "error",
        text: `Failed to update project name`,
      });
    }
    setIsOpen(false);
  };

  const handleEditProjectName = (projectId, newProjectName) => {
    const socket = WebSocketInstance.getInstance();
    if (socket) {
      socket.send("edit_project_name", {
        project_id: projectId,
        new_project_name: newProjectName,
      });
    }
    setProjects((prev) => {
          return prev.map((el) => {
            if(el.id === projectId){
              return {...el, name: newProjectName};
            }else{
              return el;
            }
          })
    })
  };

  useEffect(() => {
    if(socket !== null) {
      socket.addEventListener("edit_project_name", (response) => {
        if(response.success) {
          setShowToastr({
            show: true,
            type: "success",
            text: `Project name updated to: ${response.new_project_name}`,
          });
        } else {
          setShowToastr({
            show: true,
            type: "error",
            text: `${response.error}`,
          });
        }
      });
    }
    return () =>{
      if(socket!== null){
        socket.removeEventListener("edit_project_name", (response) => {
          if (response.success) {
            setShowToastr({
              show: true,
              type: "success",
              text: `Project name updated to: ${response.new_project_name}`,
            });
          } else {
            setShowToastr({
              show: true,
              type: "error",
              text: `${response.error}`,
            });
          }
        });
      }}
  }, [socket]);


  const handleClick = (e, projectId) => {
    setProjectId(projectId);
    setIsOpen(true);
  };
  return (
    <>
      {projects.length === 0 && (
        <>
          <div className="main-heading text-center">
            <h1 className="heading1 opacity-half">Happy {getDayName()}!</h1>
            <h6 className="heading6 font-700">Your Projects list is empty</h6>
            <p className="heading6 opacity-half">
              To get started, you can add a new project <br /> from scratch
            </p>
          </div>
          <a
            href="#"
            className="blue-btn"
            onClick={setShowHandler}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <span>
              <PlusSvg color = {isHovered? '#5A46FF' : 'white'}/>
            </span>
            <span style = {{color: isHovered ? "#5A46FF" : "white"}}>Add project</span>
          </a>
        </>
      )}
      {projects.length > 0 && (
        <Tabs
          defaultActiveKey={activeTab}
          onSelect={handleTabSelect}
          className={styles["project-snapshot-tab"]}
        >
          <Tab
            eventKey="projects"
            title="Projects"
            tabClassName={`${styles.tab} ${
              activeTab === "projects"
                ? styles["active-tab"]
                : styles["inactive-tab"]
            }`}
          >
            <Row className={`${styles["group-wrapper"]} frame-class`}>
              {projects
                .slice(0)
                .reverse()
                .map((project) => {
                  return (
                    <Col sm={12} md={6} lg={4} key={project.id}>
                         {/* <OverlayTrigger
                              trigger={["hover", "focus"]}
                              placement="bottom"
                              overlay={popover(
                                project.description && project.description != ""
                                  ? project.description
                                  : project.name
                              )}
                          > */}
                        <div
                          className={styles["group-card"]}
                          onContextMenu={() => {
                            contextMenuHandler(project);
                          }}
                          onMouseEnter = {() => showDescriptionHandler(project.id)}
                          onMouseLeave = {() => setShowDescription({})}
                        >
                           <img src={project.hasOwnProperty("image_url")? project["image_url"] :Rectangle2151} alt="a rectangle"
                           onClick={() =>
                            goToProjectDetails(project.id, project.name, project.description)
                          }
                          />
                           { projectId === project.id && isOpen ? (
                                    <span style={{fontWeight: "bold" }}>
                                    <input
                                      className={styles["project-name"]}
                                      defaultValue={project.name}
                                      onBlur={(e) => handleOnBlur(e, project)}
                                      autoFocus
                                    /></span>
                                  ) : (
                                    <span onClick={(e) =>handleClick(e, project.id)} style={{fontWeight: "bold", cursor:'pointer'}}>
                                      {project.name}
                                    </span>
                                  )}
                          <div className={styles["time-ago-wrapper"]}>
                            <TimeAgo time={project.created_at} />
                          </div>
                          {/* <div style={style}>{project.description}</div> */}
                          {showDescription[project.id] && <ProjectDescription  description ={project.description}  name ={project.name}/>}
                        </div>
                        {/* </OverlayTrigger> */}
                    </Col>
                  );
                })}
            </Row>
          </Tab>
        </Tabs>
      )}
      <DeleteProjectModal
        modalTitle="Delete Project"
        show={showDeleteModal}
        handleClose={() => setDeleteModal(false)}
        project={activeProject}
        deleteProjectFn={() => {
          deleteProjectFn(activeProject);
        }}
      />
      <Toastify
        show={showToastr["show"]}
        type={showToastr["type"]}
        message={showToastr["text"]}
        handleClose={() => {
          setShowToastr((props) => {
            return { ...props, show: false };
          });
        }}
      />
      {dialogModalData.show && (
        <div ref={dialogRef}>
          <DialogModal {...dialogModalData} />
        </div>
      )}
    </>
  );
};

export default Projects;

