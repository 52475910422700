import React from "react";
import { Spinner } from "react-bootstrap";
import styles from './Toastify.module.css';

const Toastify = (props) => {

  const {
        show = false,
        type = 'error',
        message = "",
        handleClose = () => {}
   } = props;  

  return (
    <>
       <div className={`${styles['Toastify__toast-container']} ${styles['Toastify__toast-container--top-right']} ${show && styles['Toastify_show']}`}>
        <div className={`${styles['Toastify__toast']} ${styles[type]}`}>
            <div role="alert" className={`${styles['Toastify__toast-body']}`}>
                <div className={styles['Toastify__toast-icon']}>
                    {
                        type == 'info'
                        &&
                        <Spinner animation="border" />
                    }
                    {
                        type == 'success'
                        &&
                       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.99935 1.66602C5.39935 1.66602 1.66602 5.39935 1.66602 9.99935C1.66602 14.5993 5.39935 18.3327 9.99935 18.3327C14.5993 18.3327 18.3327 14.5993 18.3327 9.99935C18.3327 5.39935 14.5993 1.66602 9.99935 1.66602ZM8.33268 14.166L4.16602 9.99935L5.34102 8.82435L8.33268 11.8077L14.6577 5.48268L15.8327 6.66602L8.33268 14.166Z" fill="#3DBA4E"/>
                        </svg>
                    }
                    {
                        type == 'error'
                        &&
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.99935 1.66602C5.39935 1.66602 1.66602 5.39935 1.66602 9.99935C1.66602 14.5993 5.39935 18.3327 9.99935 18.3327C14.5993 18.3327 18.3327 14.5993 18.3327 9.99935C18.3327 5.39935 14.5993 1.66602 9.99935 1.66602ZM10.8327 14.166H9.16602V12.4993H10.8327V14.166ZM10.8327 10.8327H9.16602V5.83268H10.8327V10.8327Z" fill="#F2323F"/>
                        </svg>
                    }
                </div>
                <div>{message}</div>
            </div>
            <button className={styles['Toastify__close-button']} onClick={handleClose}>
                <svg aria-hidden="true" viewBox="0 0 14 16"><path fillRule="evenodd" d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"></path></svg>
            </button>
        </div>
       </div>
    </>
  );
};

export default Toastify;
