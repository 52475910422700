import React from "react";
const distanceToNowFormat = (currentTime,previousTime) => {
  const timeDifferenceMS = currentTime-previousTime;
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;
  if (timeDifferenceMS < msPerMinute) {
     return Math.round(timeDifferenceMS/1000)+' seconds ago' ;
  }
  else if (timeDifferenceMS < msPerHour) {
    return Math.round(timeDifferenceMS/msPerMinute) + ' minutes ago';
  }
  else if (timeDifferenceMS < msPerDay ) {
    return Math.round(timeDifferenceMS/msPerHour ) + ' hours ago';
  }
  else if (timeDifferenceMS < msPerMonth) {
    return Math.round(timeDifferenceMS/msPerDay) + ' days ago';
  }
  else if (timeDifferenceMS < msPerYear) {
    return Math.round(timeDifferenceMS/msPerMonth) + ' months ago';
  }
  else {
    return Math.round(timeDifferenceMS/msPerYear ) + ' years ago';
  }
}
const TimeAgo = ({time}) => {
  var previousTime = Date.parse(time);
  var currentTime = Date.now();
  var showTime = distanceToNowFormat(currentTime,previousTime);
  return <>
      <span>
      {showTime}
      </span>
    </>;
};

export default TimeAgo;