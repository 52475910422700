import React from "react";

const HomeTwoSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3271_4312)">
        <path
          d="M13.6954 15.3321H2.30428C1.39984 15.3321 0.666504 14.5988 0.666504 13.6944V7.3388C0.666504 6.92325 0.984282 6.60547 1.39984 6.60547C1.81539 6.60547 2.13317 6.92325 2.13317 7.3388V13.6944C2.13317 13.7921 2.2065 13.8655 2.30428 13.8655H13.6954C13.7932 13.8655 13.8665 13.7921 13.8665 13.6944V7.3388C13.8665 6.92325 14.1843 6.60547 14.5998 6.60547C15.0154 6.60547 15.3332 6.92325 15.3332 7.3388V13.6944C15.3332 14.5988 14.5998 15.3321 13.6954 15.3321Z"
          fill="#F8F8FF"
        />
        <path
          d="M14.5999 8.07218C14.4288 8.07218 14.2576 7.99884 14.111 7.87662L7.99987 2.37662L1.88876 7.87662C1.59542 8.14551 1.13098 8.12107 0.862089 7.82773C0.593201 7.50996 0.617645 7.04551 0.910978 6.77662L7.51098 0.861068C7.77987 0.616623 8.21987 0.616623 8.48876 0.861068L15.0888 6.77662C15.3821 7.04551 15.4065 7.50996 15.1376 7.80329C14.991 7.9744 14.7954 8.07218 14.5999 8.07218Z"
          fill="#F8F8FF"
        />
        <path
          d="M9.80863 15.3314H6.19085C5.7753 15.3314 5.45752 15.0136 5.45752 14.598V8.73138C5.45752 8.31582 5.7753 7.99805 6.19085 7.99805H9.78418C10.1997 7.99805 10.5175 8.31582 10.5175 8.73138V14.598C10.542 15.0136 10.1997 15.3314 9.80863 15.3314ZM6.92419 13.8647H9.05085V9.46471H6.92419V13.8647Z"
          fill="#F8F8FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_3271_4312">
          <rect
            width="14.6667"
            height="14.6667"
            fill="white"
            transform="translate(0.666504 0.666016)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HomeTwoSvg;
