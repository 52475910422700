import CodeSvg from "../../../images/code-04.svg";
import AlertCircle from "../../../images/Icon.svg";
import Popover from "react-bootstrap/Popover";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useState } from "react";
import { useRef } from "react";
import "./NewNode.css";

const NewNode = ({ left, top, newNodeDetail, setNewNodeDetail, addNewNode }) => {
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [target1, setTarget1] = useState(null);
    const [target2, setTarget2] = useState(null);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
  
    const handleGitClick = (event) => {
        if(show2){
            setShow2(false);
        }
      setShow1(!show1);
      setTarget1(event.target);
    };

    const handleDesClick = (event) => {
        if(show1){
            setShow1(false);
        }
        setShow2(!show2);
        setTarget2(event.target);
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setNewNodeDetail(prevState => ({
          ...prevState,
          [name]: value
      }));
    };
    const handleCreateNode = (e) => {
      e.stopPropagation();
      if(e.key === "Enter"){
       addNewNode(e);
      }
    }

  const addNodeDes = (
    <Popover id="popover-positioned-bottom" title="Popover bottom">
         <div className="popOverDiv"><input className="popOverInput" type = "text" placeholder="Enter Description for the node"></input></div>
    </Popover>
  );
  const addGitDes = (
    <Popover id="popover-positioned-bottom" title="Popover bottom">
        <div className="popOverDiv"><input className="popOverInput" type = "text" placeholder="git.a.dadcom"></input></div>
    </Popover>
  );
  
  return (
    <>
      <div style={{ top: top, left: left }} className="newNodeStyle">
        <input
          className="inputStyle"
          type="text"
          name="name"
          value={newNodeDetail.name}
          onChange={handleChange}
          placeholder="Enter name"
          onKeyDown={handleCreateNode}
          autoFocus
        ></input>
     <div ref={ref1}>
     <img onClick={handleGitClick} className="newNodeIcon1" src={CodeSvg} alt="git-connect" />
      <Overlay
        show={show1}
        target={target1}
        placement="bottom-start"
        container={ref1}
      >
        <Popover id="popover-positioned-bottom" title="Popover bottom">
          <div className="popOverDiv"><input className="popOverInput" type = "text" placeholder="git.a.dadcom"></input></div>
        </Popover>
      </Overlay>
    </div>
    <div ref={ref2}>
    <OverlayTrigger
      placement="left"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip>
        <div className="toolbar">Add description and type of node</div>
      </Tooltip>}
    >
      <img onClick={handleDesClick} className="newNodeIcon2" src={AlertCircle} alt="desc-type" />
    </OverlayTrigger>
      <Overlay
        show={show2}
        target={target2}
        placement="bottom-start"
        container={ref2}

      >
         <Popover id="popover-positioned-bottom" title="Popover bottom" placement="">
         <div className="node-detail">
            <div className="des-type-div">
              <input
              type = "text"
              name="description"
              placeholder="Enter Description"
              value={newNodeDetail.description}
              onChange={handleChange}
              onKeyDown={handleCreateNode}
              className="description-div"/>
            </div>
            <div className="des-type-div">
              <input
               type="text"
               name="type"
               placeholder="Enter type"
               value={newNodeDetail.type}
               onChange={handleChange}
               onKeyDown={handleCreateNode}
               className="description-div"
               />
            </div>
            <div onClick={(e) => addNewNode(e)} className="addNode-btn">Add Node</div>
         </div>
    </Popover>
      </Overlay>
    </div>
      </div>
    </>
  );
};

export default NewNode;


