import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";
import styles from "../new-view-edit-architecture/view-edit-architecture.module.css";
import { EyePasswordSvg, EyeClosePasswordSvg, ArrowBack } from "../../../images/svg/icons";
import Toastify from "../../../common/components/Toastify/Toastify";
import context from "../../../store/create-context";

const ChangePassword = ({ onChangePassword, passwordChangeResponse }) => {
    // Initialize password fields with value and visibility status
    const [passwordFields, setPasswordFields] = useState({
        OldPassword: { value: "", show: false },
        EnterNewPassword: { value: "", show: false },
        ConfirmNewPassword: { value: "", show: false },
    });

  const [errorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();
  const { showToastr, setShowToastr} = useContext(context)

const formStyle = {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "normal",
    gap: "16px",
  };

const passwordEyeIconStyle = { position: "relative" };

const eyeIconStyle = {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
  };
  const errorMessageStyle = {
    textAlign: "center",
    width: "300px",
    color: "var(--colors-red-500, #F2323F)",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "14px",
    marginTop: "-0.6rem",
    marginBottom: "0.5rem",
  };

  const confirmButtonStyle = {
    display: "flex",
    width: "144px",
    height: "56px",
    padding: "16px 40px",
    justifyContent: "center",
    alignItems: "center",
    gap: "1px",
    borderRadius: "8px",
    background: "#5A46FF",
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Work Sans",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    border: "none",
  };
  const cancelButtonStyle = {
    ...confirmButtonStyle,
    background: "transparent",
    color: "#404040",
    border: "1px solid #404040",
    cursor: "pointer",
  };

  // Function to toggle password visibility
  const togglePasswordVisibility = (fieldName) => {
    setPasswordFields({
      ...passwordFields,
      [fieldName]: {
        ...passwordFields[fieldName],
        show: !passwordFields[fieldName].show,
      },
    });
  };

  // Function to handle password input change
  const handlePasswordChange = (fieldName, value) => {
    setPasswordFields({
      ...passwordFields,
      [fieldName]: {
        ...passwordFields[fieldName],
        value: value,
      },
    });
  };
  const handleCancel = () => {
    navigate(-1);
  };
  const isValidPassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
    return regex.test(password);
  };
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission
    const { OldPassword, EnterNewPassword, ConfirmNewPassword } =
      passwordFields;

    // Check for empty fields
    if (!OldPassword.value || !EnterNewPassword.value || !ConfirmNewPassword.value) {
      setErrorMessage("All fields are required.");
      return;
    }

    // Validate the new password
    if (!isValidPassword(EnterNewPassword.value)) {
      setErrorMessage("Password must be at least 10 characters long, include at least one lowercase letter, one uppercase letter, one special character, and one number.");
      return;
    }

    // Check if new password and confirmation match
    if (EnterNewPassword.value !== ConfirmNewPassword.value) {
        setErrorMessage("New password and confirmation do not match.");
        return;
    }

    // If everything is valid, clear the error message and proceed with password change
    setErrorMessage("");
    // Call your onChangePassword function or other logic to change the password
    onChangePassword(passwordFields)
  };
  return (
    <Container fluid className={styles["container-fluid"]}>
      <Navbar expand="lg" className={styles["cust-navbar"]} style={{ justifyContent: "flex-start" }}>
        <div className="" onClick={handleCancel} style={{ marginLeft: "75px", cursor: "pointer", marginRight: "20px", display: "flex", alignItems: "center" }}>
          <ArrowBack />
          <span style={{ marginLeft: "19px", marginRight: "20px" }}>
            back
          </span>
        </div>
        <div style={{ width: "100%", marginBottom: "30px", marginTop: "30px", paddingRight: "120px", height: "24px", fontWeight: "500", fontSize: "24px" }}>
          Change password
        </div>
      </Navbar>
      <div className="text-white d-flex justify-content-center align-items-center h-100">
        <form onSubmit={handleSubmit} style={{ ...formStyle, marginTop: "-200px" }}>
          {Object.entries(passwordFields).map(([fieldName, { value, show }]) => {
              const field = fieldName.split("Password")[0].split("New");
              const placeHolder = field.length > 1 ? field[0] + " new" : field;
              return (
                <div key={fieldName} style={passwordEyeIconStyle}>
                  <input
                    type={show ? "text" : "password"}
                    value={value}
                    onChange={(e) => handlePasswordChange(fieldName, e.target.value) }
                    placeholder={`${placeHolder} password`}
                    className={styles["inputStyle"]}
                    style={{ borderColor: show && "#CECECE" }}
                  />
                  <span style={eyeIconStyle} onClick={() => togglePasswordVisibility(fieldName)}>
                    {show ? <EyePasswordSvg /> : <EyeClosePasswordSvg />}
                  </span>
                </div>
              )
            })}
          {errorMessage ? <p style={errorMessageStyle}>{errorMessage}</p> : null}
          <div className=" d-flex flex-row justify-content-between">
            <div onClick={handleCancel} style={cancelButtonStyle}>Cancel</div>
            <button name="confirm" type="submit" style={confirmButtonStyle}>Confirm</button>
          </div>
          <p style={passwordChangeResponse?.success ? {} : errorMessageStyle}>{passwordChangeResponse?.message}</p>
        </form>
      </div>
      <Toastify
        show={showToastr["show"]}
        type={showToastr["type"]}
        message={showToastr["text"]}
        handleClose={() => {
          setShowToastr((props) => {
            return { ...props, show: false };
          });
        }}
      />
    </Container>
  );
};

export default ChangePassword;
