const GreenThreeSvg = () => {
  return (
    <>
      <svg
        width="160"
        height="112"
        viewBox="0 0 160 112"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.3152 2.07093C83.5321 -0.690311 75.7773 -0.69031 70.9943 2.07093L3.64114 40.9539C1.02955 42.4616 -0.156078 44.4807 0.0842445 46.4528C0.0289212 46.7506 0 47.0577 0 47.3716V64.3716C0 64.7208 0.0357869 65.0616 0.103895 65.3905C-0.211903 67.4071 0.967176 69.4869 3.64113 71.0306L70.9943 109.914C75.7773 112.675 83.5321 112.675 88.3151 109.914L155.668 71.0306C157.503 69.9713 158.634 68.6594 159.061 67.2887C159.652 66.4677 160 65.4603 160 64.3716V47.3716C160 46.1791 159.583 45.0841 158.886 44.2247C158.364 43.0262 157.292 41.8912 155.668 40.9539L88.3152 2.07093Z"
          fill="white"
        />
        <g filter="url(#filter0_i_68_824)">
          <rect
            width="85.1695"
            height="85.1695"
            rx="4"
            transform="matrix(0.866044 0.499967 -0.866044 0.499967 79.6547 3.37109)"
            fill="#46FFBC"
          />
          <rect
            width="85.1695"
            height="85.1695"
            rx="4"
            transform="matrix(0.866044 0.499967 -0.866044 0.499967 79.6547 3.37109)"
            fill="url(#paint0_radial_68_824)"
          />
        </g>
        <g clipPath="url(#clip0_68_824)">
          <g clipPath="url(#clip1_68_824)">
            <path
              d="M98.6999 43.2504L60.5874 42.8891C59.7379 42.8803 59.2734 43.5129 59.5364 44.3208C59.7993 45.1287 60.68 45.7753 61.4832 45.75L63.961 45.7757L63.7452 46.847C63.6373 47.3827 63.9517 48.0234 64.5079 48.4317C64.6933 48.5678 80.4111 60.8752 93.4917 55.1062C93.753 55.0418 93.9215 54.9093 94.0437 54.7429C94.0926 54.6763 94.1415 54.6097 94.144 54.5091C94.171 54.3752 94.198 54.2412 94.2005 54.1406C94.5032 51.4934 94.1932 48.8063 93.2949 46.0461L99.5957 46.1113C100.02 46.1157 100.355 45.9515 100.526 45.7184C100.697 45.4853 100.754 45.1169 100.622 44.7129C100.406 43.9391 99.525 43.2925 98.6999 43.2504Z"
              fill="rgba(0, 107, 73, 1)"
            />
            <path
              d="M79.1736 28.4548C78.5929 28.0797 77.8875 27.9718 77.4332 28.2019L66.4032 34.0928C66.2127 34.1579 66.0905 34.3244 66.061 34.559L65.0477 40.1175C64.9128 40.7871 65.6957 41.5668 66.4745 41.5749L90.1202 41.8198C90.8282 41.8272 91.1266 41.2264 90.7414 40.585C86.2976 33.326 79.4517 28.659 79.1736 28.4548Z"
              fill="rgba(0, 107, 73, 1)"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_i_68_824"
            x="7.9234"
            y="3.54297"
            width="145.463"
            height="83.8203"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="-1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_68_824"
            />
          </filter>
          <radialGradient
            id="paint0_radial_68_824"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(111.187 52.0849) rotate(-177.978) scale(195.1 97.7867)"
          >
            <stop stopColor="#91BA9F" stopOpacity="0" />
            <stop offset="0.425666" stopColor="#356B6B" stopOpacity="0.4" />
            <stop offset="1" stopColor="#1B1A53" />
          </radialGradient>
          <clipPath id="clip0_68_824">
            <rect
              width="27.0354"
              height="37.7007"
              fill="white"
              transform="translate(56.9199 44.2598) rotate(-53.7167)"
            />
          </clipPath>
          <clipPath id="clip1_68_824">
            <rect
              width="24.7824"
              height="34.559"
              fill="white"
              transform="translate(58.8528 44.2812) rotate(-53.7167)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default GreenThreeSvg;
