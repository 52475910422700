import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./Testimonials.module.css";

const Testimonials = () => {
  var options = {
    item: 3,
    className: "owl-theme",
    margin: 10,
    loop: true,
    autoplaySpeed: 4000,
    responsiveClass: true,
    autoplay:true,
    dots: false,
    nav: false,
    stagePadding: 20,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
        margin: 30,
        stagePadding: 100,
      },
      960: {
        items: 1,
        margin: 30,
        stagePadding: 150,
      },
      1280: {
        items: 1,
        margin: 50,
        stagePadding: 300,
      },
    },
  };

  let reviews = [
    {
      heading: "Unprecedented Visibility in Complex Environments!",
      review:
        "We have thousands of services and hundreds of AWS accounts and databases. Before, trying to determine which service is using which database and why was nearly impossible. With JigsawML, we have visibility we need to identify the relationships…",
      position: "VP Engineering,",
      company: "20-year-old public company",
    },
    {
      heading: "Revolutionizing Onboarding Across Roles",
      review:
        "JigsawML can transform the onboarding experience not only for engineers but also for people like us (Directors).",
      position: "Director of Engineering,",
      company: "Tech startup ($4B valuation)",
    },
    {
      heading: "Tailored Guidance for Unique Needs",
      review:
        "Far more than anything else I’ve seen, JigsawML takes into account my context and provides guidance that’s both appropriate and actionable.",
      position: "CTO,",
      company: "Leading Consulting Firm",
    },
    {
      heading: "Bridging the Gap Between Infrastructure and Code",
      review:
        "Our Cloud infrastructure and the code always deviate. We needed a way to stay on top of it, and JigsawML gives us the requisite visibility.",
      position: "Distinguished Engineer,",
      company: "Leading Global Bank",
    },
  ];

  return (
    <section className={styles["testimonial"]}>
      <OwlCarousel {...options}>
        {reviews.map((review, index) => {
          return (     
            <div key={index} className={`${styles["testimonial-wrap"]}`}>
              <div className={styles["testimonial-text"]}> 
                <h1 className={styles["testimonial-heading"]}>
                    {review["heading"]}
                </h1>
                <p className={styles["testimonial-detail"]}>
                  {review["review"]}
                </p>
                <div className={styles["info-details"]}>
                  <div className={styles["info-content"]}>
                    <h4>{review["position"]}</h4>
                    <p>{review["company"]}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </OwlCarousel>
    </section>
  );
};

export default Testimonials;
