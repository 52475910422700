import { useRef, useState, useEffect } from "react";
import { Overlay } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import CloseIcon from "../../../../../images/icons/close";
import WebSocketInstance from "../../../../../common/WebSocketInstance";
import "./ResourceIconPopUp.css";

const ResourceIconPopUp = ({
  target,
  showPopUp,
  setShowPopUp,
  setSelectedResource,
  selectedResource,
  placement = "right",
  maxWidth = "35%",
  flip = true,
  additionalInfo,
  setAdditionalInfo,
  selectedDropDownValues,
  socket = WebSocketInstance.getInstance(),
}) => {
  const ref = useRef();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    console.log("Component mounted or socket changed");

    if (socket) {
      socket.addEventListener("cloud_aws_get_additional_info", handleAdditionalInfoResponse);
      console.log("cloud_aws_get_additional_info event listener added");
    }

    return () => {
      if (socket) {
        socket.removeEventListener("cloud_aws_get_additional_info", handleAdditionalInfoResponse);
        console.log("cloud_aws_get_additional_info event listener removed");
      }
    };
  }, [socket]);

  const closePopUp = () => {
    setShowPopUp(false);
    setSelectedResource("");
  };
  const handleAdditionalInfoResponse = (response) => {
    console.log("Received response: ", response);

    if (response?.success) {
      if (response.additional_info && Object.keys(response.additional_info).length > 0) {
        setAdditionalInfo(response.additional_info);
        console.log("Additional info: ", response.additional_info);
      } else {
        setAdditionalInfo("Couldn't get more info for this resource. Please try some other resource or reach out to info@jigsawml.com");
      }
    } else {
      setErrorMessage(response.error || "An error occurred while fetching additional info.");
      console.error("Error: ", response.error);
    }
  };

  const getAdditionalInfo = (e) => {
    e.preventDefault();
    console.log("Attempting to send request for additional info");

    if (socket && typeof socket.send === 'function') {
      try {
        socket.send("cloud_aws_get_additional_info", {
          project_id: sessionStorage.getItem("jml_workspace_id"),
          node_type: selectedResource.type,
          resource_name: selectedResource.name,
          resource_id: selectedResource.data.resource_id,
          account_id: selectedDropDownValues["aws:account"]?.id,
          account_region: selectedDropDownValues["aws:region"]?.id
        });
      } catch (error) {
        setErrorMessage("Failed to send request: " + error.message);
        console.error("Failed to send request: ", error);
      }
    } else {
      setErrorMessage("Socket connection is not available. Please try again later.");
      console.error("Socket connection is not available");
    }
  };

  return (
    showPopUp && (
      <div className="res-pop-up" ref={ref}>
        <Overlay
          show={showPopUp}
          target={target}
          placement={placement}
          container={ref}
          flip={flip}
          onHide={closePopUp}
          rootClose
        >
          <Popover
            id="res-pop-up"
            style={{
              minHeight: "fit-content",
              maxHeight: "500px",
              minWidth: "250px",
              maxWidth: `${maxWidth}`,
              borderRadius: "4px !important",
              backgroundColor: "rgb(18,20,31)",
              border: "1px solid #696969",
              padding: "0.5rem 0.75rem",
              background: "rgb(18,20,31)",
              marginBottom: "-32px",
              zIndex:'9999',
              wordWrap: "break-word",
              overflowWrap: "break-word",
              wordBreak: "break-word",
              overflow: "auto",
            }}
          >
            <Popover.Body>
              <div className="resource-icon-popUp">
                <div className="res-i-pop-up">
                  <span className="res-detail"><strong>Resource Name:</strong> {selectedResource.name}</span>
                  <span className="res-detail"><strong>Resource ID:</strong> {selectedResource.data.resource_id}</span>
                  <span className="res-detail"><strong>Resource Type:</strong> {selectedResource.type}</span>
                  <span className="res-detail"><strong>Service Info:</strong> {selectedResource.description}</span>
                  <span className="res-detail"><strong>Service URL:</strong> {""}<a href={selectedResource.url} className="res-url" target="_blank">AWS Info</a></span>
                  {additionalInfo === null ? (
                    <a href="#" className="res-link" onClick={getAdditionalInfo}>
                      Click here to get more details about this resource
                    </a>
                  ) : (
                    <div className="res-detail"><strong>Resource Info:</strong> <pre style={{ display: 'inline' }}>{JSON.stringify(additionalInfo, null, 2)}</pre></div>
                  )}
                  {errorMessage && <div className="error-message">{errorMessage}</div>}
                </div>
                <span onClick={closePopUp}>
                  <CloseIcon />
                </span>
              </div>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    )
  );
};

export default ResourceIconPopUp;
