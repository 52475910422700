import { Navigate, useLocation } from "react-router-dom";
import WebSocketInstance from "./common/WebSocketInstance";

function RequireAuth({ children }) {
  const isAuthenticated = localStorage.getItem('JMLAuthToken');
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace state={{ path: location.pathname }} />;
  }

  const wsInstance = WebSocketInstance.getInstance();

  if (!wsInstance.socketRef) {
    return <Navigate to="/login" replace state={{ path: location.pathname }} />;
  }

  console.log("Authenticated");
  console.log("Children:", children);

  return children;

}

export default RequireAuth;
