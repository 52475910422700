import { OverlayTrigger, Tooltip } from "react-bootstrap";

const OverLayTooltip = (props) => {
  const { placement = "bottom", info = "" } = props;

  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 200 }}
      overlay={
      <Tooltip id="button-tooltip">{info}</Tooltip>
    }
    >
      {props.children}
    </OverlayTrigger>
  );
};

export default OverLayTooltip;
