import React, { useState, useEffect } from "react";
import { Form, OverlayTrigger } from "react-bootstrap";
import { StartSvg } from "../../../../images/svg/icons";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import bookFrame from "../../../../images/bookframe.svg";
import gitFrame from "../../../../images/gitframe.svg";
import cloudframe from "../../../../images/cloudFrame.svg";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import CommandsMgr from "../../new-view-edit-architecture/canvas_commands";
import { CloudProvider } from "../../new-view-edit-architecture/canvas_commands";
import styles from "./DefaultCanvasModal.module.css";
import { PointSvg } from "../../../../images/svg";
import { NavLink } from "react-router-dom";
import GuideTour from "../../../../common/components/GuideTour/GuideTour";
import steps from "../../../../common/components/GuideTour/steps";
import DataTour from "../../new-view-edit-architecture/components/DataTour/DataTour";
import context from "../../../../store/create-context";
import { useContext } from "react";
import Tooltip from 'react-bootstrap/Tooltip';
import UploadFileModal from "../../new-view-edit-architecture/components/UploadFileModal/UploadFileModal";
import AddMultipleGitRepoModal from "../../new-view-edit-architecture/components/AddMultipleGitRepoModal/AddMultipleGitRepoModal";
import AddConnectCloudModal from "../../new-view-edit-architecture/components/AddConnectCloudModal/AddConnectCloudModal";

const DefaultCanvasModal = (props) => {
  const ctx = useContext(context);

  const useToggle = (initialState = false) => {
    const [state, setState] = useState(initialState);
    const toggle = () => setState(!state);
    return [state, toggle];
  };

  const [isUploadModalOpen, toggleUploadModal] = useToggle();
  const [isGitRepoAddModalOpen, toggleGitRepoAddModal] = useToggle();
  const [isGitCloudAddModalOpen, toggleCloudRepoAddModal] = useToggle();
  const guideTourHandler = () => {
    setShowGuideTour(true);
    ctx.setIsTourOpen(true);
  }
  const {
    sResponse,
    setSResponse,
    descriptionId,
    socket,
    setPendingActivity,
    setCheckActivity
  } = props;
  //variables
  let options = [];
  //states
  const [singleSelections, setSingleSelections] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [showGuideTour, setShowGuideTour] = useState(false); 
  //ref
  const ref = React.createRef();

  let upload_file_disable = true;
  let uploadFileSupport = process.env.REACT_APP_UPLOAD_FILE_SUPPORT === 'true';  // Convert string to boolean
  console.log("Upload file support: ", uploadFileSupport);

  if (uploadFileSupport) {
    console.log("Upload file option is enabled.");
    upload_file_disable = false;
  } else {
    console.log("Upload file option is disabled.");
    upload_file_disable = true;
  }

  let commandsMgr = new CommandsMgr(
    CloudProvider.AWS,
    descriptionId,
    setSResponse,
    sResponse,
    socket
  );

  let sentences = Object.keys(commandsMgr.generateCmdsToAutoSuggest());

  //   options = sentences.length > 0 ? sentences.map((item) => ({ name: item })) : [];

  useEffect(() => {
    if (singleSelections.length > 0) {
      if (singleSelections[0]) {
        addPromptToAppListener();
        setPendingActivity(true);
        commandsMgr.execute(
          singleSelections[0].name,
          setSearchLoader,
          undefined,
          true
        );
        ref.current.blur();
      }
    }
  }, [singleSelections]);

  const addPromptToAppListener = () => {
    socket.addEventListener("prompt_to_app", (event) => {
      console.log("Event Received from prompt_to_app", event);
      // Check if the event has the "architecture" key
      // console.log("Frame: Received architecture edit response");
      if (event?.success === false) {
        ctx.setShowToastr({
          show: true,
          type: "error",
          text: event?.error,
        });
      } else {
        setPendingActivity(false);
        if ("architecture" in event) {
          // console.log("Frame: Architecture key is present");
          setSResponse(event["architecture"]);
        } else {
          let test = event["items"];
        }
        setSearchLoader(false);
      }
    });
  };

  const _renderMenu = (results, menuProps) => {
    if (inputValue.trim().length > 0) {
      const items = results.map((result, index) => (
        <li key={index}>
          <MenuItem position={index} option={result} component="div">
            {result.name}
          </MenuItem>
        </li>
      ));
      const { paginationText, renderMenuItemChildren, newSelectionPrefix, ...rest } = menuProps
      return (
        <Menu {...rest} className="my-2">
          <ul className={styles["menu-ul"]}>{items}</ul>
        </Menu>
      );
    }

    return null;
  };

  function renderMenuItem(option, props, index) {
    return (
      <div key={index} className="custom-option">
        <div className="custom-option-description">{option}</div>
      </div>
    );
  }

  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      const value = event.target.value;
      if (value !== "") {
        setSingleSelections([{ name: value }]);
      }
    }
  };

  const buttonClickHandler = () => {
    let ele = ref.current;
    if (ele) {
      let value = ele.getInput().value;
      if (value === "") {
        setSingleSelections([{ name: value }]);
      } else {
        ele.focus();
      }
    }
  };

  return (
    <div
      show="true"
      size="lg"
      className={styles["canvas-default-modal"]}
      header="false"
    >
      {!sResponse.hasOwnProperty("name") && (
        <>
          <p className={`${styles["heading-common"]} ${styles["description"]}`}>
            What application <br />
            are you trying to create?
            <br /> Be as descriptive as possible.
          </p>
          {Object.keys(sResponse).length === 0 && (
            <>
              <Form.Group className={` ${styles["input-search-wrapper"]}`}>
                <Typeahead
                  id={styles["search-input-field"]}
                  labelKey="name"
                  ref={ref}
                  className={styles["custom-input"]}
                  onChange={(selected) => {
                    setSingleSelections(selected);
                  }}
                  onInputChange={setInputValue}
                  options={options}
                  placeholder="Describe the details here..."
                  selected={singleSelections}
                  renderMenu={_renderMenu}
                  allowNew
                  onKeyDown={keyDownHandler}
                />
                <button
                  type="button"
                  className={styles["btn-gradient"]}
                  onClick={buttonClickHandler}
                >
                  <StartSvg /> Ask AI
                </button>
              </Form.Group>
              <DataTour tourDataId="reactour__center_input"></DataTour>
            </>
          )}
        </>
      )}
      <UploadFileModal
        show={isUploadModalOpen}
        handleClose={() => toggleUploadModal()}
        setCheckActivity={setCheckActivity}
      />
      <AddMultipleGitRepoModal
         show = {isGitRepoAddModalOpen}
         handleClose = {() => toggleGitRepoAddModal()}
         setCheckActivity = {setCheckActivity}
         setPendingActivity = {setPendingActivity}
         setShowToastr = {ctx.setShowToastr}
      />
      <AddConnectCloudModal
         show = {isGitCloudAddModalOpen}
         handleClose = {() => toggleCloudRepoAddModal()}
         setCheckActivity = {setCheckActivity}
         setPendingActivity = {setPendingActivity}
         setShowToastr = {ctx.setShowToastr}
      />
      <div className={styles["btn-action-wrapper"]}>
        <div className={styles["btn-wrapper"]}>
          <ButtonGroup className={styles["button-group"]}>
          <OverlayTrigger
             placement="bottom"
             delay={{ show: 250, hide: 400 }}
             trigger={["hover", "focus"]}
             overlay={<Tooltip id="button-tooltip">Upload files</Tooltip>}
          >
            <Button variant="secondary" className={`${styles["btn-action"]}`} onClick={() => toggleUploadModal(!isUploadModalOpen)}
              disabled={upload_file_disable}>
              <img src={bookFrame} alt=""/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
             placement="top"
             delay={{ show: 250, hide: 400 }}
             trigger={["hover", "focus"]}
             overlay={<Tooltip id="button-tooltip">Connect Github Repo</Tooltip>}
          >
            <Button variant="secondary" className={`${styles["btn-action"]}`} onClick={() => toggleGitRepoAddModal(!isGitRepoAddModalOpen)}>
              <img src={gitFrame} alt=""/>
            </Button>
          </OverlayTrigger>
            <OverlayTrigger
             placement="bottom"
             trigger={["hover", "focus"]}
             delay={{ show: 250, hide: 400 }}
             overlay={<Tooltip id="button-tooltip">Connect Cloud Account</Tooltip>}
          >
            <Button variant="secondary" className={`${styles["btn-action"]}`} onClick={() => toggleCloudRepoAddModal(!isGitCloudAddModalOpen)}>
              <img src={cloudframe} alt=""/>
            </Button>
          </OverlayTrigger>
          </ButtonGroup>
          <span className={styles["point-svg"]}>
            <PointSvg />
          </span>
        </div>
        {/* <h5 className={styles["hr-text"]}>
          <span>OR</span>
        </h5>
        <p className={`${styles["heading-common"]} ${styles["guided-tour"]}`}>
          <NavLink
            onClick={() => {
              guideTourHandler()
            }}
          >
            Click here
          </NavLink>{" "}
          {showGuideTour && <GuideTour isOpen={true} steps={steps} />}
          to get a guided tour of the platform
        </p> */}
      </div>
    </div>
  );
};

export default DefaultCanvasModal;
