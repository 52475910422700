import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Nav,
  Navbar,
  Stack,
  Dropdown,
} from "react-bootstrap";
import { LogoSvg } from "../../images/svg/index";
import { SearchSvg, HomeSvg, BinSvg, PlusSvg } from "../../images/svg/icons";
import "./NewDashboard.css";
import ModalUI from "../components/UI/ModalUI/ModalUI";
import AddProjectModal from "./components/AddProjectModal";
import { NavLink, Link } from "react-router-dom";
import Projects from "./components/Projects";
import { useContext } from "react";
import context from "../../store/create-context";
import Toastify from "../../common/components/Toastify/Toastify";
import avatar from "../../images/avatar_icon.png";

const NewDashboard = (props) => {
  const { projects, setProjects, showToastr, setShowToastr } = useContext(context);
  //states
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const shortCutKeys = [
    // {"Ctrl + z": "Undo"}, {"Ctrl+ Shift + z": "Redo"}, {"an": "add node"},
    {"/": "Ask_Ai search"},{"r": "reset"}, {"+": "Zoom In"}, {"-": "Zoom Out"}, {"Enter": "Move to sub-graph"},
    {"Esc": "Move to parent-graph"}];
  //Handlers
  const setShowHandler = () => {
    setShow(true);
  };

  //set projects
  const handleGetProjects = (data) => {
    if(data?.success === false){
      setShowToastr({
        show: true,
        type: "error",
        text: data?.error,
      });
    }
    else if (data && data.projects){
       setAllProjects(data.projects);
       setProjects(data.projects);
      };
  };

  useEffect(() => {
    setShowToastr((prev) => ({
      ...prev,
      show: false
    }));
  }, []);

  //Send Request to get projects

  useEffect(() => {
    const interval = setTimeout(() => {
      props.socket.addEventListener("get_projects", handleGetProjects);
      props.socket.send("get_projects");
    }, 500);
    return () => {
      clearTimeout(interval);
      props.socket?.removeEventListener("get_projects", handleGetProjects);
    };
  }, [props.socket]);

  const handleChange = (e) => {
    if (e.target.value === "") {
      setProjects(allProjects);
    } else {
      const filterProjects = allProjects.filter((project) => project.name.toLowerCase().includes(e.target.value.toLowerCase()));
      setProjects(filterProjects);
    }
  }

  let firstname = localStorage.getItem("JMLFirstname");
  if (!firstname) {
    firstname = "User";
  }

  return (
    <main>
      <Container>
        <Navbar expand="lg" className="py-5 px-2" variant="dark">
          <Navbar.Brand href="#home">
            <LogoSvg className="logo-icon" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                as={Link}
                to="#"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Coming Soon"
              >
                Resources
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="#"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Coming Soon"
              >
                FAQ
              </Nav.Link>
              <Nav.Link
                href=""
                onClick={() => setCurrentPage(0)}
                style={{ color: "white", border: "1px solid white", marginLeft: "20px"}}
              >
                <span>
                  <HomeSvg />
                </span>
                My Projects
              </Nav.Link>
              <Dropdown
                className="mx-auto patch-dropdown"
                drop="down"
                variant="success"
              >
                <Dropdown.Toggle>
                  <img
                    src={avatar}
                    className="user-img"
                    loading="lazy"
                    alt="avatar"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown-toggle">
                  <Dropdown.Item href="/change-password">Change password</Dropdown.Item>
                  <Dropdown.Item href="#">{firstname}</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={props.logoutHandler}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Row xs={12} md={12} lg={12}>
          <Col sm={12} md={3}>
            <div className="left-side">
              <Form.Group className="mb-3 has-search">
                <span>
                  <SearchSvg />
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search snapshots, projects"
                  name="s"
                  onChange={handleChange}
                />
              </Form.Group>
              <Stack gap={5}>
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link
                      href=""
                      className={currentPage === 0 && "active"}
                      onClick={() => setCurrentPage(0)}
                    >
                      <span>
                        <HomeSvg />
                      </span>
                      My projects
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link href="" className={currentPage === 1 && 'active'} onClick={()=>setCurrentPage(1)}>
                      <span>
                        <BinSvg />
                      </span>
                      Bin
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>

                {!props.sandboxMode && (
                  <button
                    className="blue-btn mb-4"
                    onClick={setShowHandler}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <span>
                      <PlusSvg color={isHovered ? '#5A46FF' : 'white'} />
                    </span>
                    <span style={{ color: isHovered ? "#5A46FF" : "white" }}>
                      New Project
                    </span>
                  </button>
                )}
              </Stack>
              <table className="shortcut-key-table">
                <thead>
                  <tr>
                    <th className="shortcut-heading">Shortcut</th>
                    <th className="shortcut-heading">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {shortCutKeys.map((shortcut, index) => {
                    return (
                      <tr key={index} className="shortcut-data-row">
                        <td className="shortcut-key-column">{Object.keys(shortcut)}</td>
                        <td className="shortcut-key-column">{Object.values(shortcut)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Col>
          <Col sm={12} md={9}>
            <div className="right-side">
              <Projects
                setShowHandler={setShowHandler}
                socket={props.socket}
                sandboxMode={props.sandboxMode}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <AddProjectModal
        modalTitle="New Project"
        show={show}
        handleClose={() => setShow(false)}
        socket={props.socket}
        sandboxMode={props.sandboxMode}
      />
      <Toastify
        show={showToastr["show"]}
        type={showToastr["type"]}
        message={showToastr["text"]}
        handleClose={() => {
          setShowToastr((props) => {
            return { ...props, show: false };
          });
        }}
      />
    </main>
  );
};

export default NewDashboard;
