const AllSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1545_35285)">
        <path
          d="M7.01872 7.61422C6.93369 7.61422 6.86992 7.59297 6.7849 7.57171L1.02446 5.23353C0.790639 5.1485 0.641846 4.91468 0.641846 4.68086C0.641846 4.44704 0.790639 4.21323 1.02446 4.1282L6.80616 1.79001C6.95495 1.72625 7.10374 1.72625 7.25254 1.79001L13.0342 4.10694C13.2681 4.19197 13.4168 4.42579 13.4168 4.65961C13.4168 4.89343 13.2681 5.12724 13.0342 5.21227L7.25254 7.57171C7.16751 7.59297 7.10374 7.61422 7.01872 7.61422ZM2.87375 4.68086L7.01872 6.33885L11.1637 4.65961L7.01872 3.00162L2.87375 4.68086Z"
          fill="#989898"
        />
        <path
          d="M7.01858 10.2091C6.93356 10.2091 6.86979 10.1878 6.78476 10.1666L1.02432 7.82838C0.705477 7.70084 0.556684 7.33949 0.684221 7.0419C0.811759 6.72306 1.15186 6.57426 1.4707 6.7018L7.01858 8.9337L12.5665 6.7018C12.8853 6.57426 13.2254 6.72306 13.3529 7.0419C13.4805 7.36074 13.3317 7.70084 13.0128 7.82838L7.2524 10.1666C7.18863 10.1878 7.10361 10.2091 7.01858 10.2091Z"
          fill="#989898"
        />
        <path
          d="M7.01858 12.6331C6.93356 12.6331 6.86979 12.6119 6.78476 12.5906L1.02432 10.2737C0.705477 10.1462 0.556684 9.7848 0.684221 9.48721C0.811759 9.16837 1.15186 9.01957 1.4707 9.14711L7.01858 11.379L12.5665 9.14711C12.8853 9.01957 13.2254 9.16837 13.3529 9.48721C13.4805 9.80606 13.3317 10.1462 13.0128 10.2737L7.2524 12.5906C7.18863 12.6331 7.10361 12.6331 7.01858 12.6331Z"
          fill="#989898"
        />
      </g>
      <defs>
        <clipPath id="clip0_1545_35285">
          <rect
            width="12.775"
            height="10.8832"
            fill="white"
            transform="translate(0.641602 1.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AllSvg;
