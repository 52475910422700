import React from "react";

const CollpaseSvg = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1557_5857)">
        <path
          d="M6.34661 11.9974C6.13427 11.9974 5.94848 11.9178 5.78923 11.7585L0.905548 6.90138C0.587046 6.58288 0.587046 6.07858 0.905548 5.78662L5.76269 0.902938C6.08119 0.584437 6.58548 0.584437 6.87744 0.902938C7.19594 1.22144 7.19594 1.72573 6.87744 2.01769L2.57768 6.344L6.87744 10.6438C7.19594 10.9623 7.19594 11.4666 6.87744 11.7585C6.74473 11.9178 6.5324 11.9974 6.34661 11.9974Z"
          fill="#F8F8FF"
        />
        <path
          d="M11.2037 11.9974C10.9914 11.9974 10.8056 11.9178 10.6464 11.7585L5.76268 6.90138C5.44418 6.58288 5.44418 6.07858 5.76268 5.78662L10.6464 0.902938C10.9649 0.584437 11.4692 0.584437 11.7611 0.902938C12.0796 1.22144 12.0796 1.72573 11.7611 2.01769L7.46135 6.344L11.7611 10.6438C12.0796 10.9623 12.0796 11.4666 11.7611 11.7585C11.6019 11.9178 11.3895 11.9974 11.2037 11.9974Z"
          fill="#F8F8FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1557_5857">
          <rect
            width="11.3333"
            height="11.3333"
            fill="white"
            transform="translate(0.666672 0.664062)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CollpaseSvg;
