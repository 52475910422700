const EyeClosePasswordSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_3050_1512)">
        <path
          d="M15.0413 14.0844L1.93129 0.886871C1.63942 0.593048 1.17728 0.593048 0.885409 0.886871C0.617858 1.15621 0.617858 1.64591 0.885409 1.91525L3.48795 4.53517C2.56368 5.3187 1.66374 6.34708 0.788118 7.59583C0.788118 7.59583 0.788118 7.59583 0.788118 7.62032C0.763795 7.6448 0.763795 7.66929 0.739472 7.69377C0.739472 7.71826 0.71515 7.71826 0.71515 7.74274C0.71515 7.76723 0.690827 7.79171 0.690827 7.8162C0.690827 7.84068 0.666504 7.86517 0.666504 7.88965C0.666504 7.91414 0.666504 7.93862 0.666504 7.96311C0.666504 7.98759 0.666504 8.01208 0.666504 8.03656C0.666504 8.06105 0.666504 8.08554 0.666504 8.11002C0.666504 8.13451 0.666504 8.15899 0.666504 8.18348C0.666504 8.20796 0.690827 8.23245 0.690827 8.25693C0.690827 8.28142 0.71515 8.3059 0.71515 8.33039C0.71515 8.35487 0.739472 8.37936 0.739472 8.37936C0.763795 8.40384 0.763795 8.42833 0.788118 8.45281C0.788118 8.45281 0.788118 8.45281 0.788118 8.4773C2.97717 11.6604 5.40946 13.2764 7.96335 13.2764C9.05788 13.2764 10.1524 12.9581 11.1983 12.3705L13.9954 15.1128C14.1414 15.2597 14.3359 15.3332 14.5062 15.3332C14.7008 15.3332 14.871 15.2597 15.017 15.1128C15.3088 14.819 15.3088 14.3538 15.0413 14.0844ZM7.96335 10.3137C6.69857 10.3137 5.67701 9.28531 5.67701 8.01208C5.67701 7.6448 5.7743 7.30201 5.92024 6.9837L8.98491 10.0688C8.69304 10.2158 8.3282 10.3137 7.96335 10.3137Z"
          fill="#B1B2B3"
        />
        <path
          d="M12.6579 10.6322C12.8525 10.8281 13.1687 10.8281 13.3389 10.6322C13.947 9.99561 14.5551 9.26105 15.1388 8.42855L15.2361 8.28164C15.3577 8.11025 15.3577 7.88988 15.2361 7.71848L15.1388 7.57157C12.9497 4.38849 10.5175 2.77246 7.96357 2.77246C7.40415 2.77246 6.84472 2.84592 6.2853 3.01731L6.13936 3.06628C5.77452 3.16422 5.67722 3.62944 5.92045 3.8743L12.6579 10.6322Z"
          fill="#B1B2B3"
        />
      </g>
      <defs>
        <clipPath id="clip0_3050_1512">
          <rect
            width="14.6667"
            height="14.6667"
            fill="white"
            transform="translate(0.666504 0.666504)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeClosePasswordSvg;