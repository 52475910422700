const DownloadSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3295_8492)">
        <path
          d="M3.59997 5.72708C3.79552 5.72708 3.96663 5.80042 4.1133 5.94708L7.99997 9.83375L11.8866 5.94708C12.18 5.65375 12.6444 5.65375 12.9133 5.94708C13.2066 6.24042 13.2066 6.70486 12.9133 6.97375L8.5133 11.3737C8.21997 11.6671 7.75552 11.6671 7.48663 11.3737L3.08664 6.97375C2.7933 6.68042 2.7933 6.21597 3.08664 5.94708C3.2333 5.80042 3.40441 5.72708 3.59997 5.72708Z"
          fill="white"
        />
        <path
          d="M13.0845 15.3335H2.91564C1.66897 15.3335 0.666748 14.3313 0.666748 13.0846V10.1513C0.666748 9.73575 0.984526 9.41797 1.40008 9.41797C1.81564 9.41797 2.13341 9.73575 2.13341 10.1513V13.0846C2.13341 13.5246 2.47564 13.8669 2.91564 13.8669H13.0845C13.5245 13.8669 13.8667 13.5246 13.8667 13.0846V10.1513C13.8667 9.73575 14.1845 9.41797 14.6001 9.41797C15.0156 9.41797 15.3334 9.73575 15.3334 10.1513V13.0846C15.3334 14.3313 14.3312 15.3335 13.0845 15.3335Z"
          fill="white"
        />
        <path
          d="M8.00007 0.66708C8.41562 0.66708 8.7334 0.984858 8.7334 1.40041L8.7334 10.0782C8.7334 10.4937 8.41562 10.8115 8.00007 10.8115C7.58451 10.8115 7.26673 10.4937 7.26673 10.0782L7.26673 1.40041C7.26673 0.984857 7.58451 0.66708 8.00007 0.66708Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3295_8492">
          <rect
            width="14.6667"
            height="14.6667"
            fill="white"
            transform="translate(0.666748 0.666992)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DownloadSvg;
