const ComponentSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1542_13264)">
        <g clipPath="url(#clip1_1542_13264)">
          <path
            d="M6.57756 7.31066H1.40253C0.985589 7.31066 0.666748 6.99182 0.666748 6.57488V1.39985C0.666748 0.982903 0.985589 0.664062 1.40253 0.664062H6.57756C6.99451 0.664062 7.31335 0.982903 7.31335 1.39985V6.57488C7.31335 6.99182 6.99451 7.31066 6.57756 7.31066ZM2.13832 5.83909H5.84178V2.13563H2.13832V5.83909Z"
            fill="#D2D2D2"
          />
          <path
            d="M6.57756 15.3341H1.40253C0.985589 15.3341 0.666748 15.0153 0.666748 14.5983V9.42329C0.666748 9.00634 0.985589 8.6875 1.40253 8.6875H6.57756C6.99451 8.6875 7.31335 9.00634 7.31335 9.42329V14.5983C7.31335 15.0153 6.99451 15.3341 6.57756 15.3341ZM2.13832 13.8625H5.84178V10.1591H2.13832V13.8625Z"
            fill="#D2D2D2"
          />
          <path
            d="M14.5976 7.31066H9.42255C9.00561 7.31066 8.68677 6.99182 8.68677 6.57488V1.39985C8.68677 0.982903 9.00561 0.664062 9.42255 0.664062H14.5976C15.0145 0.664062 15.3334 0.982903 15.3334 1.39985V6.57488C15.3334 6.99182 15.0145 7.31066 14.5976 7.31066ZM10.1583 5.83909H13.8618V2.13563H10.1583V5.83909Z"
            fill="#D2D2D2"
          />
          <path
            d="M14.5976 15.3341H9.42255C9.00561 15.3341 8.68677 15.0153 8.68677 14.5983V9.42329C8.68677 9.00634 9.00561 8.6875 9.42255 8.6875H14.5976C15.0145 8.6875 15.3334 9.00634 15.3334 9.42329V14.5983C15.3334 15.0153 15.0145 15.3341 14.5976 15.3341ZM10.1583 13.8625H13.8618V10.1591H10.1583V13.8625Z"
            fill="#D2D2D2"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1542_13264">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_1542_13264">
          <rect
            width="14.6667"
            height="14.6667"
            fill="white"
            transform="translate(0.666748 0.664062)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ComponentSvg;
