const SliderSvg = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1545_32714)">
        <path
          d="M10.3188 25.5011C9.82609 25.5011 9.42029 25.0941 9.42029 24.5999V18.2918C9.42029 17.7976 9.82609 17.3906 10.3188 17.3906C10.8116 17.3906 11.2174 17.7976 11.2174 18.2918V24.5999C11.2174 25.0941 10.8116 25.5011 10.3188 25.5011Z"
          fill="#F8F8FF"
        />
        <path
          d="M14.6667 25.4985C14.174 25.4985 13.7682 25.0916 13.7682 24.5974V15.9637C13.7682 15.4695 14.174 15.0625 14.6667 15.0625C15.1595 15.0625 15.5653 15.4695 15.5653 15.9637V24.5974C15.5653 25.0916 15.1595 25.4985 14.6667 25.4985Z"
          fill="#F8F8FF"
        />
        <path
          d="M19.0145 25.5035C18.5218 25.5035 18.116 25.0965 18.116 24.6023V20.0965C18.116 19.6023 18.5218 19.1953 19.0145 19.1953C19.5073 19.1953 19.9131 19.6023 19.9131 20.0965V24.6023C19.9131 25.0965 19.5073 25.5035 19.0145 25.5035Z"
          fill="#F8F8FF"
        />
        <path
          d="M10.3188 15.6163C9.82609 15.6163 9.42029 15.2093 9.42029 14.7151V8.90116C9.42029 8.40698 9.82609 8 10.3188 8C10.8116 8 11.2174 8.40698 11.2174 8.90116V14.7151C11.2174 15.2093 10.8116 15.6163 10.3188 15.6163Z"
          fill="#F8F8FF"
        />
        <path
          d="M14.6667 13.2907C14.174 13.2907 13.7682 12.8837 13.7682 12.3895V8.90116C13.7682 8.40698 14.174 8 14.6667 8C15.1595 8 15.5653 8.40698 15.5653 8.90116V12.3895C15.5653 12.8837 15.1595 13.2907 14.6667 13.2907Z"
          fill="#F8F8FF"
        />
        <path
          d="M19.0145 17.3895C18.5218 17.3895 18.116 16.9826 18.116 16.4884V8.90116C18.116 8.40698 18.5218 8 19.0145 8C19.5073 8 19.9131 8.40698 19.9131 8.90116V16.5174C19.9131 17.0116 19.5073 17.3895 19.0145 17.3895Z"
          fill="#F8F8FF"
        />
        <path
          d="M16.116 13.5801H13.2175C12.7247 13.5801 12.3479 13.2022 12.3479 12.708C12.3479 12.2138 12.7247 11.8359 13.2175 11.8359H16.116C16.6088 11.8359 16.9856 12.2138 16.9856 12.708C16.9856 13.2022 16.6088 13.5801 16.116 13.5801Z"
          fill="#F8F8FF"
        />
        <path
          d="M20.4638 20.7051H17.5652C17.0725 20.7051 16.6957 20.3272 16.6957 19.833C16.6957 19.3388 17.0725 18.9609 17.5652 18.9609H20.4638C20.9566 18.9609 21.3334 19.3388 21.3334 19.833C21.3334 20.3272 20.9566 20.7051 20.4638 20.7051Z"
          fill="#F8F8FF"
        />
        <path
          d="M11.7681 18.9317H8.86957C8.37681 18.9317 8 18.5538 8 18.0596C8 17.5654 8.37681 17.1875 8.86957 17.1875H11.7681C12.2609 17.1875 12.6377 17.5654 12.6377 18.0596C12.6377 18.5538 12.2609 18.9317 11.7681 18.9317Z"
          fill="#F8F8FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1545_32714">
          <rect
            width="13.3333"
            height="17.5"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SliderSvg;
