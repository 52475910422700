const EyePasswordSvg = () => {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_808_13482)">
          <path
            d="M15.3333 7.59608C15.3333 7.57261 15.3333 7.54913 15.3333 7.52566C15.3333 7.50218 15.3089 7.47871 15.3089 7.45524C15.3089 7.43176 15.2845 7.40829 15.2845 7.38481C15.2845 7.36134 15.2601 7.33787 15.2601 7.33787C15.2357 7.31439 15.2357 7.29092 15.2113 7.26744C15.2113 7.26744 15.2113 7.26744 15.2113 7.24397C12.9905 4.2158 10.5746 2.6665 7.98776 2.6665C5.40096 2.6665 3.00939 4.2158 0.788645 7.26744C0.788645 7.26744 0.788645 7.26744 0.788645 7.29092C0.764241 7.31439 0.764241 7.33787 0.739837 7.36134C0.739837 7.38481 0.715434 7.38481 0.715434 7.40829C0.715434 7.43176 0.69103 7.45524 0.69103 7.47871C0.69103 7.50218 0.666626 7.52566 0.666626 7.54913C0.666626 7.57261 0.666626 7.59608 0.666626 7.61956C0.666626 7.64303 0.666626 7.6665 0.666626 7.68998C0.666626 7.71345 0.666626 7.73693 0.666626 7.7604C0.666626 7.78387 0.666626 7.80735 0.666626 7.83082C0.666626 7.8543 0.69103 7.87777 0.69103 7.90125C0.69103 7.92472 0.715434 7.94819 0.715434 7.97167C0.715434 7.99514 0.739837 8.01862 0.739837 8.01862C0.764241 8.04209 0.764241 8.06557 0.788645 8.08904C0.788645 8.08904 0.788645 8.08904 0.788645 8.11251C2.98498 11.1642 5.42536 12.7135 7.98776 12.7135C10.5502 12.7135 12.9905 11.1172 15.1869 8.06557C15.1869 8.06557 15.1869 8.06557 15.1869 8.04209C15.2113 8.01862 15.2113 7.99514 15.2357 7.97167C15.2357 7.94819 15.2601 7.94819 15.2601 7.92472C15.2601 7.90125 15.2845 7.87777 15.2845 7.8543C15.2845 7.83082 15.3089 7.80735 15.3089 7.78387C15.3089 7.7604 15.3089 7.73693 15.3089 7.71345C15.3089 7.68998 15.3089 7.6665 15.3089 7.64303C15.3333 7.64303 15.3333 7.61956 15.3333 7.59608ZM7.98776 9.87308C6.71876 9.87308 5.6938 8.88716 5.6938 7.6665C5.6938 6.44585 6.71876 5.45993 7.98776 5.45993C9.25675 5.45993 10.2817 6.44585 10.2817 7.6665C10.2817 8.88716 9.25675 9.87308 7.98776 9.87308Z"
            fill="#B1B2B3"
          />
        </g>
        <defs>
          <clipPath id="clip0_808_13482">
            <rect
          width="14.6667"
          height="14.6667"
              fill="white"
              transform="translate(0.666626 2.6665)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  };
  
  export default EyePasswordSvg;