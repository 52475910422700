import amplify from "../../../images/aws/Architecture-Service-Icons/Arch_Front-End-Web-Mobile/48/Arch_AWS-Amplify_48.svg";
import apigateway from "../../../images/aws/Architecture-Service-Icons/Arch_Networking-Content-Delivery/48/Arch_Amazon-API-Gateway_48.svg";
import appsync from "../../../images/aws/Architecture-Service-Icons/Arch_App-Integration/48/Arch_AWS-AppSync_48.svg";
import group from "../../../images/aws/Architecture-Group-Icons/Auto-Scaling-group_32.svg";
import cloudtrail from "../../../images/aws/Architecture-Service-Icons/Arch_Management-Governance/48/Arch_AWS-CloudTrail_48.svg";
import codebuild from "../../../images/aws/Architecture-Service-Icons/Arch_Developer-Tools/48/Arch_AWS-CodeBuild_48.svg";
import codepipeline from "../../../images/aws/Architecture-Service-Icons/Arch_Developer-Tools/48/Arch_AWS-CodePipeline_48.svg";
import table from "../../../images/aws/Resource-Icons/Res_Database/Res_Amazon-DynamoDB_Table_48.svg";
import elasticip from "../../../images/aws/Resource-Icons/Res_Compute/Res_Amazon-EC2_Elastic-IP-Address_48.svg";
import instance from "../../../images/aws/Resource-Icons/Res_Compute/Res_Amazon-EC2_Instance_48.svg";
import keypair from "../../../images/aws/Resource-Icons/Res_Security-Identity-Compliance/Res_AWS-Identity-Access-Management_Data-Encryption-Key_48.svg";
import routetable from "../../../images/aws/Resource-Icons/Res_Networking-Content-Delivery/Res_Amazon-Route-53_Route-Table_48.svg";
import snapshot from "../../../images/aws/Resource-Icons/Res_Storage/Res_Amazon-Elastic-Block-Store_Snapshot_48.svg";
import subnet from "../../../images/aws/Architecture-Group-Icons/Public-subnet_32.svg";
import volume from "../../../images/aws/Resource-Icons/Res_Storage/Res_Amazon-Elastic-Block-Store_Volume_48.svg";
import vpc from "../../../images/aws/Resource-Icons/Res_Networking-Content-Delivery/Res_Amazon-VPC_Endpoints_48.svg";
import ecs from "../../../images/aws/Architecture-Service-Icons/Arch_Containers/48/Arch_Amazon-Elastic-Container-Service_48.svg";
import filesystem from "../../../images/aws/Resource-Icons/Res_Storage/Res_Amazon-Elastic-File-System_File-System_48.svg";
import eks from "../../../images/aws/Architecture-Service-Icons/Arch_Containers/48/Arch_Amazon-Elastic-Kubernetes-Service_48.svg";
import elasticbeanstalk from "../../../images/aws/Resource-Icons/Res_Compute/Res_AWS-Elastic-Beanstalk_Application_48.svg";
import elb from "../../../images/aws/Architecture-Service-Icons/Arch_Networking-Content-Delivery/48/Arch_Elastic-Load-Balancing_48.svg";
import emr from "../../../images/aws/Resource-Icons/Res_Analytics/Res_Amazon-EMR_Cluster_48.svg";
import role from "../../../images/aws/Resource-Icons/Res_Security-Identity-Compliance/Res_AWS-Identity-Access-Management_Role_48.svg";
import iam  from "../../../images/aws/Resource-Icons/Res_Security-Identity-Compliance/Res_AWS-Identity-Access-Management_IAM-Roles-Anywhere_48.svg"
import kinesis from "../../../images/aws/Architecture-Service-Icons/Arch_Analytics/48/Arch_Amazon-Kinesis-Data-Streams_48.svg";
import kms_key from "../../../images/aws/Architecture-Service-Icons/Arch_Security-Identity-Compliance/48/Arch_AWS-Key-Management-Service_48.svg";
import lambda_function from "../../../images/aws/Resource-Icons/Res_Compute/Res_AWS-Lambda_Lambda-Function_48.svg";
import rds from "../../../images/aws/Resource-Icons/Res_Database/Res_Amazon-RDS_Multi-AZ-DB-Cluster_48.svg";
import db from "../../../images/aws/Resource-Icons/Res_Database/Res_Amazon-RDS_Multi-AZ-DB-Cluster_48.svg";
import redshift from "../../../images/aws/Architecture-Service-Icons/Arch_Analytics/48/Arch_Amazon-Redshift_48.svg";
import hostedzone from "../../../images/aws/Resource-Icons/Res_Networking-Content-Delivery/Res_Amazon-Route-53-Hosted-Zone_48.svg";
import s3_bucket from "../../../images/aws/Resource-Icons/Res_Storage/Res_Amazon-Simple-Storage-Service_Bucket-With-Objects_48.svg";
import model from "../../../images/aws/Resource-Icons/Res_Artificial-Intelligence/Res_Amazon-SageMaker_Model_48.svg";
import notebookinstance from "../../../images/aws/Resource-Icons/Res_Artificial-Intelligence/Res_Amazon-SageMaker_Notebook_48.svg";
import topic from "../../../images/aws/Resource-Icons/Res_Application-Integration/Res_Amazon-Simple-Notification-Service_Topic_48.svg";
import queue from "../../../images/aws/Resource-Icons/Res_Application-Integration/Res_Amazon-Simple-Queue-Service_Queue_48.svg";
import ec2 from "../../../images/aws/Architecture-Service-Icons/Arch_Compute/16/Arch_Amazon-EC2_16.svg";
import app from "../../../images/aws/Resource-Icons/Res_Management-Governance/Res_AWS-OpsWorks_Apps_48.svg";
import api from "../../../images/aws/Architecture-Service-Icons/Arch_Developer-Tools/48/Arch_AWS-Cloud-Control-API_48.svg";
import autoscaling from "../../../images/aws/Architecture-Service-Icons/Arch_Management-Governance/48/Arch_AWS-Auto-Scaling_48.svg";
import trail from "../../../images/aws/Resource-Icons/Res_Management-Governance/Res_AWS-CloudTrail_CloudTrail-Lake_48.svg";
import project from "../../../images/aws/Resource-Icons/Res_Analytics/Res_Amazon-DataZone_Data-Projects_48.svg";
import pipeline from "../../../images/aws/Architecture-Service-Icons/Arch_Analytics/48/Arch_AWS-Data-Pipeline_48.svg";
import dynamodb from "../../../images/aws/Architecture-Service-Icons/Arch_Database/48/Arch_Amazon-DynamoDB_48.svg";
import cluster from "../../../images/aws/Architecture-Service-Icons/Arch_Compute/48/Arch_AWS-Parallel-Cluster_48.svg";
import efs from "../../../images/aws/Architecture-Service-Icons/Arch_Storage/48/Arch_Amazon-EFS_48.svg";
import loadbalancer from "../../../images/aws/Resource-Icons/Res_Networking-Content-Delivery/Res_Elastic-Load-Balancing_Classic-Load-Balancer_48.svg";
import stream from "../../../images/aws/Resource-Icons/Res_General-Icons/Res_48_Dark/Res_Data-Stream_48_Dark.svg";
import lambda from "../../../images/aws/Architecture-Service-Icons/Arch_Compute/48/Arch_AWS-Lambda_48.svg";
import route53 from "../../../images/aws/Architecture-Service-Icons/Arch_Networking-Content-Delivery/48/Arch_Amazon-Route-53_48.svg";
import s3 from "../../../images/aws/Resource-Icons/Res_Storage/Res_Amazon-Simple-Storage-Service_S3-Standard_48.svg";
import sagemaker from "../../../images/aws/Architecture-Service-Icons/Arch_Artificial-Intelligence/48/Arch_Amazon-SageMaker_48.svg";
import sns from "../../../images/aws/Architecture-Service-Icons/Arch_App-Integration/48/Arch_Amazon-Simple-Notification-Service_48.svg";
import sqs from "../../../images/aws/Architecture-Service-Icons/Arch_App-Integration/48/Arch_Amazon-Simple-Queue-Service_48.svg";
import "./infraviewResource-icons.css";

export const infraviewResourceIcon = {
  ec2 : <img src={ec2} alt='' className="resIcon" />,
  amplify: <img src={amplify} alt='' className="resIcon" />,
  app : <img src={app} alt='' className="resIcon" />,
  apigateway: <img src={apigateway} alt='' className="resIcon" />,
  // restapi: <img src={} alt='' className="resIcon" />,
  apigatewayv2: <img src={apigateway} alt='' className="resIcon" />,
  api: <img src={api} alt='' className="resIcon" />,
  appsync: <img src={appsync} alt='' className="resIcon" />,
  // graphqlapi: <img src={} alt='' className="resIcon" />,
  autoscaling: <img src={autoscaling} alt='' className="resIcon" />,
  group: <img src={group} alt='' className="resIcon" />,
  // 'launch-configuration': <img src={launchconfiguration} alt='' className="resIcon" />,
  cloudtrail: <img src={cloudtrail} alt='' className="resIcon" />,
  trail: <img src={trail} alt='' className="resIcon" />,
  codebuild: <img src={codebuild} alt='' className="resIcon" />,
  project: <img src={project} alt='' className="resIcon" />,
  codepipeline: <img src={codepipeline} alt='' className="resIcon" />,
  pipeline: <img src={pipeline} alt='' className="resIcon" />,
  dynamodb: <img src={dynamodb} alt='' className="resIcon" />,
  table: <img src={table} alt='' className="resIcon" />,
  'elastic-ip': <img src={elasticip} alt='' className="resIcon" />,
  instance: <img src={instance} alt='' className="resIcon" />,
  'key-pair': <img src={keypair} alt='' className="resIcon" />,
  'route-table': <img src={routetable} alt='' className="resIcon" />,
  snapshot: <img src={snapshot} alt='' className="resIcon" />,
  subnet: <img src={subnet} alt='' className="resIcon" />,
  volume: <img src={volume} alt='' className="resIcon" />,
  vpc: <img src={vpc} alt='' className="resIcon" />,
  ecs: <img src={ecs} alt='' className="resIcon" />,
  cluster: <img src={cluster} alt='' className="resIcon" />,
  efs: <img src={efs} alt='' className="resIcon" />,
  'file-system': <img src={filesystem} alt='' className="resIcon" />,
  eks: <img src={eks} alt='' className="resIcon" />,
  elasticbeanstalk: <img src={elasticbeanstalk} alt='' className="resIcon" />,
  // application: <img src={elasticbeanstalk_application} alt='' className="resIcon" />,
  elb: <img src={elb} alt='' className="resIcon" />,
  elbv2: <img src={elb} alt='' className="resIcon" />,
  loadbalancer: <img src={loadbalancer} alt='' className="resIcon" />,
  emr: <img src={emr} alt='' className="resIcon" />,
  iam: <img src={iam} alt='' className="resIcon" />,
  role: <img src={role} alt='' className="resIcon" />,
  kinesis: <img src={kinesis} alt='' className="resIcon" />,
  stream: <img src={stream} alt='' className="resIcon" />,
  kms: <img src={kms_key} alt='' className="resIcon" />,
  key: <img src={keypair} alt='' className="resIcon" />,
  lambda: <img src={lambda} alt='' className="resIcon" />,
  function: <img src={lambda_function} alt='' className="resIcon" />,
  rds:<img src={rds} alt='' className="resIcon" />,
  db: <img src={db} alt='' className="resIcon" />,
  redshift: <img src={redshift} alt='' className="resIcon" />,
  route53: <img src={route53} alt='' className="resIcon" />,
  hostedzone: <img src={hostedzone} alt='' className="resIcon" />,
  s3: <img src={s3} alt='' className="resIcon" />,
  bucket: <img src={s3_bucket} alt='' className="resIcon" />,
  sagemaker: <img src={sagemaker} alt='' className="resIcon" />,
  model: <img src={model} alt='' className="resIcon" />,
  'notebook-instance': <img src={notebookinstance} alt='' className="resIcon" />,
  sns: <img src={sns} alt='' className="resIcon" />,
  topic: <img src={topic} alt='' className="resIcon" />,
  sqs:  <img src={sqs} alt='' className="resIcon" />,
  queue:  <img src={queue} alt='' className="resIcon" />,
};
