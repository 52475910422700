const DescriptionSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1542_13261)">
        <path
          d="M13.2606 15.3307H2.07255C1.65362 15.3307 1.33325 15.0135 1.33325 14.5986V1.39618C1.33325 0.981312 1.65362 0.664062 2.07255 0.664062H8.28267C8.7016 0.664062 9.02197 0.981312 9.02197 1.39618V5.59362H13.2606C13.6796 5.59362 13.9999 5.91087 13.9999 6.32574V14.5986C13.9999 14.9891 13.6796 15.3307 13.2606 15.3307ZM2.81185 13.8665H12.5213V7.05785H8.28267C7.86373 7.05785 7.54337 6.7406 7.54337 6.32574V2.12829H2.81185V13.8665Z"
          fill="#D2D2D2"
        />
        <path
          d="M13.2607 7.05785C13.0636 7.05785 12.8911 6.98464 12.7432 6.83822L7.76525 1.90865C7.46953 1.61581 7.46953 1.15214 7.76525 0.883696C8.06097 0.590851 8.52919 0.590851 8.80027 0.883696L13.7782 5.78885C14.0739 6.0817 14.0739 6.54537 13.7782 6.81381C13.6304 6.98464 13.4579 7.05785 13.2607 7.05785Z"
          fill="#D2D2D2"
        />
        <path
          d="M10.7471 12.3549H4.58622C4.16729 12.3549 3.84692 12.0376 3.84692 11.6227C3.84692 11.2079 4.16729 10.8906 4.58622 10.8906H10.7224C11.1413 10.8906 11.4617 11.2079 11.4617 11.6227C11.4617 12.0376 11.1413 12.3549 10.7471 12.3549Z"
          fill="#D2D2D2"
        />
        <path
          d="M10.7471 9.69079H4.58622C4.16729 9.69079 3.84692 9.37354 3.84692 8.95868C3.84692 8.54381 4.16729 8.22656 4.58622 8.22656H10.7224C11.1413 8.22656 11.4617 8.54381 11.4617 8.95868C11.4617 9.37354 11.1413 9.69079 10.7471 9.69079Z"
          fill="#D2D2D2"
        />
        <path
          d="M6.13875 7.05798H4.58622C4.16729 7.05798 3.84692 6.74073 3.84692 6.32586C3.84692 5.911 4.16729 5.59375 4.58622 5.59375H6.13875C6.55769 5.59375 6.87805 5.911 6.87805 6.32586C6.87805 6.74073 6.55769 7.05798 6.13875 7.05798Z"
          fill="#D2D2D2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1542_13261">
          <rect
            width="12.6667"
            height="14.6667"
            fill="white"
            transform="translate(1.33325 0.664062)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DescriptionSvg;
