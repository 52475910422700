import React from "react";

const BlueSvg = (props) => {
  return (
    <svg
      width="170"
      height="116"
      viewBox="0 0 170 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.3152 6.07093C88.5321 3.30969 80.7773 3.30969 75.9943 6.07093L8.64114 44.9539C6.02955 46.4616 4.84392 48.4807 5.08424 50.4528C5.02892 50.7506 5 51.0577 5 51.3716V68.3716C5 68.7208 5.03579 69.0616 5.1039 69.3905C4.7881 71.4071 5.96718 73.4869 8.64113 75.0306L75.9943 113.914C80.7773 116.675 88.5321 116.675 93.3151 113.914L160.668 75.0306C162.503 73.9713 163.634 72.6594 164.061 71.2887C164.652 70.4677 165 69.4603 165 68.3716V51.3716C165 50.1791 164.583 49.0841 163.886 48.2247C163.364 47.0262 162.292 45.8912 160.668 44.9539L93.3152 6.07093Z"
        fill="white"
      />
      <g filter="url(#filter0_d_55_135)">
        <rect
          width="97.771"
          height="97.771"
          rx="10"
          transform="matrix(0.866044 0.499967 -0.866044 0.499967 84.674 1)"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_55_135"
          x="1.07318"
          y="0.929688"
          width="167.202"
          height="99.9062"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_55_135"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_55_135"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default BlueSvg;
