import React from "react";

const UploadSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1331_48347)">
        <g clipPath="url(#clip1_1331_48347)">
          <path
            d="M8.73421 0.320078C8.52052 0.106745 8.25341 7.742e-05 7.9863 7.74285e-05C7.71919 7.7437e-05 7.45207 0.106745 7.23838 0.320078L2.61735 4.93341C2.18997 5.36008 2.18997 6.02674 2.61735 6.45341C3.04473 6.88008 3.71251 6.88008 4.13989 6.45341L6.89114 3.65341L6.89114 11.0134C6.89114 11.6001 7.37194 12.0801 7.95959 12.0801C8.54723 12.0801 9.02803 11.6001 9.02803 11.0134L9.02803 3.65341L11.8327 6.45341C12.2601 6.88008 12.9279 6.88008 13.3552 6.45341C13.7826 6.02674 13.7826 5.36008 13.3552 4.93341L8.73421 0.320078Z"
            fill="white"
          />
          <path
            d="M14.9316 7.33301C14.3439 7.33301 13.8631 7.81301 13.8631 8.39967L13.8631 13.8663L2.13689 13.8663L2.13689 8.39967C2.13689 7.81301 1.65609 7.33301 1.06845 7.33301C0.480801 7.33301 0 7.81301 0 8.39967L0 14.933C0 15.5197 0.480801 15.9997 1.06845 15.9997L14.9316 15.9997C15.5192 15.9997 16 15.5197 16 14.933L16 8.39967C16 7.81301 15.5459 7.33301 14.9316 7.33301Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1331_48347">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_1331_48347">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UploadSvg;
