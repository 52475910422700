import React from "react";
import styles from "./NewGraphDetail.module.css";
import { Nav } from "react-bootstrap";
import { HomeTwoSvg } from "../../../../images/svg/icons";
const NewGraphDetail = (props) => {
  return (
    props.graphDetail.length > 0 && (
      <div className={styles["all-btn-wrapper"]}>
        {props.graphDetail.map(function (ele, index) {
          return (
            <Nav.Link
              key={index}
              href="#"
              className={`${styles["group-btn"]} ${
                ele.active && index !== 0 && styles["active"]
              }`}
              onClick={() => {
                if (index === 0 && props.graphDetail.length > 1) {
                  props.backToParentGraph();
                }
              }}
            >
              {index === 0 && <HomeTwoSvg />}
              <span className={styles["graph-name"]}>{ele.name}</span>
            </Nav.Link>
          );
        })}
      </div>
    )
  );
};

export default NewGraphDetail;
