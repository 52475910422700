import React from 'react';
import { Handle } from 'reactflow';
import styled from 'styled-components';

// Separate styled components for node and horizontal bar
const StyledNode = styled.div`
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 2px 0px;
    width: 80px;
    text-align: center !important;

    ${props =>
      props.colorProp &&
      `
      border: 1px solid ${props.colorProp};
    `}
`;

const StyledHr = styled.hr`
    border-top: 1px solid white;
    width: 100%;
`;

function CloudIcon({ data: { handles, label, IconComponent, violations } }) {
    let borderColor;
    switch (violations) {
        case 1:
            borderColor = 'green';
            break;
        case 2:
            borderColor = 'red';
            break;
        default:
            borderColor = 'white';
    }

    function splitString(text, len) {
        let words = text.split(" ");
        let result = [];
        let currentString = "";

        for (let i = 0; i < words.length; i++) {
            let word = words[i];
            if (currentString.length + word.length + 1 <= len) {
                currentString += word + " ";
            } else {
                result.push(<React.Fragment key={result.length}>{currentString.trim()}<br /></React.Fragment>);
                currentString = word + " ";
            }
        }

        if (currentString.length > 0) {
            result.push(<React.Fragment key={result.length}>{currentString.trim()}<br /></React.Fragment>);
        }

        return result;
    }

    return (
        <>
            {handles.map(({ id, type, position }) => (
                <Handle
                    key={id}
                    id={id}
                    type={type}
                    position={position}
                    style={{ background: '#555' }}
                    isConnectable={true}
                />
            ))}
            <StyledNode colorProp={borderColor}>
            <>
                <IconComponent size={30} style={{ marginBottom: '1px' }} />
                <StyledHr className='icon-div-hr' />
                <small
                    style={{
                        minHeight: '30px',
                        fontSize: '10px',
                        marginTop: '1px',
                        padding: '2px',
                        textAlign: 'center',
                    }}
                >
                    {splitString(label, 10)}
                </small>
            </>
            </StyledNode>
        </>
    );
}

export default CloudIcon;