const HexaSvg = () => {
  return (
    <>
      <svg
        width="268"
        height="146"
        viewBox="0 0 268 146"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M259.318 110.408L243.62 119.471L227.921 110.408L227.921 92.2807L243.62 83.2172L259.318 92.2807L259.318 110.408Z"
          fill="#5A46FF"
        />
        <path
          d="M118.021 82.0992L102.322 91.1627L86.6239 82.0992L86.624 63.9721L102.322 54.9086L118.021 63.9721L118.021 82.0992Z"
          fill="#5A46FF"
        />
        <path
          d="M70.5663 56.7515L54.8678 65.815L39.1694 56.7515L39.1694 38.6245L54.8678 29.561L70.5663 38.6245L70.5663 56.7515Z"
          fill="#5A46FF"
        />
        <path
          d="M164.942 54.7242L149.244 63.7877L133.545 54.7242L133.545 36.5971L149.244 27.5336L164.942 36.5971L164.942 54.7242Z"
          fill="#5A46FF"
        />
        <path
          d="M164.942 109.466L149.244 118.53L133.545 109.466L133.545 91.3393L149.244 82.2758L164.942 91.3393L164.942 109.466Z"
          fill="#5A46FF"
        />
        <path
          d="M212.134 82.8531L196.435 91.9166L180.737 82.8531L180.737 64.7261L196.435 55.6625L212.134 64.7261L212.134 82.8531Z"
          fill="#5A46FF"
        />
        <path
          d="M243.641 83.0796L227.925 92.1329L212.191 83.0488L212.173 64.9115L227.889 55.8583L243.623 64.9423L243.641 83.0796Z"
          fill="#D9D9D9"
        />
        <path
          d="M102.482 109.002L86.7834 118.065L71.0849 109.002L71.0849 90.8745L86.7834 81.811L102.482 90.8745L102.482 109.002Z"
          fill="white"
        />
        <path
          d="M149.556 82.0992L133.857 91.1627L118.159 82.0992L118.159 63.9721L133.857 54.9086L149.556 63.9721L149.556 82.0992Z"
          fill="white"
        />
        <path
          d="M196.09 55.6695L180.391 64.733L164.693 55.6695L164.693 37.5425L180.391 28.4789L196.09 37.5425L196.09 55.6695Z"
          fill="white"
        />
        <path
          d="M101.714 55.6695L86.0153 64.733L70.3168 55.6695L70.3168 37.5425L86.0153 28.4789L101.714 37.5425L101.714 55.6695Z"
          fill="#E9EDF5"
        />
        <path
          d="M196.09 109.466L180.391 118.53L164.693 109.466L164.693 91.3393L180.391 82.2758L196.09 91.3393L196.09 109.466Z"
          fill="#D9D9D9"
        />
        <path
          d="M228.178 109.466L212.48 118.53L196.781 109.466L196.781 91.3393L212.48 82.2758L228.178 91.3393L228.178 109.466Z"
          fill="white"
        />
        <path
          d="M71.0717 109.951L55.3732 119.014L39.6747 109.951L39.6747 91.8237L55.3732 82.7602L71.0717 91.8237L71.0717 109.951Z"
          fill="#5A46FF"
        />
        <path
          d="M55.0272 83.6617L39.3288 92.7252L23.6303 83.6617L23.6303 65.5346L39.3288 56.4711L55.0272 65.5346L55.0272 83.6617Z"
          fill="#E9EDF5"
        />
        <path
          d="M39.246 56.6109L23.5475 65.6744L7.84905 56.6109L7.84905 38.4839L23.5475 29.4204L39.246 38.4839L39.246 56.6109Z"
          fill="#E9EDF5"
        />
        <path
          d="M86.6107 83.0406L70.9123 92.1041L55.2138 83.0406L55.2138 64.9136L70.9123 55.85L86.6107 64.9136L86.6107 83.0406Z"
          fill="#D9D9D9"
        />
        <path
          d="M133.802 55.6695L118.104 64.733L102.405 55.6695L102.405 37.5425L118.104 28.4789L133.802 37.5425L133.802 55.6695Z"
          fill="#D9D9D9"
        />
        <path
          d="M133.802 109.146L118.104 118.21L102.405 109.146L102.405 91.019L118.104 81.9555L133.802 91.019L133.802 109.146Z"
          fill="#D9D9D9"
        />
        <path
          d="M227.52 55.857L211.822 64.9205L196.123 55.857L196.123 37.73L211.822 28.6664L227.52 37.73L227.52 55.857Z"
          fill="#D9D9D9"
        />
        <path d="M149.195 29L149.195 0" stroke="black" />
        <path d="M149.195 146L149.195 117" stroke="black" />
      </svg>
    </>
  );
};

export default HexaSvg;
