import { useContext } from "react";
import { Modal, Spinner } from "react-bootstrap";
import context from "../../../store/create-context";
import styles from "./SpinnerLoader.module.css";
const SpinnerLoader = () => {

  const ctx = useContext(context);

  return (
    <Modal 
      size={"sm"} 
      show={ctx.showSpinner}
      backdrop="static"
      centered
      className={styles["spinner-loader"]}
    >
      <Modal.Body className="text-center">
         <Spinner animation="grow" variant="warning"  />
      </Modal.Body>
    </Modal>
  );
};

export default SpinnerLoader;
