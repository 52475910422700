import React from 'react';
import CloudIcon from './cloud-icon.component';

// import QuickSight from 'react-aws-icons/dist/aws/logo/QuickSight';
// import APIGateway from 'react-aws-icons/dist/aws/logo/APIGateway';
// import Athena from 'react-aws-icons/dist/aws/logo/Athena';
// import Instance from 'react-aws-icons/dist/aws/compute/Instance';
// import MobileAnalytics from 'react-aws-icons/dist/aws/logo/MobileAnalytics';
// import IoT from 'react-aws-icons/dist/aws/logo/IoT';
// import Route53 from 'react-aws-icons/dist/aws/logo/Route53';
// import Greengrass from 'react-aws-icons/dist/aws/logo/Greengrass';
// import AWSCloud from 'react-aws-icons/dist/aws/compute/AWSCloud';
// import CloudFront from 'react-aws-icons/dist/aws/logo/CloudFront';
// import Cognito from 'react-aws-icons/dist/aws/logo/Cognito';
// import DB from 'react-aws-icons/dist/aws/compute/DB';
// import LoadBalancer from 'react-aws-icons/dist/aws/compute/LoadBalancer';
// import CloudWatch from 'react-aws-icons/dist/aws/compute/CloudWatch';
// import AutoScaling from 'react-aws-icons/dist/aws/compute/AutoScaling';
// import VPC from 'react-aws-icons/dist/aws/compute/VPC';
// import DynamoDB from 'react-aws-icons/dist/aws/logo/DynamoDB';
// import EC2 from 'react-aws-icons/dist/aws/logo/EC2';
// import LambdaFunction from 'react-aws-icons/dist/aws/compute/LambdaFunction';
// import MobileClient from 'react-aws-icons/dist/aws/general/MobileClient';
// import RDS from 'react-aws-icons/dist/aws/logo/RDS';
// import S3 from 'react-aws-icons/dist/aws/logo/S3';
// import Aurora from 'react-aws-icons/dist/aws/compute/DB';
// import EBS from 'react-aws-icons/dist/aws/storage/EBS';
// import Lightsail from 'react-aws-icons/dist/aws/logo/Lightsail';
// import Redshift from 'react-aws-icons/dist/aws/logo/Redshift';
// import ElastiCache from 'react-aws-icons/dist/aws/logo/ElasticCache';
// import CloudSearch from 'react-aws-icons/dist/aws/logo/CloudSearch';
// import Kinesis from 'react-aws-icons/dist/aws/logo/Kinesis';
// import EMR from 'react-aws-icons/dist/aws/logo/EMR';
// import Glue from 'react-aws-icons/dist/aws/logo/Glue';
// import SES from 'react-aws-icons/dist/aws/logo/SES';
// import SQS from 'react-aws-icons/dist/aws/logo/SQS';
// import SNS from 'react-aws-icons/dist/aws/logo/SNS';
// import Rekognition from 'react-aws-icons/dist/aws/logo/Rekognition';
// import XRay from 'react-aws-icons/dist/aws/logo/XRay';
// import StepFunctions from 'react-aws-icons/dist/aws/logo/StepFunctions';
// import Glacier from 'react-aws-icons/dist/aws/logo/Glacier';
import {ArchitectureAmazonQuickSight, ResourceAmazonSimpleStorageServiceBucket} from 'aws-react-icons'
import {ArchitectureAmazonAPIGateway} from 'aws-react-icons'
import {ArchitectureAmazonAthena} from 'aws-react-icons';
import {ArchitectureAmazonKinesisVideoStreams} from 'aws-react-icons';
import {ArchitectureAWSConsoleMobileApplication} from 'aws-react-icons';
import {ArchitectureAWSIoTEvents} from 'aws-react-icons';
import {ResourceAmazonRoute53HostedZone} from 'aws-react-icons';
import {ResourceAWSIoTGreengrassArtifact} from 'aws-react-icons';
// import KinesisStreams from 'react-aws-icons/dist/aws/compute/KinesisStreams';
// import {ArchitectureAmazonKinesisVideoStreams} from 'aws-react-icons';
import {ArchitectureAWSCloudControlAPI} from 'aws-react-icons';
import {ArchitectureAmazonCloudFront} from 'aws-react-icons';
import {ArchitectureAWSAppSync} from 'aws-react-icons';
import {ArchitectureAmazonCognito} from 'aws-react-icons';
import {ArchitectureAmazonDocumentDB} from 'aws-react-icons';
import {ArchitectureElasticLoadBalancing} from 'aws-react-icons';
import {ArchitectureAmazonCloudWatch} from 'aws-react-icons';
import {ArchitectureAWSAutoScaling} from 'aws-react-icons';
import {ArchitectureAmazonVPCLattice} from 'aws-react-icons';
import {ArchitectureAmazonDynamoDB} from 'aws-react-icons';
import {ArchitectureAmazonEC2} from 'aws-react-icons';
import {ResourceAWSIoTLambdaFunction} from 'aws-react-icons';
import {ResourceMobileclient} from 'aws-react-icons';
import {ArchitectureAmazonRDS} from 'aws-react-icons';
import {ArchitectureAmazonS3onOutposts} from 'aws-react-icons';
import {ArchitectureAmazonElasticBlockStore} from 'aws-react-icons';
import {ArchitectureAmazonLightsail} from 'aws-react-icons';
import {ArchitectureAmazonRedshift} from 'aws-react-icons';
import {ArchitectureAmazonElastiCache} from 'aws-react-icons';
import {ArchitectureAmazonCloudSearch} from 'aws-react-icons';
import {ArchitectureAmazonKinesis} from 'aws-react-icons';
import {ArchitectureAmazonEMR} from 'aws-react-icons';
import {ArchitectureAWSGlue} from 'aws-react-icons';
import {ResourceAmazonSimpleEmailServiceEmail} from 'aws-react-icons';
import {ArchitectureAmazonSimpleQueueService} from 'aws-react-icons';
import {ArchitectureAmazonSimpleNotificationService} from 'aws-react-icons';
import {ArchitectureAmazonRekognition} from 'aws-react-icons';
import {ArchitectureAWSXRay} from 'aws-react-icons';
import {ArchitectureAWSIdentityandAccessManagement} from 'aws-react-icons';
// -----------------------------------------------------------------------
import MechanicalTurk from 'react-aws-icons/dist/aws/logo/MechanicalTurk';
// -----------------------------------------------------------------------
import {ArchitectureAWSStepFunctions} from 'aws-react-icons';
import {ArchitectureAmazonSimpleStorageServiceGlacier} from 'aws-react-icons';
import {ArchitectureAWSCloudTrail} from 'aws-react-icons';
import {ArchitectureAWSWAF} from 'aws-react-icons';
import {ArchitectureAWSKeyManagementService} from 'aws-react-icons';
function AWSIconWrapper({ iconType, data }) {
    const iconMap = {
        KMS: {
            IconComponent: ArchitectureAWSKeyManagementService,
            label: 'AWS KMS',
        },
        WAF: {
            IconComponent: ArchitectureAWSWAF,
            label: 'AWS WAF',
        },
        CloudTrail: {
            IconComponent: ArchitectureAWSCloudTrail,
            label: 'AWS CloudTrail',
        },
        IAM: {
            IconComponent: ArchitectureAWSIdentityandAccessManagement,
            label: 'AWS IAM',
        },
        Glacier: {
            IconComponent: ArchitectureAmazonSimpleStorageServiceGlacier,
            label: 'AWS Glacier',
        },
        XRay: {
            IconComponent: ArchitectureAWSXRay,
            label: 'AWS XRay',
        },
        StepFunctions: {
            IconComponent: ArchitectureAWSStepFunctions,
            label: 'AWS Step Functions',
        },
        Rekognition: {
            IconComponent: ArchitectureAmazonRekognition,
            label: 'AWS Rekognition',
        },
        QuickSight: {
            IconComponent: ArchitectureAmazonQuickSight,
            label: 'AWS QuickSight',
        },
        Route53: {
            IconComponent: ResourceAmazonRoute53HostedZone,
            label: 'AWS Route53',
        },
        AutoScaling: {
            IconComponent: ArchitectureAWSAutoScaling,
            label: 'AWS AutoScaling',
        },
        CloudWatch: {
            IconComponent: ArchitectureAmazonCloudWatch,
            label: 'AWS CloudWatch',
        },
        LoadBalancer: {
            IconComponent: ArchitectureElasticLoadBalancing,
            label: 'AWS LoadBalancer',
        },
        VPC: {
            IconComponent: ArchitectureAmazonVPCLattice,
            label: 'AWS VPC',
        },
        EBS: {
            IconComponent: ArchitectureAmazonElasticBlockStore,
            label: 'AWS Elastic Block Store',
        },
        APIGateway: {
            IconComponent: ArchitectureAmazonAPIGateway,
            label: 'API Gateway',
        },
        Athena: {
            IconComponent: ArchitectureAmazonAthena,
            label: 'AWS Athena',
        },
        // Instance: {
        //     IconComponent: ArchitectureAmazonKinesisVideoStreams,
        //     label: 'Custom Service',
        // },
        MobileAnalytics: {
            IconComponent: ArchitectureAWSConsoleMobileApplication,
            label: 'AWS Mobile Analytics',
        },
        IoT: {
            IconComponent: ArchitectureAWSIoTEvents,
            label: 'AWS IoT',
        },
        Greengrass: {
            IconComponent: ResourceAWSIoTGreengrassArtifact,
            label: 'AWS Greengrass',
        },
        KinesisStreams: {
            IconComponent: ArchitectureAmazonKinesisVideoStreams,
            label: 'AWS Kinesis Streams',
        },
        AWSCloud: {
            IconComponent: ArchitectureAWSCloudControlAPI,
            label: 'AWS Cloud',
        },
        CloudFront: {
            IconComponent: ArchitectureAmazonCloudFront,
            label: 'AWS CloudFront',
        },
        Cognito: {
            IconComponent: ArchitectureAmazonCognito,
            label: 'AWS Cognito',
        },
        DB: {
            IconComponent: ArchitectureAmazonDocumentDB,
            label: 'AWS DB',
        },
        DynamoDB: {
            IconComponent: ArchitectureAmazonDynamoDB,
            label: 'AWS DynamoDB',
        },
        EC2: {
            IconComponent: ArchitectureAmazonEC2,
            label: 'AWS EC2',
        },
        LambdaFunction: {
            IconComponent: ResourceAWSIoTLambdaFunction,
            label: 'AWS Lambda',
        },
        MobileClient: {
            IconComponent: ResourceMobileclient,
            label: 'AWS Mobile Client',
        },
        RDS: {
            IconComponent: ArchitectureAmazonRDS,
            label: 'AWS RDS',
        },
        S3: {
            IconComponent: ArchitectureAmazonS3onOutposts,
            label: 'AWS S3',
        },
        Aurora: {
            IconComponent: ArchitectureAmazonDocumentDB,
            label: 'AWS Aurora',
        },
        Lightsail: {
            IconComponent: ArchitectureAmazonLightsail,
            label: 'AWS Lightsail',
        },
        Redshift: {
            IconComponent: ArchitectureAmazonRedshift,
            label: 'AWS Redshift',
        },
        ElastiCache: {
            IconComponent: ArchitectureAmazonElastiCache,
            label: 'AWS ElastiCache',
        },
        CloudSearch: {
            IconComponent: ArchitectureAmazonCloudSearch,
            label: 'AWS CloudSearch',
        },
        Kinesis: {
            IconComponent: ArchitectureAmazonKinesis,
            label: 'AWS Kinesis',
        },
        EMR: {
            IconComponent: ArchitectureAmazonEMR,
            label: 'AWS EMR',
        },
        Glue: {
            IconComponent: ArchitectureAWSGlue,
            label: 'AWS Glue',
        },
        SES: {
            IconComponent: ResourceAmazonSimpleEmailServiceEmail,
            label: 'AWS SES',
        },
        SQS: {
            IconComponent: ArchitectureAmazonSimpleQueueService,
            label: 'AWS SQS',
        },
        SNS: {
            IconComponent: ArchitectureAmazonSimpleNotificationService,
            label: 'AWS SNS',
        },
        ElasticSearch: {
            IconComponent: ArchitectureAmazonCloudSearch,
            label: 'AWS ElasticSearch',
        },
        MechanicalTurk: {
            IconComponent: MechanicalTurk,
            label: 'AWS Mechanical Turk',
        },
        AppSync: {
            IconComponent: ArchitectureAWSAppSync,
            label: 'AWS AppSync',
        },
        S3bucket: {
            IconComponent: ResourceAmazonSimpleStorageServiceBucket,
            label: 'S3 Bucket',
        },
    };

    var iconData = iconMap[iconType];

    if (!iconData) {
      const firstLetter = data?.label?.charAt(0)?.toUpperCase() || 'S';
      iconData = {
        IconComponent: () => <div style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            background:'orange',
            borderRadius:'50%',
            width:'40px',
            height:'40px',
            fontSize:'24px',
            color:'white',
            zIndex:'-1'
        }}><div>{firstLetter}</div></div>,
        label: data?.label || 'Custom Service',
      };
    }
    const finalLabel = data?.label === "" ? iconData.label : data?.label;
    return <CloudIcon data={{ ...iconData, ...data, label: finalLabel }} />;
}

export default AWSIconWrapper;