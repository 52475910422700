const ChatSvg = () => {
  return (
    <>
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.40106 16.6673C1.27864 16.6673 1.18069 16.6433 1.08275 16.5954C0.837901 16.4755 0.666504 16.2117 0.666504 15.9479V5.39633C0.666504 4.26924 1.62143 3.33398 2.77224 3.33398H13.2519C14.4027 3.33398 15.3577 4.26924 15.3577 5.39633V12.4707C15.3577 13.5978 14.4027 14.533 13.2519 14.533H4.58414L1.8418 16.5234C1.71937 16.6194 1.54797 16.6673 1.40106 16.6673ZM2.77224 4.77283C2.42944 4.77283 2.13562 5.0606 2.13562 5.39633V14.509L3.89856 13.2381C4.02098 13.1421 4.1679 13.0942 4.33929 13.0942H13.2519C13.5947 13.0942 13.8885 12.8064 13.8885 12.4707V5.39633C13.8885 5.0606 13.5947 4.77283 13.2519 4.77283H2.77224Z"
          fill="white"
        />
        <circle cx="13.9995" cy="4" r="4" fill="#FF2257" />
      </svg>
    </>
  );
};

export default ChatSvg;
