const EyeCloseSvg = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2419_11829)">
        <path
          d="M15.0415 13.5849L1.93154 0.387359C1.63966 0.0935364 1.17753 0.0935364 0.885654 0.387359C0.618102 0.656697 0.618102 1.1464 0.885654 1.41574L3.4882 4.03566C2.56393 4.81919 1.66398 5.84757 0.788362 7.09632C0.788362 7.09632 0.788362 7.09632 0.788362 7.1208C0.764039 7.14529 0.764039 7.16977 0.739717 7.19426C0.739717 7.21874 0.715394 7.21874 0.715394 7.24323C0.715394 7.26771 0.691071 7.2922 0.691071 7.31669C0.691071 7.34117 0.666748 7.36566 0.666748 7.39014C0.666748 7.41463 0.666748 7.43911 0.666748 7.4636C0.666748 7.48808 0.666748 7.51257 0.666748 7.53705C0.666748 7.56154 0.666748 7.58602 0.666748 7.61051C0.666748 7.63499 0.666748 7.65948 0.666748 7.68396C0.666748 7.70845 0.691071 7.73293 0.691071 7.75742C0.691071 7.78191 0.715394 7.80639 0.715394 7.83088C0.715394 7.85536 0.739717 7.87985 0.739717 7.87985C0.764039 7.90433 0.764039 7.92882 0.788362 7.9533C0.788362 7.9533 0.788362 7.9533 0.788362 7.97779C2.97742 11.1609 5.4097 12.7769 7.9636 12.7769C9.05812 12.7769 10.1527 12.4586 11.1985 11.8709L13.9957 14.6133C14.1416 14.7602 14.3362 14.8337 14.5064 14.8337C14.701 14.8337 14.8713 14.7602 15.0172 14.6133C15.3091 14.3195 15.3091 13.8542 15.0415 13.5849ZM7.9636 9.81418C6.69881 9.81418 5.67725 8.7858 5.67725 7.51257C5.67725 7.14529 5.77454 6.8025 5.92048 6.48419L8.98516 9.56933C8.69328 9.71624 8.32844 9.81418 7.9636 9.81418Z"
          fill="#F8F8FF"
        />
        <path
          d="M12.6579 10.1322C12.8525 10.3281 13.1687 10.3281 13.3389 10.1322C13.947 9.49561 14.5551 8.76105 15.1388 7.92855L15.2361 7.78164C15.3577 7.61025 15.3577 7.38988 15.2361 7.21848L15.1388 7.07157C12.9497 3.88849 10.5175 2.27246 7.96357 2.27246C7.40415 2.27246 6.84472 2.34592 6.2853 2.51731L6.13936 2.56628C5.77452 2.66422 5.67722 3.12944 5.92045 3.3743L12.6579 10.1322Z"
          fill="#F8F8FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_2419_11829">
          <rect
            width="14.6667"
            height="14.6667"
            fill="white"
            transform="translate(0.666748 0.166992)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeCloseSvg;
