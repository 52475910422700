import React, { useEffect, useState, useContext } from "react";
import styles from "./FooterBar.module.css";
import { Popover as ReactBootstrapPopover } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import context from "../../../store/create-context";
import { CrossSvg } from "../../../images/svg/icons";

const CustomTypeHead = ({ textData, sResponse, setFooterBtn }) => {
  const boxStyle = {
    position: "absolute",
    inset: "auto auto 0px 0px",
    marginLeft: "65px",
    opacity: 0.95,
  };
  let { setFooterSearchText, setHightlightNodesContext } = useContext(context);

  const [questionsAndanswer, setQuestionAndanswer] = useState([]);
  const [answer, setAnswer] = useState(null);
  const [question, setQuestion] = useState(null);
  const [markupArr, setMarkupArray] = useState([]);

  useEffect(() => {
    if (!sResponse.questions) return;
    let categoryData;

    switch (textData) {
      case "general":
        categoryData = sResponse.questions?.General;
        break;
      case "security":
        categoryData = sResponse.questions?.Security;
        break;
      case "cost":
        categoryData = sResponse.questions?.Cost;
        break;
      default:
        categoryData = sResponse.questions?.General;
    }

    setQuestionAndanswer(categoryData || []);
    setAnswer(null);
    setQuestion(null);
  }, [textData, sResponse?.questions]);

  const handleShowAnswer = (index) => {
    setMarkupArray([]);
    setAnswer(questionsAndanswer[index].answer.text);

    setQuestion(questionsAndanswer[index].question);
    setQuestionAndanswer([]);
    if (questionsAndanswer[index].answer.markup) {
      setMarkupArray(questionsAndanswer[index].answer.markup);
    }
  };
  const renderHighlightedText = (str, markUpArr) => {
    //Render Text
    const renderText = (text) => (
      <span dangerouslySetInnerHTML={{ __html: text }} />
    );

    //Render PopUp
    const renderPopover = (text, popupText) => {
      const popover = (
        <ReactBootstrapPopover
          id="popover-basic"
          style={{ background: "rgb(47, 47, 47)" }}
        >
          <ReactBootstrapPopover.Body>
            <p className="p-2">{popupText}</p>
          </ReactBootstrapPopover.Body>
        </ReactBootstrapPopover>
      );

      return (
        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
          <span
            variant="success"
            style={{ color: "#02DC59", cursor: "pointer" }}
          >
            {text}
          </span>
        </OverlayTrigger>
      );
    };

    //Render JmlSearch
    const renderJmlSearch = (text, jmlSearch) => (
      <span
        style={{ color: "yellow", cursor: "pointer" }}
        onClick={() => {
          setFooterSearchText(jmlSearch);
        }}
      >
        {text}
      </span>
    );

    //Render Canvas HighLight
    const renderCanvasHighlight = (text, canvasHighlight) => (
      <span
        style={{ color: "red", cursor: "pointer" }}
        onClick={() => setHightlightNodesContext(canvasHighlight)}
      >
        {text}
      </span>
    );

    //Render Anchor Tag
    const renderAnchorTag = (text, href) => (
      <a
        href={href}
        target="_blank"
        style={{ color: "#0078D4", opacity: "0.7" }}
        rel="noreferrer"
      >
        {text}
      </a>
    );

    //Render Hover
    const renderHover = (text, hoverText) => (
      <span
        data-toggle="tooltip"
        data-placement="bottom"
        title={hoverText}
        style={{
          color: "#0000FF",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        {text}
      </span>
    );
    const renderNodeHighlight = (text, canvasHighlight) => (
      <span
        style={{ color: "red", cursor: "pointer" }}
        onClick={() => setHightlightNodesContext(canvasHighlight)}
      >
        {text}
      </span>
    );
    //Render Markup
    const renderMarkup = (text, data) => {
      let type = data.type;
      switch (type) {
        case "href":
          return renderAnchorTag(text, data.href);
        case "hover":
          return renderHover(text, data.hover_text);
        case "popup":
          return renderPopover(text, data.popup_text);
        case "jml_search":
          return renderJmlSearch(text, data.jml_search);
        case "canvas_highlight":
          return renderCanvasHighlight(text, data.canvas_highlight);
        default:
          return renderText(text);
      }
    };

    /*split string based on wordsToSplit array*/
    let wordsToSplit = [];
    if (markUpArr && Array.isArray(markUpArr)) {
      markUpArr.forEach((item) => {
        wordsToSplit.push(item.text.trim());
      });
    }

    if (wordsToSplit.length == 0) return renderText(str);

    const regex = new RegExp(`(\\b${wordsToSplit.join("\\b|\\b")}\\b)`, "g");
    const parts = str.split(regex);

    return parts.map((part, index) => {
      const matchingMarkUp = markUpArr.find((markup) => markup.text === part);
      if (matchingMarkUp) {
        return renderMarkup(part, matchingMarkUp);
      } else {
        return renderText(part);
      }
    });
  };

  return (
    <>
      {sResponse?.questions ? (
        <div style={boxStyle} id={styles["search-input-field"]}>
          <div className={styles["custom-cross-svg"]}>
            <h6 className={styles["custom-heading"]}>
              {questionsAndanswer.length > 0 && (
                <span>Here are some recommended {textData} questions:</span>
              )}

              {question && <span key="question">{question}</span>}
            </h6>
            <div
              style={{ cursor: "pointer", height: "26px" }}
              onClick={() => setFooterBtn(null)}
            >
              <CrossSvg />
            </div>
          </div>
          <hr />
          <ul
            className={styles["menu-ul"]}
            id={styles["question-menu-ul"]}
            style={{
              maxHeight: "300px",
              overflowY: "scroll",
            }}
          >
            {questionsAndanswer &&
              questionsAndanswer.map((item, index) => (
                <li
                  style={{ textAlign: "start", cursor: "pointer" }}
                  key={index}
                >
                  <a
                    onClick={() => handleShowAnswer(index)}
                    className="dropdown-item"
                  >
                    <span>{index + 1}. </span> {item.question}
                  </a>
                </li>
              ))}

            {answer && (
              <li key="answer" style={{ textAlign: "start" }}>
                <a className="dropdown-item">
                  {renderHighlightedText(answer, markupArr)}
                </a>
              </li>
            )}
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default CustomTypeHead;
