import React, { useState, useCallback } from "react";
import ModalUI from "../../../../components/UI/ModalUI/ModalUI";
import { Row, Col, Form,Nav } from "react-bootstrap";
import styles from "./LinkModal.module.css";
import { AwsIconSvg, AzureIconSvg, AzureTextSvg, DigitalOceanIcon, TickSvg } from "../../../../../images/svg/icons";

const LinkModal = (props) => {

    const modalCloseHandler = () => {
        props.handleClose();
    };

    return (
        <ModalUI
            show={props.show}
            theme="dark"
            className={styles["bg-color"]}
            classes="border-no pb-0"
            modalTitle="Link Infrastructure"
            size="md"
            handleClose={modalCloseHandler}
        >
        <Row>
            <Col>
               <p className={styles["heading"]}>Please select a server below</p>
               <div className={styles["all-btn-wrapper"]}>
              <Nav.Link  href="#" className={`${styles["group-btn"]} ${styles['active']}`}>
                <span><AzureIconSvg/></span>
                <span><AzureTextSvg/></span>
              </Nav.Link>
              <Nav.Link  href="#" className={`${styles["group-btn"]}`}>
                <span><AwsIconSvg/></span>
              </Nav.Link>
              <Nav.Link  href="#" className={`${styles["group-btn"]}`}>
                <span><DigitalOceanIcon/></span>
              </Nav.Link>
            </div>
            <Form>
                <Form.Group className="mt-4">
                    <Form.Control
                        type="input"
                        name="user_id"
                        data-testid="user_id"
                        placeholder="Enter your user ID"
                        className={styles["custom-input"]}
                    />
                 </Form.Group>
                 <Form.Group className="mt-3 mb-3">
                    <Form.Control
                        type="input"
                        name="passkey"
                        data-testid="passkey"
                        placeholder="Your passkey"
                        className={styles["custom-input"]}
                    />
                </Form.Group>
                <button
                    className={styles['cust-btn']}
                    style={{ float: "right" }}
                >
                    <span>
                     <TickSvg/>
                    </span>
                    <span>Give Access</span>
                </button>
            </Form>
            </Col>
        </Row>
        </ModalUI>
  );
};

export default LinkModal;
