import React, { useState, useEffect, useContext } from "react";
import styles from "./FooterBar.module.css";
import { StartSvg, ClockSvg } from "../../../images/svg/icons";
import CommandsMgr from "./canvas_commands";
import { CloudProvider } from "./canvas_commands";
import { Form, Nav, Spinner } from "react-bootstrap";
import SearchModal from "./components/search-model.component";
import Collapse from "react-bootstrap/Collapse";
import { CollpaseSvg } from "../../../images/svg/icons";
import expandBtn from "../../../images/expand.svg";
import context from "../../../store/create-context";
import CustomTypeHead from "./CustomTypeHead";
import Button from "react-bootstrap/Button";
import { handleClickOnAskAi } from "../../../utils/google-analytics";

const FooterBar = (props) => {

  //context
  const { footerSearchText, footerBtn, setFooterSearchText, setFooterBtn, edgeColor, userId, setShowToastr, activeSection, setQueryStr, queryStr } = useContext(context);
  const { descriptionId, sResponse, setSResponse, socket } = props;
  const [ position, setPosition ] = useState({ x: 0, y: 0 });
  const [ dragging, setDragging ] = useState(false);
  const handleDragStart = (e) => {
    setDragging(true);
    const setX = e.clientX - position.x;
    const setY = e.clientY - position.y;
    const handleMouseMove = (e) => {
        setPosition({ x: e.clientX - setX, y: e.clientY - setY });
    };
    const handleMouseUp = () => {
        setDragging(false);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
};


  //states
  // const [footerBtn, setFooterBtn] = useState(null);
  const [open, setOpen] = useState(true);
  const [items, setItems] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  //ref
  const searchText = React.createRef();

  let commandsMgr = new CommandsMgr(
    CloudProvider.AWS,
    descriptionId,
    setSResponse,
    sResponse,
    socket
  );

  const addAskAIListener = () => {
    socket.addEventListener("ask_ai", (event) => {
      // Check if the event has the "architecture" 
      if (event?.success === false) {
        setShowToastr({
          show: true,
          type: "error",
          text: event?.error,
        });
      } else {
        let items = event["items"];
        setItems(items);
        setSearchLoader(false);
        setFooterBtn(null);
      }
    });
  };

  const cancelRequest = () => {
    if(searchLoader){
      socket.socketRef.off("ask_ai")
      setSearchLoader(false);
      setQueryStr("");
    }
  }
  //Effects
  useEffect(() => {
    if (footerSearchText !== "" && footerSearchText !== undefined) {
      if (searchText.current) {
        searchText.current.value = footerSearchText;
        setFooterSearchText("");
      }
    }
  }, [footerSearchText]);

  // Handlers
  let _interval = null;
  const executeCommandFn = (value) => {
    setSearchLoader(true);
    clearTimeout(_interval);
    _interval = setTimeout(() => {
      addAskAIListener();
      commandsMgr.execute(value, setSearchLoader, undefined, sResponse);
    }, 1000);
  };

  const keyDownHandler = (event) => {
    event.stopPropagation();
    if (event.key === "/" || event.key === "Enter") {
      event.preventDefault();
      const value = event.target.value;
      if (value !== "") {
        executeCommandFn(value);
      }
    }
  };

  const askAIbuttonClickHandler = () => {
    if (searchText.current) {
      if (searchText.current.value !== ""){
        handleClickOnAskAi(userId);
        executeCommandFn(searchText.current.value);
      }
      else searchText.current.focus();
      setQueryStr("");
    }
  };

  const tabHandler = (tab) => {
    setFooterBtn(tab);
    searchText.current.value = "";
    setItems([]);
  };

  const InputField = {
    borderRadius: "0.5rem",
    color: "white",
    width: "24rem",
    padding: " 0.4rem 1rem",
    border: `${activeSection!="infra_view"? "1px solid #3d3d3d": "1px solid rgb(110 108 108)"}`,
    backgroundColor: `${activeSection==="infra_view" && "rgba(39, 39, 39, 1)"}`
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  }

  const handleInputBlur = () => {
    setIsFocused(false);
  }
  const handleValueChange = (e) =>{
    setQueryStr(e.target.value);
  }

  return (
    <div className={styles["main-footer"]}>
      {activeSection !== "infra_view" && <span className={styles["thick-border"]} style={{color: edgeColor}}>
        {/* Thick borders indicates that the node is a subgraph and can be double
        clicked to zoom in further   */}
        Don't forget to double-click on thick border nodes.
      </span>}
      <div className={styles["footer-wrapper"]}
      draggable={!isFocused} onDragStart={handleDragStart}
       style={{top: position.y + 'px', left: position.x + 'px', cursor: dragging ? 'grabbing' : 'grab', pointerEvents: dragging ? "none" : "all"}}>
        <div className={styles["footer-grid"]}>
          <Collapse in={open} dimension="width">
            <div id="example-collapse-text">
              <div className={`${activeSection==="infra_view" && styles["infra-view-bg"]} ${styles["search-grid"]}`}>
                <div>
                  {/* <div className={`${styles["history"]} ${activeSection==="infra_view" && styles["history-bg"]}`}>
                    <ClockSvg />
                  </div> */}
                </div>
                <div className={styles["advance-search-input-field"]}>
                  {items?.length > 0 && (
                    <SearchModal
                      items={items}
                      setItems={setItems}
                      title={""}
                      setSearchLoader={setSearchLoader}
                      descriptionId={descriptionId}
                      setSResponse={setSResponse}
                      sResponse={sResponse}
                      socket={socket}
                    />
                  )}
                </div>
                <div className={styles["input-btn-wrapper"]}>
                  <Form.Group
                    className={`position-relative ${styles["input-search-wrapper"]}}`}
                  >
                  {queryStr === "" ? <input
                      style={InputField}
                      ref={searchText}
                      onKeyDown={keyDownHandler}
                      placeholder="Ask any question about your systems here…"
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      className={isFocused? styles["input-focus"]: styles["input-blur"]}
                    /> :
                    <input
                      style={InputField}
                      ref={searchText}
                      onChange={handleValueChange}
                      value={queryStr}
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      className={styles["input-focus"]}
                    />
                    }

                    {searchLoader && (
                      <Spinner
                        animation="grow"
                        size="sm"
                        style={{
                          position: "absolute",
                          right: "4%",
                          top: "35%",
                          color: "#fff",
                        }}
                      />
                    )}
                  </Form.Group>
                  {footerBtn !== null && (
                    <CustomTypeHead
                      textData={footerBtn}
                      sResponse={sResponse}
                      setFooterBtn={setFooterBtn}
                    />
                  )}

                  <div className={`${styles["all-btn-wrapper"]} ${activeSection === "infra_view" && styles["all-btn-wrapper-infra"]}`}>
                    <Nav.Link
                      href="#"
                      className={`${styles["group-btn"]} ${
                        footerBtn === "general" && styles["active"]
                      }`}
                      onClick={() => {
                        tabHandler("general");
                      }}
                      title="Coming Soon"
                    >
                      <span>General</span>
                    </Nav.Link>
                    <Nav.Link
                      href="#"
                      className={`${styles["group-btn"]} ${
                        footerBtn === "security" && styles["active"]
                      }`}
                      onClick={() => {
                        tabHandler("security");
                      }}
                      title="Coming Soon"
                    >
                      <span>Security</span>
                    </Nav.Link>
                    <Nav.Link
                      href="#"
                      className={`${styles["group-btn"]} ${
                        footerBtn === "cost" && styles["active"]
                      }`}
                      onClick={() => {
                        tabHandler("cost");
                      }}
                      title="Coming Soon"
                    >
                      <span>Cost</span>
                    </Nav.Link>
                  </div>
                  {searchLoader === true ? <button
                  className={styles["cancel-btn"]}
                    onClick={cancelRequest}
                  >
                    Cancel
                  </button>:
                  <button
                    className={styles["ai-btn"]}
                    onClick={askAIbuttonClickHandler}
                  >
                    <StartSvg />
                    <span>Ask AI</span>
                  </button>}
                  <button
                    onClick={() => setOpen(!open)}
                    className={styles["btn-open"]}
                  >
                    {activeSection!="infra_view"? <CollpaseSvg />: <img src={expandBtn} />}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        {!open ? (
          <Button
            style={{
              height: "40px",
              width: "100px",
              position: "absolute",
              bottom: "25px",
              right: "28px",
            }}
            className={`${styles["ai-btn"]} ${dragging ? styles["ai-btn-drag"] : ""}`}
            onClick={() => {setOpen(!open)}}
          >
            <StartSvg />
            <span>Ask AI</span>
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default FooterBar;
