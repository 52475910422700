const BrownThreeSvg = () => {
  return (
    <>
      <svg
        width="160"
        height="112"
        viewBox="0 0 160 112"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.3152 2.07093C83.5321 -0.690311 75.7773 -0.69031 70.9943 2.07093L3.64114 40.9539C1.02955 42.4616 -0.156078 44.4807 0.0842445 46.4528C0.0289212 46.7506 0 47.0577 0 47.3716V64.3716C0 64.7208 0.0357869 65.0616 0.103895 65.3905C-0.211903 67.4071 0.967176 69.4869 3.64113 71.0306L70.9943 109.914C75.7773 112.675 83.5321 112.675 88.3151 109.914L155.668 71.0306C157.503 69.9713 158.634 68.6594 159.061 67.2887C159.652 66.4677 160 65.4603 160 64.3716V47.3716C160 46.1791 159.583 45.0841 158.886 44.2247C158.364 43.0262 157.292 41.8912 155.668 40.9539L88.3152 2.07093Z"
          fill="white"
        />
        <g filter="url(#filter0_i_68_712)">
          <rect
            width="85.1695"
            height="85.1695"
            rx="4"
            transform="matrix(0.866044 0.499967 -0.866044 0.499967 79.6547 3.37109)"
            fill="#FF8946"
          />
          <rect
            width="85.1695"
            height="85.1695"
            rx="4"
            transform="matrix(0.866044 0.499967 -0.866044 0.499967 79.6547 3.37109)"
            fill="url(#paint0_radial_68_712)"
          />
        </g>
        <g clipPath="url(#clip0_68_712)">
          <g clipPath="url(#clip1_68_712)">
            <path
              d="M70.6164 49.3688L80.9721 44.7584C81.076 44.7011 81.1986 44.6144 81.2731 44.4971C81.3476 44.3799 81.3554 44.2614 81.3819 44.1136L81.2078 32.8108C81.2204 32.3662 80.8219 31.8249 80.293 31.4887C79.7641 31.1526 79.1049 31.0216 78.7076 31.2218L68.2853 35.8308C68.0106 35.9443 67.8802 36.1495 67.8941 36.4463L68.0681 47.7491C68.1036 48.2243 68.454 48.7351 69.031 49.1018C69.608 49.4685 70.2192 49.5689 70.6164 49.3688Z"
              fill="white"
            />
            <path
              d="M84.0489 35.5627L84.2355 46.4209L74.3252 50.8616C73.7573 51.1179 73.7958 51.8007 74.4052 52.435C75.0147 53.0692 75.9144 53.353 76.4823 53.0967L86.8379 48.4864C86.9419 48.429 87.0644 48.3424 87.139 48.2251C87.2135 48.1079 87.2213 47.9894 87.2477 47.8416L87.0737 36.5388C87.0646 35.9158 86.3776 35.1912 85.5927 34.9392C84.7411 34.686 84.0879 34.9703 84.0489 35.5627Z"
              fill="white"
            />
            <path
              d="M89.5782 39.0764L89.7648 49.9346L79.8545 54.3753C79.2866 54.6316 79.3251 55.3144 79.9345 55.9487C80.544 56.5829 81.4436 56.8667 82.0115 56.6104L92.3191 51.9695C92.4231 51.9122 92.5457 51.8255 92.6202 51.7083C92.6947 51.591 92.7025 51.4725 92.7289 51.3247L92.5549 40.0219C92.5458 39.399 91.8588 38.6743 91.0739 38.4223C90.289 38.1703 89.5691 38.4534 89.5782 39.0764Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_i_68_712"
            x="7.9234"
            y="3.54297"
            width="145.463"
            height="83.8203"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="-1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_68_712"
            />
          </filter>
          <radialGradient
            id="paint0_radial_68_712"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(80.8553 5.6547) rotate(136.311) scale(95.5074 78.8491)"
          >
            <stop stopColor="#91A8BA" stopOpacity="0" />
            <stop offset="0.300379" stopColor="#3B356B" stopOpacity="0.4" />
            <stop offset="1" stopColor="#49260D" />
          </radialGradient>
          <clipPath id="clip0_68_712">
            <rect
              width="22.7683"
              height="36.8448"
              fill="white"
              transform="translate(58 43.2148) rotate(-57.5607)"
            />
          </clipPath>
          <clipPath id="clip1_68_712">
            <rect
              width="20.871"
              height="29.1688"
              fill="white"
              transform="translate(62.3958 44.8848) rotate(-57.5607)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default BrownThreeSvg;
