/**
 * This function used to create group nodes
 */

export const groupNodes = (graph_viz) => {
  const nodes = graph_viz.nodes;

  let result = convertToNestedStructure(nodes);

  //remove top parent elements that doesnot have children
  //result = result.filter(_r => _r.children.length > 0)

  function updatePosition(result) {
    for (let item of result) {
      let updatedWidth = 0;
      let updatedHeight = 0;

      if (item.children.length > 0) {
        // Recursively update position for children and get the updated width and height
        const {
          updatedWidth: childUpdatedWidth,
          updatedHeight: childUpdatedHeight,
        } = updatePosition(item.children);

        updatedWidth = childUpdatedWidth;
        updatedHeight = childUpdatedHeight;

        // Calculate the position for the current parent based on children's positions
        let positions = [];
        let minX = Infinity;
        let maxX = -Infinity;
        let minY = Infinity;
        let maxY = -Infinity;

        item.children.forEach((child) => {
          let position = child.position;
          if (position) {
            positions.push({ x: position.x, y: position.y });
          }
        });

        if (positions.length > 0) {
          positions.forEach((item) => {
            minX = Math.min(minX, item.x);
            maxX = Math.max(maxX, item.x);
            minY = Math.min(minY, item.y);
            maxY = Math.max(maxY, item.y);
          });

          item.position = { x: minX - 10, y: minY - 10 };

          let width = maxX - minX + 200;
          let height = maxY - minY + 200;

          // Use the larger of the calculated width and height from the current and previous calls
          updatedWidth = Math.max(width, updatedWidth) + 60;
          updatedHeight = Math.max(height, updatedHeight) + 60;

          item.style = {
            width: updatedWidth,
            height: updatedHeight,
            color: "#fff",
            background: "none",
            border: "1px dashed red",
            borderRadius: "8px",
            zIndex: "-1",
          };

          item.children = item.children.map((_it) => {
            _it.position = {
              x: _it.position.x + 30 - minX,
              y: _it.position.y + 30 - minY,
            };
            return _it;
          });
        }
      }

      // Return the calculated width and height for the current level
      return {
        updatedWidth,
        updatedHeight,
      };
    }
  }

  let initialOutsideVariable = 0;

  updatePosition(result, initialOutsideVariable);
  graph_viz.nodes = flattenData(result);
  return graph_viz;
};

function convertToNestedStructure(data) {
  const map = new Map();
  const result = [];

  data.forEach((item) => {
    map.set(item.id, { ...item, children: [] });
  });

  data.forEach((item) => {
    if (item.parent) {
      // map.get(item.parent)?.children.push(map.get(item.id));
      result.push(map.get(item.id));
    } else {
      result.push(map.get(item.id));
    }
  });

  return result;
}

function flattenData(originalData) {
  let result = [];

  function traverse(node) {
    let _n = {};

    for (let key in node) {
      if (key !== "children") {
        _n[key] = node[key];
      } else {
        // _n["extent"] = _n["parent"] ? "parent" : undefined;
        // _n["parentNode"] = _n["parent"];
        if (node[key].length > 0) {
          _n["type"] = "group";
        }
        _n[key] = node[key];
      }
    }

    result.push(_n);

    if (node.children && node.children.length > 0) {
      node.children.forEach(traverse);
    }
  }

  originalData.forEach(traverse);

  return result;
}
