const DocSvg = () => {
  return (
    <>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3295_7853)">
          <path
            d="M13.2604 15.8337H2.07231C1.65337 15.8337 1.33301 15.5164 1.33301 15.1015V1.89911C1.33301 1.48424 1.65337 1.16699 2.07231 1.16699H8.28242C8.70136 1.16699 9.02172 1.48424 9.02172 1.89911V6.09655H13.2604C13.6793 6.09655 13.9997 6.4138 13.9997 6.82867V15.1015C13.9997 15.492 13.6793 15.8337 13.2604 15.8337ZM2.81161 14.3694H12.5211V7.56078H8.28242C7.86349 7.56078 7.54312 7.24353 7.54312 6.82867V2.63122H2.81161V14.3694Z"
            fill="white"
          />
          <path
            d="M13.2602 7.56078C13.0631 7.56078 12.8906 7.48757 12.7427 7.34115L7.76476 2.41158C7.46904 2.11874 7.46904 1.65507 7.76476 1.38663C8.06048 1.09378 8.5287 1.09378 8.79978 1.38663L13.7777 6.29178C14.0735 6.58463 14.0735 7.0483 13.7777 7.31674C13.6299 7.48757 13.4574 7.56078 13.2602 7.56078Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_3295_7853">
            <rect
              width="12.6667"
              height="14.6667"
              fill="white"
              transform="translate(1.33301 1.16699)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default DocSvg;
