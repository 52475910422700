import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import ContextProvider from './store/context-provider';

const container = document.getElementById('root');
document.title = "JigsawML"

const root = createRoot(container);

root.render(
	<React.StrictMode>
		{/* <img src={NewLogo} align="left" style={{ width: 125, height: 101, opacity: 1.0 }}></img> */}
		<ContextProvider>
		<App />
		</ContextProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
