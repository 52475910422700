const BlueThreeSvg = () => {
  return (
    <>
      <svg
        width="160"
        height="112"
        viewBox="0 0 160 112"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.3152 2.07093C83.5321 -0.690311 75.7773 -0.69031 70.9943 2.07093L3.64114 40.9539C1.02955 42.4616 -0.156078 44.4807 0.0842445 46.4528C0.0289212 46.7506 0 47.0577 0 47.3716V64.3716C0 64.7208 0.0357869 65.0616 0.103895 65.3905C-0.211903 67.4071 0.967176 69.4869 3.64113 71.0306L70.9943 109.914C75.7773 112.675 83.5321 112.675 88.3151 109.914L155.668 71.0306C157.503 69.9713 158.634 68.6594 159.061 67.2887C159.652 66.4677 160 65.4603 160 64.3716V47.3716C160 46.1791 159.583 45.0841 158.886 44.2247C158.364 43.0262 157.292 41.8912 155.668 40.9539L88.3152 2.07093Z"
          fill="white"
        />
        <g filter="url(#filter0_i_60_318)">
          <rect
            width="85.1695"
            height="85.1695"
            rx="4"
            transform="matrix(0.866044 0.499967 -0.866044 0.499967 79.6547 3.37109)"
            fill="#5A46FF"
          />
          <rect
            width="85.1695"
            height="85.1695"
            rx="4"
            transform="matrix(0.866044 0.499967 -0.866044 0.499967 79.6547 3.37109)"
            fill="url(#paint0_radial_60_318)"
          />
        </g>
        <path
          d="M85.1428 58.823C84.857 59.2712 84.3921 59.6052 83.8055 59.7354C82.6323 59.9959 81.5138 59.2824 81.2553 58.1088L77.4908 41.0174L60.4057 44.8102C59.2324 45.0707 58.1139 44.3572 57.8554 43.1836C57.5969 42.0099 58.3115 40.8895 59.4847 40.6291L78.6677 36.409C79.2544 36.2787 79.8161 36.3849 80.2635 36.6702C80.7109 36.9556 81.0602 37.4936 81.218 38.0356L85.4063 57.2257C85.5355 57.8126 85.4287 58.3749 85.1428 58.823Z"
          fill="white"
        />
        <path
          d="M104.877 46.3448L93.0166 53.5435C92.0169 54.1503 90.7033 53.8302 90.0975 52.8322C89.4917 51.8342 89.814 50.5211 90.8137 49.9143L102.674 42.7156C103.673 42.1088 104.987 42.4289 105.593 43.4269C106.199 44.425 105.876 45.738 104.877 46.3448Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_i_60_318"
            x="7.9234"
            y="3.54297"
            width="145.463"
            height="83.8203"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="-1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_60_318"
            />
          </filter>
          <radialGradient
            id="paint0_radial_60_318"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(80.8553 5.6547) rotate(136.311) scale(95.5074 78.8491)"
          >
            <stop stopColor="#91A8BA" stopOpacity="0" />
            <stop offset="0.300379" stopColor="#3B356B" stopOpacity="0.4" />
            <stop offset="1" stopColor="#201A53" />
          </radialGradient>
        </defs>
      </svg>
    </>
  );
};

export default BlueThreeSvg;
